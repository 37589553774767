import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { countDistance } from "../functions/countDistance";
import { deleteCompare, getCompareServices } from "../redux/actions/appActions";
import NoData from "../components/Shared/NoData";
import useLocation from "./../hooks/useLocation";
import useBuyService from "./../hooks/useBuyService";
import "../style/Compare.scss";

const ComparePage = (props) => {
  const [location] = useLocation();
  const lan = props.lan;

  return (
    <div className="top-bottom">
      {props.compareServices.length === 0 ? (
        <div className="container-lg px-lg-0">
          <div className="block-empty__body">
            <h1 className="block-empty__title">{lan.compare}</h1>
            <NoData />
            <div className="block-empty__action">
              <Link className="btn btn-primary btn-sm" to="/">
                {lan.toHome}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-lg px-lg-0">
          <h3
            className="block-header__title"
            style={{ padding: "20px 0px 0px" }}
          >
            {lan.compareServices}
          </h3>
          <div
            className="section-header__divider"
            style={{ marginBottom: "20px" }}
          ></div>
          <div className="compare card">
            <div className="table-responsive">
              <table className="compare__table compare-table">
                <tbody>
                  <tr className="compare-table__row">
                    <th className="compare-table__column compare-table__column--header">
                      {lan.clinic}
                    </th>
                    {props.compareServices.length !== 0 &&
                      props.compareServices.map((product) => (
                        <td
                          key={product.id}
                          className="compare-table__column compare-table__column--product"
                        >
                          <div className="compare-table__product">
                            <div className="compare-table__product-image image image--type--product">
                              <div className="image__body">
                                <Link to={`/clinics/${product.clinic_id}`}>
                                  <img
                                    className="image__tag pb-3"
                                    src={
                                      product.clinic && product.clinic.images[0]
                                        ? product.clinic.images[0].image
                                        : "/images/no-hospital.png"
                                    }
                                    alt={product.clinic && product.clinic.name}
                                  />
                                </Link>
                              </div>
                            </div>
                            <div className="compare-table__product-name">
                              <Link
                                className="cart-table__product-name"
                                to={`/clinics/${product.clinic_id}`}
                              >
                                {product.clinic && product.clinic.name}
                              </Link>
                            </div>
                          </div>
                        </td>
                      ))}
                    <td className="compare-table__column compare-table__column--fake" />
                  </tr>
                  <tr className="compare-table__row">
                    <th className="compare-table__column compare-table__column--header">
                      {lan.servic}
                    </th>
                    {props.compareServices.length !== 0 &&
                      props.compareServices.map((product) => (
                        <td
                          key={product.id}
                          className="compare-table__column compare-table__column--product"
                        >
                          <Link
                            className="cart-table__product-name"
                            to={`/services/${
                              product.standard_service &&
                              product.standard_service.id
                            }`}
                          >
                            {product.name}
                          </Link>
                        </td>
                      ))}
                    <td className="compare-table__column compare-table__column--fake" />
                  </tr>
                  <tr className="compare-table__row">
                    <th className="compare-table__column compare-table__column--header">
                      {lan.price}
                    </th>
                    {props.compareServices.length !== 0 &&
                      props.compareServices.map((product) => (
                        <td
                          key={product.id}
                          className="compare-table__column compare-table__column--product"
                        >
                          {product.price} {lan.sum}
                        </td>
                      ))}
                    <td className="compare-table__column compare-table__column--fake" />
                  </tr>
                  {location.length !== 0 && (
                    <>
                      <tr className="compare-table__row">
                        <th className="compare-table__column compare-table__column--header">
                          {lan.distanceUs}
                        </th>
                        {props.compareServices.length !== 0 &&
                          props.compareServices.map((product) => (
                            <td
                              key={product.id}
                              className="compare-table__column compare-table__column--product"
                            >
                              {(product.clinic &&
                                countDistance(location, [
                                  product.clinic.location_y,
                                  product.clinic.location_x,
                                ])) ||
                                0}{" "}
                              {lan.km}
                            </td>
                          ))}
                        <td className="compare-table__column compare-table__column--fake" />
                      </tr>
                      <tr className="compare-table__row">
                        <th className="compare-table__column compare-table__column--header">
                          {lan.roadPrice}
                        </th>
                        {props.compareServices.length !== 0 &&
                          props.compareServices.map((product) => (
                            <td
                              key={product.id}
                              className="compare-table__column compare-table__column--product"
                            >
                              {product.clinic &&
                                Math.round(
                                  countDistance(location, [
                                    product.clinic.location_y,
                                    product.clinic.location_x,
                                  ]) * props.roadPrice
                                )}{" "}
                              {lan.sum}
                            </td>
                          ))}
                        <td className="compare-table__column compare-table__column--fake" />
                      </tr>
                      <tr className="compare-table__row">
                        <th className="compare-table__column compare-table__column--header">
                          {lan.roadAndServicePrice}
                        </th>
                        {props.compareServices.length !== 0 &&
                          props.compareServices.map((product) => (
                            <td
                              key={product.id}
                              className="compare-table__column compare-table__column--product"
                            >
                              {product.clinic &&
                                Math.round(
                                  countDistance(location, [
                                    product.clinic.location_y,
                                    product.clinic.location_x,
                                  ]) * props.roadPrice
                                ) + product.price}{" "}
                              {lan.sum}
                            </td>
                          ))}
                        <td className="compare-table__column compare-table__column--fake" />
                      </tr>
                    </>
                  )}
                  <tr className="compare-table__row">
                    <th className="compare-table__column compare-table__column--header">
                      <span className="sr-only">{lan.delete}</span>
                    </th>
                    {props.compareServices.length !== 0 &&
                      props.compareServices.map((product) => (
                        <td
                          key={product.id}
                          className="compare-table__column compare-table__column--product"
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary"
                            onClick={() => props.deleteCompare(product)}
                          >
                            {lan.delete}
                          </button>
                        </td>
                      ))}
                    <td className="compare-table__column compare-table__column--fake" />
                  </tr>
                  <tr className="compare-table__row">
                    <th className="compare-table__column compare-table__column--header">
                      <span className="sr-only">{lan.delete}</span>
                    </th>
                    {props.compareServices.length !== 0 &&
                      props.compareServices.map((product) => (
                        <td
                          key={product.id}
                          className="compare-table__column compare-table__column--product"
                        >
                          <ButtonOpeningTimeTableCustom item={product} />
                        </td>
                      ))}
                    <td className="compare-table__column compare-table__column--fake" />
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
    compareServices: state.app.compareServices,
    roadPrice: state.app.roadPrice,
  };
};

export default connect(mapStateToProps, { getCompareServices, deleteCompare })(
  ComparePage
);

const ButtonOpeningTimeTableCustom = ({ item }) => {
  const [ButtonOpeningTimeTable] = useBuyService(item);

  return ButtonOpeningTimeTable;
};

import React, { useState } from "react";
import { Form, Input, Button, Col } from "antd";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendData } from "../server/requestFunctions";
import { phoneFormat } from "../functions";

const ResetPasswordForgetP = () => {
  const [form] = Form.useForm();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);
  const lan = useSelector((state) => state.app.language);
  const history = useHistory();
  const register = (values) => {
    setLoading(true);
    sendData(`send-sms`, phoneFormat(values.phone))
      .then(() => {
        form.resetFields();
        setPhoneNumber(values.phone);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const sendPasswordForReset = (values) => {
    setLoadingCode(true);
    sendData("update-password", { ...values, ...phoneFormat(phoneNumber) })
      .then(() => {
        toast.success(lan.changePasswordSuccessfully);
        history.push("/account");
      })
      .finally(() => {
        setLoadingCode(false);
      });
  };
  return (
    <div id="doctorsComp" className="top-bottom">
      <div className="container-lg px-lg-0 d-flex justify-content-center">
        <Col md={12} span={24}>
          <div className="card flex-grow-1">
            <div className="card-body">
              {phoneNumber ? (
                <Form
                  form={form}
                  name="code"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  onFinish={sendPasswordForReset}
                >
                  <Form.Item
                    label={`${lan.enterCodeSendedToThisPhone} (${phoneNumber})`}
                    name="key"
                    rules={[
                      {
                        required: true,
                        message: lan.notFilled,
                      },
                    ]}
                  >
                    <Input disabled={loadingCode} placeholder={lan.enterCode} />
                  </Form.Item>
                  <Form.Item
                    label={lan.password}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: lan.notFilled,
                      },
                    ]}
                  >
                    <Input.Password
                      disabled={loadingCode}
                      placeholder={lan.password}
                    />
                  </Form.Item>
                  <div className="d-flex justify-content-between">
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={loadingCode}
                        loading={loadingCode}
                      >
                        {lan.sending}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              ) : (
                <>
                  <h3 className="card-title">{lan.resetPassword}</h3>
                  <Form
                    form={form}
                    name="reset"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    onFinish={register}
                  >
                    <Form.Item
                      label={lan.phoneNumber}
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: lan.notFilled,
                        },
                        {
                          pattern: /^[89][012345789][0-9]{7}$/,
                          message: lan.enterRightNumber + " (991234567)",
                        },
                      ]}
                    >
                      <Input
                        disabled={loading}
                        placeholder={lan.enterPhoneUsernameGmail}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        disabled={loading}
                      >
                        {lan.gettingSms}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Col>
      </div>
    </div>
  );
};

export default ResetPasswordForgetP;

import React from "react";
import { connect } from "react-redux";
import IllCard from "../components/Cards/IllCard";
import { Tabs } from "antd";
import useFetch from "../hooks/useFetch";
import Title from "../components/Shared/Title";

const { TabPane } = Tabs;

const SpecificSymptomPage = (props) => {
  const lan = props.lan;
  const [symptom] = useFetch(`symptoms/symptom/${props.match.params.id}`);
  const [sicknesses] = useFetch(
    `sickness/sickness-list?lang=uz&symptom_id=${props.match.params.id}`
  );

  return (
    <div className="top-bottom">
      <div className="container px-0">
        <Title title={symptom.name} />
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab={lan.description} key="1">
            <p
              dangerouslySetInnerHTML={{
                __html: symptom.description,
              }}
            />
          </TabPane>
          <TabPane tab={lan.illnesses} key="2">
            {sicknesses.map((item) => (
              <div key={item.id} className="oneIllnessCard mb-3">
                <IllCard item={item} to={"/illness/" + item.id} />
              </div>
            ))}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(SpecificSymptomPage);

import React, { useEffect } from "react";
import AccountComp from "../../components/Layouts/AccountLayout";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import NoData from "../../components/Shared/NoData";
import useFetchWithPgtn from "./../../hooks/useFetchWithPgtn";
import Loading from "./../../components/Shared/Loading";
import OrderPDFGenerator from "../../components/Shared/OrderPDFGenerator";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { isEmpty } from "../../functions";
import { getUserData } from "../../redux/actions/appActions";

const StatusOrdersP = () => {
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.app.language);
  const user = useSelector((state) => state.app.userData);

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  const {
    data: orders,
    pgtn,
    loading,
  } = useFetchWithPgtn("order/get-active-list");
  return (
    <AccountComp>
      <div className="card">
        {loading ? (
          <Loading />
        ) : (
          <>
            <table className="cart-table__table">
              <thead className="cart-table__head">
                <tr className="cart-table__row">
                  <th className="cart-table__column cart-table__column--image">
                    {lan.img}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.servic}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.clinic}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.code}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.price}
                  </th>
                  <th className="cart-table__column cart-table__column--time">
                    {lan.createdTime}
                  </th>
                  <th className="cart-table__column cart-table__column--time">
                    {lan.time}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.ordersStatus}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.download}
                  </th>
                </tr>
              </thead>
              <tbody className="cart-table__body">
                {isEmpty(orders) ||
                  orders.map((item) => (
                    <tr className="cart-table__row">
                      <td className="cart-table__column cart-table__column--image">
                        <div className="image image--type--product">
                          <Link
                            className="image__body"
                            to={`/clinics/${item.clinic_id}`}
                          >
                            <img
                              alt=""
                              className="image__tag"
                              src={
                                item.clinic.images[0].image ||
                                "/images/no-hospital.png"
                              }
                            />
                          </Link>
                        </div>
                      </td>
                      <td className="cart-table__column cart-table__column--product">
                        <Link
                          className="cart-table__product-name"
                          to={`/services/${item.service.service_category_id}`}
                        >
                          {item.service.name}
                        </Link>
                      </td>
                      <td className="cart-table__column cart-table__column--product">
                        <Link
                          className="cart-table__product-name"
                          to={`/clinics/${item.clinic_id}`}
                        >
                          {item.clinic.name}
                        </Link>
                      </td>
                      <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                      >
                        {item.promo_code}
                      </td>
                      <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                      >
                        {item.price}
                      </td>
                      <td
                        className="cart-table__column cart-table__column--time"
                        data-title={lan.time}
                      >
                        <div>
                          {item.created_at.slice(0, 10)}{" "}
                          {item.created_at.slice(11, 16)}
                        </div>
                      </td>
                      <td
                        className="cart-table__column cart-table__column--time"
                        data-title={lan.time}
                      >
                        <div>
                          <div
                            className="timetablecard"
                            style={{
                              font: "bold 12px/22px Arial",
                              width: "62px",
                            }}
                          >
                            {item.service_time.split(" ").map((el) => (
                              <p key={el} className="p-0">
                                {el}
                              </p>
                            ))}
                          </div>
                          {/* <div
                          key={index}
                          className="timetablecard"
                          style={{
                            font: "bold 12px/22px Arial",
                            width: "62px",
                          }}
                        >
                          <p className="p-0">{day.objectDate}</p>
                        </div> */}
                        </div>
                      </td>
                      <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                      >
                        {lan.statuses[item.status]}
                      </td>
                      <td
                        className="cart-table__column cart-table__column--price"
                        data-title="Price"
                      >
                        <PDFDownloadLink
                          document={
                            <OrderPDFGenerator
                              name={user.name + " " + user.surname}
                              service={item.service.name}
                              time={item.service_time}
                              price={item.price}
                              promo_code={item.promo_code}
                              clinic={item.clinic.name}
                              doctor={item.doctor.user.fio}
                            />
                          }
                          fileName={`${item.service.name}.pdf`}
                        >
                          {({ blob, url, loading, error }) =>
                            loading ? (
                              lan.loading
                            ) : (
                              <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                              />
                            )
                          }
                        </PDFDownloadLink>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {orders.length === 0 && <NoData />}
            {pgtn}
          </>
        )}
      </div>
    </AccountComp>
  );
};

export default StatusOrdersP;

import styled from "styled-components";

export const ButtonM = styled.button`
  height: 30px;
  padding: 0 16px;
  font-size: 15px;
  position: relative;
  background: #1a8194;
  color: #fff;
  border: none;
  border-radius: 2px;
  font-weight: 500;
  transition: background-color 0.15s, color 0.15s;
  &:hover {
    background-color: #333;
    color: white;
  }
`;

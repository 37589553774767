// import { Alert } from "antd";
import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const CommonLayout = (props) => {
  return (
    <React.Fragment>
      <Header
        isNotSearchComp={props.isNotSearchComp}
        searchTitle={props.searchTitle}
      />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};

export default CommonLayout;

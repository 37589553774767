import React from "react";
import BigServiceCard from "../../components/Cards/BigServiceCard";
import SpecificClinicLayout from "../../components/Layouts/SpecificClinicLayout";
import { Row, Col } from "antd";
import { useSelector } from "react-redux";
import InfiniteScrollCustom from "../../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from "../../hooks/useFetchWithScrollPgtn";

const SpecificClinicServicesPage = (props) => {
  const {
    data: services,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn(`clinic/services/${props.match.params.id}`);
  const lan = useSelector((state) => state.app.language);

  return (
    <SpecificClinicLayout breadcrumbs={[lan.services]}>
      <InfiniteScrollCustom
        data={services}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
      >
        <Row gutter={[8, 8]}>
          {services.map((item) => (
            <Col md={12} span={24} key={item.id}>
              <BigServiceCard item={item} />
            </Col>
          ))}
        </Row>
      </InfiniteScrollCustom>
    </SpecificClinicLayout>
  );
};

export default SpecificClinicServicesPage;

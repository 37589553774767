import { Row, Col } from "antd";
import React, { useEffect } from "react";
import ConvenienceCard from "../Cards/ConvenienceCard2";
import "./Conveniences.scss";
import AOS from "aos";
import { useSelector } from "react-redux";

const Conveniences = () => {
  useEffect(() => {
    AOS.init({
      duration: 500,
    });
  });
  const lan = useSelector((state) => state.app.language);
  
  return (
    <div className="conveniences">
      <header className="col-lg-12 text-center" data-aos="zoom-in-up">
        <h2 className="separator_off">
          {lan.whatCanOurSiteGiveYou}
        </h2>
        <h6 className="subtitle">{lan.allConveniencesYouCanWant}</h6>
      </header>
      <Row gutter={[16, 16]}>
        {lan.conveniences.map((item, index) => (
          <Col key={index} span={24} sm={24} md={12} lg={12}>
            <div className="mb-4" data-aos="zoom-in-up">
              <ConvenienceCard {...item} />
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Conveniences;

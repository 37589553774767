import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { accountMenus } from "../../../constants/accountMenus";
import "../../../style/AccountPages.scss";
import LogoutButton from "../../Buttons/LogoutButton";

const AccountLayout = (props) => {
  const lan = props.lan;
  return (
    <div className="accountComp top-bottom">
      <div className="container-lg px-lg-0">
        <div className="row mx-0">
          <div className="col-12 col-lg-2 d-flex px-0">
            <div className="account-nav flex-grow-1">
              <h4 className="account-nav__title">{lan.navigation}</h4>
              <ul className="account-nav__list">
                {accountMenus.map((item, index) => (
                  <li className="account-nav__item" key={index}>
                    <NavLink
                      activeClassName="active"
                      to={"/account/" + item.name}
                    >
                      {lan[item.name]}
                    </NavLink>
                  </li>
                ))}
                <li className="account-nav__divider"></li>
                <li className="account-nav__item">
                  <LogoutButton />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 col-lg-10 mt-4 mt-lg-0 pl-lg-3 px-0">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(AccountLayout);

import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Tabs, Row } from "antd";
import LoginForm from "../components/Shared/LoginForm";
import { changeAccountKey } from "../redux/actions/appActions";
import RegisterForm from "../components/Shared/RegisterForm";
import UserProfileForm from "../components/Shared/UserProfileForm";
import UserMedForm from "../components/Shared/UserMedForm";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { API_URL_FRONT, SITE_LANG, TOKEN } from "../tools/constants";
import { getCookie } from "../functions/useCookies";
import "../style/LoginPage.scss";

const { TabPane } = Tabs;

const steps = [
  <RegisterForm />,
  <div className="card flex-grow-1">
    <div className="card-body">
      <UserProfileForm />
    </div>
  </div>,
  <UserMedForm href="/" />,
];

const LoginPage = (props) => {
  const lan = props.lan;
  const history = useHistory();
  useEffect(() => {
    axios
      .get(
        `${API_URL_FRONT}user/profile?lang=${localStorage.getItem(SITE_LANG)}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(TOKEN)}`,
          },
        }
      )
      .then(() => {
        history.push("account/myAccount");
      });
  }, []);
  return (
    <div className="top-bottom">
      <div className="block">
        <div className="container container--max--lg">
          <Tabs
            onChange={(value) => props.changeAccountKey(value)}
            activeKey={props.accountKey}
            type="card"
            centered
          >
            <TabPane tab={lan.login} key="1">
              <div className="col-md-6 d-flex m-auto">
                <LoginForm href="/" />
              </div>
            </TabPane>
            <TabPane tab={lan.register} key="2">
              <Row className="m-auto justify-content-center">
                {steps[props.activeDataStep]}
              </Row>
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
    accountKey: state.app.accountKey,
    activeDataStep: state.app.activeDataStep,
  };
};

export default connect(mapStateToProps, { changeAccountKey })(LoginPage);

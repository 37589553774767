import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ClinicServiceCard from "../components/Cards/ClinicServiceCard";
import ServicesFilter from "../components/Filters/ServicesFilter";
import SmallLoading from "../components/Shared/SmallLoading";
import { Select } from "antd";
import useFetch from "./../hooks/useFetch";
import useLocation from "../hooks/useLocation";
import InfiniteScrollCustom from "../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from "../hooks/useFetchWithScrollPgtn";

const { Option } = Select;

const SearchResultsPage = (props) => {
  const id = props.match.params.id;
  const [location] = useLocation([]);
  const [filterData, setFilterData] = useState({ standard_service: id });
  const [changes, setChanges] = useState(false);
  const [serviceTypes] = useFetch(`services/category-list`, {
    category_id: id,
  });
  const [regions] = useFetch("basic-list/regions");
  const [city, setCity] = useState("");
  const lan = useSelector((state) => state.app.language);
  // const search = useSelector((state) => state.app.search);

  useEffect(() => {
    setChanges(!changes);
    setFilterData({ ...filterData, standard_service: id });
  }, [id]);
  const {
    data: services,
    total,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("services/service-list", filterData, [changes]);
  const handlePrice = (value) => {
    if (value) {
      setFilterData({ ...filterData, price: value * 1000 });
    } else {
      delete filterData.price;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    if (e.target.value) {
      setFilterData({ ...filterData, region: e.target.value });
    } else {
      delete filterData.region;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleDistance = (dis) => {
    if (dis) {
      setFilterData({
        ...filterData,
        maxDistance: dis * 1000,
        longitude: location[1],
        latitude: location[0],
      });
    } else {
      if (filterData.maxDistance) {
        delete filterData.maxDistance;
        delete filterData.longitude;
        delete filterData.latitude;
        setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
      }
    }
    setChanges(!changes);
  };
  const handleServiceType = (e) => {
    const typeId = e.target.value;
    if (typeId) {
      setFilterData({ ...filterData, typeId });
    } else {
      delete filterData.typeId;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleSort = (value) => {
    if (value) {
      setFilterData({ ...filterData, sort: value });
    } else {
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  return (
    <div className="top-bottom">
      <div className="container-lg px-lg-0">
        <div className="row mx-0">
          <div className="col-lg-3 px-0">
            <ServicesFilter
              quantity={<ServicesSort lan={lan} handleSort={handleSort} />}
              handleDistance={handleDistance}
              handlePrice={handlePrice}
              handleServiceType={handleServiceType}
              serviceTypes={serviceTypes}
              location={location}
              places={regions}
              handlePlace={handleCity}
              city={city}
            />
          </div>
          <div className="col-lg-9 pl-lg-3 px-0">
            <div className="sort-card">
              <div className="view-options__legend">
                <h4 className="d-flex align-items-center">
                  {loading ? (
                    <SmallLoading />
                  ) : (
                    <span
                      style={{ marginRight: "2px", display: "inline-block" }}
                    >
                      {total}
                    </span>
                  )}
                  <span>
                    {" " + lan.amount + " " + lan.services.toLowerCase()}
                  </span>
                </h4>
              </div>
              <div className="sort-selections d-flex justify-content-between flex-sm-row flex-column">
                <div className="view-options__select d-none d-lg-block">
                  <label htmlFor="view-option-sort">{lan.sorting}:</label>
                  <ServicesSort lan={lan} handleSort={handleSort} />
                </div>
              </div>
            </div>
            <div>
              <InfiniteScrollCustom
                data={services}
                next={fetchMoreData}
                hasMore={hasMore}
                loading={loading}
              >
                {services.map((item) => (
                  <div key={item.serviceItemId} className="mb-3">
                    <ClinicServiceCard item={item} />
                  </div>
                ))}
              </InfiniteScrollCustom>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultsPage;

const ServicesSort = ({ lan, handleSort }) => {
  return (
    <Select
      style={{ width: 200 }}
      placeholder={lan.sortClinics}
      defaultValue=""
      onChange={(e) => handleSort(e)}
    >
      <Option value="">{lan.all}</Option>
      {/* <Option value="RATE">{lan.mostfamous}</Option> */}
      <Option value="asc_name">{lan.increasename}</Option>
      <Option value="desc_name">{lan.decreasename}</Option>
      <Option value="asc_price">{lan.increaseprice}</Option>
      <Option value="desc_price">{lan.decreaseprice}</Option>
      {/* <Option value="DIST_ASC">{lan.increaselocation}</Option>
      <Option value="DIST_DESC">{lan.decreaselocation}</Option> */}
    </Select>
  );
};

import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ClinicsPage from "./pages/ClinicsPage";
import HomePage from "./pages/HomePage";
import IllnessPage from "./pages/IllnessPage";
import SymptomsPage from "./pages/SymptomsPage";
import SpecificIllnessPage from "./pages/SpecificIllnessPage";
import CartPage from "./pages/CartPage";
import LoginPage from "./pages/LoginPage";
import CheckOutPage from "./pages/CheckOutPage";
import NewsPage from "./pages/NewsPage";
import LikePage from "./pages/LikePage";
import SpecificNewsPage from "./pages/SpecificNewsPage";
import QuesAnsPage from "./pages/QuesAnsPage";
import CheckoutHistoryPage from "./pages/CheckoutHistoryPage";
import CitiesPage from "./pages/CitiesPage";
import SearchResultsPage from "./pages/SearchResultsPage";
import ContactUsPage from "./pages/ContactUsPage";
import AboutUsPage from "./pages/AboutUsPage";
import SpecificClinicDoctorsPage from "./pages/SpecificClinicPage/SpecificClinicDoctorsPage";
// import SpecificClinicPage from "./pages/SpecificClinicPage/SpecificClinicPage";
import SpecificClinicTechPage from "./pages/SpecificClinicPage/SpecificClinicTechPage";
import SpecificClinicServicesPage from "./pages/SpecificClinicPage/SpecificClinicServicesPage";
import SpecificClinicCategoriesPage from "./pages/SpecificClinicPage/SpecificClinicCategoriesPage";
import SpecificClinicPackagesPage from "./pages/SpecificClinicPage/SpecificClinicPackagesPage";
import SpecificDoctorPage from "./pages/SpecificDoctorPage";
import ComparePage from "./pages/ComparePage";
import DoctorsPage from "./pages/DoctorsPage";
import SpecificSymptomPage from "./pages/SpecificSymptomPage";
import PackagesPage from "./pages/PackagesPage";
import CommonLayout from "./components/Layouts/CommonLayout";
import NotFound from "./pages/NotFound";
import CityClinicsPage from "./pages/CityClinicsPage";
import ResetPasswordForgetP from "./pages/ResetPasswordForgetP";
import StorageProfileP from "./pages/AccountPages/StorageProfileP";
import SockJS from "sockjs-client";
import { accountMenus } from "./constants/accountMenus";
import { IS_VERIFIED, TOKEN } from "./tools/constants";
import { BackTop, notification } from "antd";
import { getCookie } from "./functions/useCookies";
import { USER_ID, WEB_SOCKET_URL } from "./tools/constants";
import { useDispatch } from "react-redux";
import { getCartProducts } from "./redux/actions/appActions";
var Stomp = require("stompjs");

const routes = [
  {
    route: "",
    page: HomePage,
  },
  {
    route: "doctors/test",
    page: () => <div className="card">Test</div>,
  },
  {
    route: "packages",
    page: PackagesPage,
  },
  {
    route: "doctors/:id",
    page: SpecificDoctorPage,
  },
  {
    route: "quesans",
    page: QuesAnsPage,
  },
  {
    route: "aboutus",
    page: AboutUsPage,
  },
  {
    route: "contactus",
    page: ContactUsPage,
  },
  {
    route: "compare",
    page: ComparePage,
  },
  {
    route: "services/:id",
    page: SearchResultsPage,
  },
  {
    route: "cities",
    page: CitiesPage,
  },
  {
    route: "clinics/:id",
    page: SpecificClinicDoctorsPage,
  },
  {
    route: "clinics/:id/technologies",
    page: SpecificClinicTechPage,
  },
  {
    route: "clinics/:id/services",
    page: SpecificClinicServicesPage,
  },
  {
    route: "clinics/:id/categories",
    page: SpecificClinicCategoriesPage,
  },
  {
    route: "clinics/:id/packages",
    page: SpecificClinicPackagesPage,
  },
  {
    route: "clinics/:id/doctors/:sid",
    page: SpecificDoctorPage,
  },
  {
    route: "news",
    page: NewsPage,
  },
  {
    route: "news/:id",
    page: SpecificNewsPage,
  },
  {
    route: "illness/:id",
    page: SpecificIllnessPage,
  },
  {
    route: "symptoms/:id",
    page: SpecificSymptomPage,
  },
  {
    route: "cart",
    page: CartPage,
  },
  {
    route: "like",
    page: LikePage,
  },
  {
    route: "account",
    page: LoginPage,
  },
  {
    route: "account/prices",
    page: StorageProfileP,
  },
  {
    route: "account-resetpassword",
    page: ResetPasswordForgetP,
  },
  {
    route: "checkout",
    page: CheckOutPage,
  },
  {
    route: "checkout/:id",
    page: CheckoutHistoryPage,
  },
  {
    route: ":id/clinics",
    page: CityClinicsPage,
  },
];

const pagesWithLayout = [
  {
    route: "doctors",
    page: DoctorsPage,
  },
  {
    route: "clinics",
    page: ClinicsPage,
  },
  {
    route: "illness",
    page: IllnessPage,
  },
  {
    route: "symptoms",
    page: SymptomsPage,
  },
];

function App() {
  const soc = new SockJS(WEB_SOCKET_URL);
  const stompClient = Stomp.over(soc);
  const dispatch = useDispatch();
  stompClient.connect({}, function (frame) {
    stompClient.subscribe("/topic/messages", function (msg) {
      if (
        JSON.parse(msg.body).event === "USER_ORDER_APPROVED" &&
        JSON.parse(msg.body).content === getCookie(USER_ID)
      ) {
        dispatch(getCartProducts());
        notification.success({
          message: "Shifoxona tomonidan ma'qullandi !",
          duration: 0,
          placement: "bottomRight",
          description: (
            <div>
              Ma'qullangan xizmatlarni ko'rish uchun Savatcha bo'limiga o'ting
            </div>
          ),
        });
      }
      if (
        JSON.parse(msg.body).event === "USER_ORDER_TIME_FINISHED" &&
        JSON.parse(msg.body).content === getCookie(USER_ID)
      ) {
        dispatch(getCartProducts());
      }
    });
  });
  return (
    <React.Fragment>
      <ToastContainer autoClose={5000} />
      <BackTop />

      <Router>
        <Switch>
          {accountMenus.map((item, index) => (
            <ProtectedRoute
              key={index}
              path={"/account/" + item.name}
              component={item.page}
            />
          ))}
          {routes.map((item, index) => (
            <CommonRoute
              key={index}
              exact
              path={"/" + item.route}
              component={item.page}
            />
          ))}
          {pagesWithLayout.map((item, index) => (
            <Route
              key={index}
              exact
              path={"/" + item.route}
              component={item.page}
            />
          ))}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

function CommonRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(routeProps) => (
        <CommonLayout>
          <Component {...routeProps} />
        </CommonLayout>
      )}
    />
  );
}

const ProtectedRoute = (props) => {
  const token =
    getCookie(TOKEN) && localStorage.getItem(IS_VERIFIED) === "true";
  return (
    token && <CommonRoute exact path={props.path} component={props.component} />
  );
};

export default App;

import React from "react";
import SpecificClinicLayout from "../../components/Layouts/SpecificClinicLayout";
import ClinicIllnessCategories from "../../components/Shared/ClinicIllnessCategories";

const SpecificClinicCategoriesPage = () => {
  
  return (
    <SpecificClinicLayout>
      <ClinicIllnessCategories />
    </SpecificClinicLayout>
  );
};

export default SpecificClinicCategoriesPage;

import React, { useEffect, useState } from "react";
import { TabList, Tab, TabPanel, Tabs } from "react-tabs";
import Search from "./Search";
import "./SearchService.scss";
import Loading from "../Shared/Loading";
import { getData } from "../../server/requestFunctions";

const SearchService = () => {
  const [allCategories, setAllCategories] = useState([]);
  const [menus, setMenus] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(true);
    getData("services/category-list", { type: "category" }).then((res) => {
      setAllCategories(res.data);
      setLoading(false);
      getTabIndex(tabIndex, res.data);
    });
  }, []);

  const getTabIndex = (index, data) => {
    setTabIndex(index);
    getData(`services/category-list`, {
      category_id: data[index] && data[index].id,
      // type: "subcategory",
    }).then((res) => {
      setMenus(
        res.data.map((item) => {
          return { label: item.name, value: item.id };
        })
      );
    });
  };

  return (
    <div className="searchService">
      {loading ? (
        <Loading />
      ) : (
        <Tabs
          selectedIndex={tabIndex}
          onSelect={(index) => getTabIndex(index, allCategories)}
        >
          <TabList>
            {allCategories.map((item, index) => (
              <Tab key={item.id}>{item.name}</Tab>
            ))}
          </TabList>
          {allCategories.map((item) => (
            <TabPanel key={item.id}>
              <Search menus={menus} />
            </TabPanel>
          ))}
        </Tabs>
      )}
    </div>
  );
};

export default SearchService;

import React, { useEffect } from "react";
import LastNews from "../components/Home/LastNews";
import Numbers from "../components/Home/Numbers";
import Advertisement from "../components/Home/Advertisement";
// import Illnesses from "../components/Home/Illnesses";
import Clinics from "../components/Home/Clinics";
import SearchService from "../components/Search/SearchService";
import Conveniences from "../components/Home/Conveniences";
import "../style/HomePage.scss";
import { useDispatch } from "react-redux";
import { updateState } from "../redux/actions/appActions";

const HomePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateState({ search: "" }));
  }, []);
  return (
    <div className="homePage">
      <div className="searchNumbers">
        <div className="block-finder__image"></div>
        <div className="container-lg px-lg-0 pt-5">
          <SearchService />
        </div>
      </div>
      <div className="conveniences__home">
        <div className="conveniences__background"></div>
        <div className="container-lg px-lg-0">
          <Conveniences />
        </div>
      </div>
      <div className="container-lg px-lg-0">
        <Advertisement />
        {/* <Illnesses /> */}
        <Clinics />
        <LastNews />
      </div>
      <div className="numbers">
        <div className="container-lg px-lg-0">
          <Numbers />
        </div>
      </div>
    </div>
  );
};

export default HomePage;

import React from "react";
import { connect } from "react-redux";
import NewsCard from "../components/Cards/NewsCard";
import useFetchWithScrollPgtn from "../hooks/useFetchWithScrollPgtn";
// import LastNewsCard from "../components/Cards/LastNewsCard";
import InfiniteScrollCustom from "./../components/Shared/InfiniteScrollCustom";
import "../style/NewsPage.scss";

const NewsPage = (props) => {
  const lan = props.lan;
  const {
    data: news,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("get-article-list");

  return (
    <div className="newsComp top-bottom">
      <div className="container-lg px-lg-0">
        <h3 className="block-header__title">{lan.news}</h3>
        <div
          className="section-header__divider"
          style={{ marginBottom: "20px" }}
        ></div>
      </div>
      <div className="container-lg px-lg-0">
        <div className="blog-view__item blog-view__item-posts">
          <div className="block posts-view">
            <div className="posts-view__list posts-list posts-list--layout--list">
              <div className="posts-list__body">
                <InfiniteScrollCustom
                  data={news}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loading={loading}
                >
                  {news.map((item) => (
                    <NewsCard {...item} />
                  ))}
                </InfiniteScrollCustom>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="blog-view__item blog-view__item-sidebar">
          <div className="card widget widget-search">
            <form action="" className="widget-search__form">
              <input
                className="widget-search__input"
                type="search"
                placeholder="Blog search..."
              />
              <button type="submit" className="widget-search__button">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path d="M19.2 17.8s-.2.5-.5.8c-.4.4-.9.6-.9.6s-.9.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9-1 .8-2.4 1.3-3.9 1.3-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7c0 1.5-.5 2.9-1.3 4 1.1.8 2.5 2 4 3.1 2.3 1.7 1.5 2.7 1.5 2.7zM8 3C5.2 3 3 5.2 3 8s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z"></path>
                </svg>
              </button>
              <div className="widget-search__field"></div>
            </form>
          </div>
          <div className="card widget widget-posts">
            <div className="widget__header">
              <h4>Latest Posts</h4>
            </div>
            <ul className="widget-posts__list">
              <LastNewsCard />
              <LastNewsCard />
              <LastNewsCard />
              <LastNewsCard />
              <LastNewsCard />
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(NewsPage);

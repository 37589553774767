import React, { useState } from "react";
import { Card, Tag, Modal } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { BiInfoSquare } from "react-icons/bi";
// import { ButtonM } from "../Buttons/ButtonM";
import "./PackageCard.scss";
import { useSelector } from "react-redux";

const { Meta } = Card;
// const { TabPane } = Tabs;

const PackageCard = (props) => {
  const lan = useSelector((state) => state.app.language);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="card package-card">
      <div className="package-img">
        <Card
          hoverable
          cover={
            <LazyLoadImage
              height={200}
              style={{ objectFit: "cover" }}
              alt={props.name}
              src={props.image}
            />
          }
        >
          <div className="package-sort">
            <Tag color="#55acee">{props.category.name}</Tag>
            <br />
            {/* <Tag color="#55acee">{props.whoIs}</Tag> <br />
            <Tag color="#55acee">{props.age} +</Tag> */}
          </div>
          <div className="package-info" onClick={showModal}>
            <BiInfoSquare />
          </div>
          <Meta title={props.name} />
          {props.services &&
            props.services.map((it, ind) => (
              <p>
                {ind + 1}. <Link>{it.name}</Link>
              </p>
            ))}
          <div className="d-flex justify-content-between mt-2">
            <h4>
              {props.price} {lan.sum}
            </h4>
            {/* <ButtonM>Покупка</ButtonM> */}
          </div>
        </Card>
      </div>
      <Modal
        title={`${lan.aboutPackage}, ${props.name}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        cancelText="Yopish"
      >
        <p
          dangerouslySetInnerHTML={{
            __html: props.description,
          }}
        />
        {/*         <Tabs defaultActiveKey="1" centered>
          {props.services &&
            props.services.map((it, index) => (
              <TabPane tab={it.name} key={index}></TabPane>
            ))}
        </Tabs> */}
      </Modal>
    </div>
  );
};

export default PackageCard;

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./CategoryCard.scss";

const CategoryCard = ({ name, img, number, lan, to }) => {
  return (
    <React.Fragment>
      <li className="categories-list__item categories-list__item--has-image">
        <Link to={to}>
          <div className="image image--type--category">
            <div className="image__body">
              <img alt={name} className="image__tag" src={img} />
            </div>
          </div>
          <div className="categories-list__item-name">{name}</div>
        </Link>
        <div className="categories-list__item-products">
          {number} {lan.illnesses.toLowerCase()}
        </div>
      </li>
      <li className="categories-list__divider"></li>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(CategoryCard);

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getData, sendData } from "./../server/requestFunctions";
import Loading from "../components/Shared/Loading";
import {
  MAX_NUMBER_SELECTING_TIME,
  // NUMBER_WEEKS_SERVICE_SCHEDULE,
} from "../tools/constants";
import TimetableCard from "../components/Cards/TimetableCard";
import { Tabs, Modal, Empty, Button, Alert, Radio, Tag, Space } from "antd";
import { TiArrowBackOutline } from "react-icons/ti";
import { ButtonM } from "../components/Buttons/ButtonM";
import { getCartProducts, getStatusOrders } from "../redux/actions/appActions";

const { TabPane } = Tabs;

const useDoctorService = (item) => {
  const lan = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  console.log(item);
  const [selectedService, setSelectedService] = useState("");
  const [selected, setSelected] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [liveOrderLoading, setLiveOrderLoading] = useState(false);

  const [services, setServices] = useState([]);
  const [servicesLoading, setServicesLoading] = useState(true);

  const check = (selectedTime) => {
    var numberOfTimes = selectedTimes.length;
    const dayFinded = selectedTimes.find(
      (d) => JSON.stringify(d) === JSON.stringify(selectedTime)
    );
    if (dayFinded) {
      setSelectedTimes((selectedTimes) =>
        selectedTimes.filter(
          (d) => JSON.stringify(d) !== JSON.stringify(selectedTime)
        )
      );
    } else {
      if (numberOfTimes < MAX_NUMBER_SELECTING_TIME) {
        setSelectedTimes([...selectedTimes, selectedTime]);
      } else {
        toast.error(lan.maxNumberOfSelectingTime);
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedService && !selected) {
      setSelected(true);
      setScheduleLoading(true);
      getData(
        `service-doctors/get-time-table?service_id=${selectedService}&doctor_id=${item.id}`
      ).then((res) => {
        const newSchedule = res.data;
        setSchedule(newSchedule);
        setScheduleLoading(false);
      });
    } else if (selectedService && selected) {
      setLoading(true);
      setSelected(false);
      setSelectedService("");
      setSelectedTimes([]);
      sendData("user-basket/set", {
        service_id: selectedService,
        doctor_id: item.id,
        dates: selectedTimes.map((st) => st.join(" ").slice(0, -3)),
      })
        .then(() => {
          toast.success(lan.orderSendSuccessfully);
          dispatch(getCartProducts());
          dispatch(getStatusOrders());
          setSelectedTimes([]);
          setIsModalVisible(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error(lan.selectDoctorService);
    }
  };

  const back = () => {
    setSelected(false);
  };

  const selectType = (e) => {
    setSelectedService(e.target.value);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const openTimeTable = () => {
    showModal();
    setServicesLoading(true);
    getData(`service-doctors/get-doctor-services/${item.id}`).then((res) => {
      setServicesLoading(false);
      setServices(res.data);
    });
  };

  const sendLiveOrder = (id, day) => {
    setLiveOrderLoading(true);
    getData(
      `order/live?timetableObjectId=${id}&serviceItemId=${selectedService}`
    )
      .then(() => {
        setIsModalVisible(false);
        toast.success(lan.orderSendSuccessfully);
      })
      .finally(() => {
        setLiveOrderLoading(false);
        dispatch(getCartProducts());
      });
  };

  const TimeTableModal = (
    <Modal
      visible={isModalVisible}
      title={[
        <div className="service-modal-title">
          {selected && (
            <Tag onClick={back}>
              <TiArrowBackOutline />
              {lan.back}
            </Tag>
          )}
          <span>
            {lan.appointmentToDoctor}, {item.name}
          </span>
        </div>,
      ]}
      okText={selected ? lan.sending : lan.selectTime}
      cancelText={lan.closing}
      onOk={handleOk}
      onCancel={handleCancel}
      className="service-modal"
      confirmLoading={loading}
    >
      {selected ? (
        <div className="timeTable">
          {scheduleLoading ? (
            <Loading />
          ) : schedule.length !== 0 ? (
            <Tabs tabPosition="left">
              {schedule.map((day) => (
                <TabPane
                  tab={[
                    <>
                      <p>{day.date}</p>
                      <p>{day.weekday}</p>
                    </>,
                  ]}
                  key={day.date}
                >
                  <div className="panel-content">
                    {day.times.length !== 0 ? (
                      day.times.map((time) => (
                        <TimetableCard
                          key={time}
                          time={time}
                          statusOrder={
                            day.yellow_times.includes(time)
                              ? "yellow"
                              : day.red_times.includes(time)
                              ? "red"
                              : "white"
                          }
                          check={() => check([day.date, time])}
                          status={selectedTimes.find(
                            (d) =>
                              JSON.stringify(d) ===
                              JSON.stringify([day.date, time])
                          )}
                        />
                      ))
                    ) : (
                      <div>
                        {/* <Empty
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                          description={lan.noTimeTable}
                        /> */}
                        <Alert
                          message={lan.liveOrder}
                          description={lan.descLiveOrder}
                          type="warning"
                          showIcon
                          closable
                        />
                        <Button
                          className="mt-3"
                          loading={liveOrderLoading}
                          disabled={liveOrderLoading}
                          onClick={() =>
                            sendLiveOrder(day.timetableObjectId, day)
                          }
                        >
                          {lan.buy}
                        </Button>
                      </div>
                    )}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={lan.noTimeTable}
            />
          )}
        </div>
      ) : servicesLoading ? (
        <Loading />
      ) : services.length !== 0 ? (
        <Radio.Group
          onChange={selectType}
          defaultValue={selectedService}
          buttonStyle="solid"
        >
          <Space direction="vertical">
            {services &&
              services.map((service) => (
                <Radio.Button key={service.id} value={service.id}>
                  <p>{service.name}</p>
                  <h5 className="mb-0">
                    {service.price} {lan.sum}
                  </h5>
                </Radio.Button>
              ))}
          </Space>
        </Radio.Group>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={lan.noDoctorServices}
        />
      )}
    </Modal>
  );

  const ApplyingButton = (
    <>
      {item.clinic && item.clinic.allowed_billing ? (
        <ButtonM onClick={openTimeTable}>{lan.consultation}</ButtonM>
      ) : (
        <ButtonM disabled style={{ opacity: 0.5 }}>
          {lan.noConsultation}
        </ButtonM>
      )}
      {TimeTableModal}
    </>
  );
  return [ApplyingButton];
};

export default useDoctorService;

import { requestFront } from "./customAxios";
import { SITE_LANG } from "../tools/constants";
import { query_maker } from "../functions/query_maker";

export const getData = (url, query) => {
  const lang = localStorage.getItem(SITE_LANG);
  query = { lang, ...query };
  const query_str = query_maker(query);
  const config = {
    method: "GET",
    url: url + query_str,
  };
  return requestFront(config);
};

export const sendData = (url, data, query, headers) => {
  const lang = localStorage.getItem(SITE_LANG);
  query = { lang, ...query };
  const query_str = query_maker(query);
  const config = {
    method: "POST",
    url: url + query_str,
    data,
  };
  return requestFront(config, headers);
};

export const downloadFile = (name, urlName, ext) => {
  const config = {
    url: `${urlName}`,
    method: "GET",
    responseType: "blob",
  };
  requestFront(config).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    name = ext ? name + `.${ext}` : name;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  });
};

import { PGNTN_LIMIT } from "../../tools/constants";
import { HttpRequestHub, HttpRequestHubCommon } from "../HttpRequestHub";

export const getAllDataWithPagination = (url, page, sort, keyword) => {
  const config = {
    method: "GET",
    url: `${url}?page=${page}&size=${PGNTN_LIMIT}${
      sort ? `&sort=${sort}` : ""
    }${keyword ? `&keyword=${keyword}` : ""}`,
  };
  return HttpRequestHub(config);
};

export const getAllDataWithFilter = (url, data) => {
  const config = {
    method: "POST",
    url,
    data,
  };
  return HttpRequestHub(config);
};

export const getAllDataWithPaginationAuth = (url, page) => {
  const config = {
    method: "GET",
    url: `${url}?page=${page}&size=${PGNTN_LIMIT}`,
  };
  return HttpRequestHubCommon(config);
};

export const getAllDataWithFilterAuth = (url, data) => {
  const config = {
    method: "POST",
    url,
    data,
  };
  return HttpRequestHubCommon(config);
};

export const getData = (name) => {
  const config = {
    method: "GET",
    url: `${name}`,
  };
  return HttpRequestHub(config);
};

export const getById = (name, id) => {
  const config = {
    method: "GET",
    url: `${name}/${id}`,
  };
  return HttpRequestHub(config);
};

export const sendData = (url, data) => {
  const config = {
    method: "POST",
    url,
    data,
  };
  return HttpRequestHub(config);
};

export const sendDataAuth = (url, data) => {
  const config = {
    method: "POST",
    url,
    data,
  };
  return HttpRequestHubCommon(config);
};

export const getDataAuth = (url) => {
  const config = {
    method: "GET",
    url,
  };
  return HttpRequestHubCommon(config);
};

export const deleteDataAuth = (url) => {
  const config = {
    method: "DELETE",
    url,
  };
  return HttpRequestHubCommon(config);
};

import AccountComp from "../../components/Layouts/AccountLayout";
import React from "react";
import { Collapse } from "antd";
import { connect } from "react-redux";
import NoData from "../../components/Shared/NoData";
import useFetchWithPgtn from "./../../hooks/useFetchWithPgtn";
import Loading from "./../../components/Shared/Loading";

const { Panel } = Collapse;

const AccountQuesAnsP = (props) => {
  const lan = props.lan;
  const {
    data: questions,
    pgtn,
    total,
    loading,
  } = useFetchWithPgtn("user/questions");
  return (
    <AccountComp>
      <div className="card">
        <div className="card-header" style={{ backgroundColor: "white" }}>
          <h4>{lan.help}</h4>
        </div>
        <div className="card-divider"></div>
        <div className="card-body">
          {loading ? (
            <Loading />
          ) : questions.length !== 0 ? (
            <>
              <Collapse
                accordion
                defaultActiveKey={["0"]}
                expandIconPosition="right"
              >
                {questions.map((item) => (
                  <Panel header={item.question} key={item.id}>
                    <div>{item.answer}</div>
                  </Panel>
                ))}
              </Collapse>
              <div className="mt-3 d-flex justify-content-between">
                <h5>
                  {lan.total}: {total} {lan.amount}
                </h5>
                {pgtn}
              </div>
            </>
          ) : (
            <NoData />
          )}
        </div>
      </div>
    </AccountComp>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(AccountQuesAnsP);

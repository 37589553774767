import React, { useState } from "react";
import HospitalCard from "../Cards/HospitalCard";
import "./SearchHospital.scss";
import isEmpty from "lodash.isempty";
import { Link } from "react-router-dom";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import hospital from "../../static/images/hospital.png";
import { generateUrl } from "../../functions/generateUrl";

const mapState = {
  center: [41.346269760432435, 69.21482057595402],
  zoom: 11,
  behaviors: ["default", "scrollZoom"],
};

const SearchHospital = (props) => {
  const [, setActivehover] = useState([]);
  const [preset, setPreset] = useState("islands#violetIcon");

  const hoverHandler = (id) => {
    setActivehover(id);
  };
  const hoverLoser = () => {
    setActivehover("");
  };
  return (
    <div className="searchHospital">
      <div className="d-flex flex-wrap">
        <div className="searchForms">
          <div className="searchFormComp">{props.filter}</div>
        </div>
        <div className="hospitals">
          {props.places.map((item, index) => (
            <Link
              key={index}
              onMouseEnter={() => hoverHandler(item.id)}
              onMouseLeave={hoverLoser}
              to={`/clinics/${item.id}-${generateUrl(item.name)}`}
              className="hospitalLink"
            > 
              <HospitalCard {...item} />
            </Link>
          ))}
        </div>
        <div className="hospitalsMap">
          <div style={{ height: "100vh", width: "100%" }}>
            {!isEmpty(props.places) && (
              <YMaps>
                <Map state={mapState}>
                  {props.places.map((place) => (
                    <Placemark
                      key={place.id}
                      geometry={place.location}
                      mouseenter={() => setPreset("islands#greenIcon")}
                      mouseleave={() => setPreset("islands#violetIcon")}
                      properties={{
                        hintContent: place.name,
                        balloonContent: place.name,
                        balloonContentFooter: place.name,
                        balloonContentHeader: place.name,
                        balloonContentBody: place.name,
                      }}
                      options={{
                        preset: preset,
                        iconLayout: "default#image",
                        iconImageHref: hospital,
                        iconImageSize: [35, 35],
                      }}
                    />
                  ))}
                </Map>
              </YMaps>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchHospital;

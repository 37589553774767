import { generateUrl } from "./generateUrl";

export const generateClinicUrl = (id, name) => {
  return name ? id + "-" + generateUrl(name) : "";
};

export const isEmpty = (data) => {
  if (data === undefined || data === null || data === "") {
    return true;
  }
  if (Array.isArray(data)) {
    if (data.length !== 0) {
      return false;
    } else {
      return true;
    }
  } else {
    if (Object.keys(data).length !== 0) {
      return false;
    } else {
      return true;
    }
  }
};

export const phoneFormat = (phone) => {
  return { phone: "998" + phone };
};

export const makingTimetable = (interval, start, end) => {
  const clear_start = start.slice(0, 5);
  const clear_end = end.slice(0, 5);
  let timetable = [];
  let int_hours = parseInt(interval / 60);
  let int_minutes = interval % 60;

  let start_num = parseInt(clear_start.split(":").join(""));
  let end_num = parseInt(clear_end.split(":").join(""));

  if (start_num >= end_num) {
    return new Error("Error");
  }

  let new_start = clear_start;

  while (end_num >= start_num) {
    timetable.push(new_start);

    let new_hour = parseInt(new_start.slice(0, 2)) + int_hours;
    let new_minutes = parseInt(new_start.slice(3)) + int_minutes;
    let new_minutes_hours = parseInt(new_minutes / 60);

    new_hour += new_minutes_hours;
    new_minutes = new_minutes % 60;

    if (new_hour < 10) {
      new_hour = "0" + new_hour;
    }
    if (new_minutes < 10) {
      new_minutes = "0" + new_minutes;
    }

    new_start = new_hour + ":" + new_minutes;
    start_num = parseInt(new_start.split(":").join(""));
  }
  return timetable;
};

import { Modal } from "antd";
import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { connect } from "react-redux";

const FilterLayout = (props) => {
  const lan = props.lan;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div className="widget">
      <div className="widget__header widget-filters__header d-flex justify-content-between align-items-center">
        <div
          className={`filter__img d-lg-flex align-items-center ${
            props.mobile ? "d-flex" : "d-none"
          }`}
        >
          <h4 className="mr-1">{props.title || lan.filter}</h4>
          <LazyLoadImage src="/images/filter.png" alt="Filter" />
        </div>
        <div
          className={`filter__img align-items-center ${
            props.mobile ? "d-none" : "d-lg-none d-flex"
          }`}
          onClick={showModal}
        >
          <h4 className="mr-1">{props.title || lan.filter}</h4>
          <LazyLoadImage src="/images/filter.png" alt="Filter" />
        </div>
        <h4 className={props.mobile ? "d-none" : "d-lg-none d-block"}>
          {props.quantity}
        </h4>
      </div>
      <div className={props.mobile ? "d-block" : "d-none d-lg-block"}>
        {props.children}
      </div>
      <Modal
        title={lan.filter}
        okText={lan.seeResults}
        cancelText={lan.closing}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        {props.children}
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(FilterLayout);

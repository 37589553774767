import { MAX_NUMBER_SELECTING_TIME } from "../tools/constants";

export const UZ = {
  menus: [
    "Viloyatlar",
    "Shifoxonalar",
    "Yangiliklar",
    "Savol Javoblar",
    "Shifokorlar",
    "Ma'lumotnoma",
  ],
  reference: "Ma'lumotnoma",
  lanName: "Uz",
  lan: "Til",
  lanCat: ["Uz", "Ru"],
  aboutMenu: "Biz haqimizda",
  allHosp: "Barcha shifoxonalar",
  allClients: "Barcha mijozlar",
  allDoctors: "Barcha doktorlar",
  contactMenu: "Kontaktlar",
  quote: "Bemorlar uchun eng zo'r sayt",
  currercyMenu: "Valyuta",
  search: "Qidiruv",
  currercyCat: "",
  myAccount: "Shaxsiy kabinet",
  enterSys: "Sistemaga kirish",
  loginEmPl: "customer@example.com",
  password: "Parol",
  loginForget: "Unutdingizmi",
  enterButton: "Kirish",
  register: "Ro'yxatdan o'tish",
  registerSuccessfully: "Вы успешно зарегистрировались !",
  basketFree: "Sizning savatingiz bo'sh",
  passToSer: "Xizmatlar bo'limiga o'tish",
  hello: "Salom",
  basket: "Savatcha",
  searchTabs: ["Analiz", "Diagnostika", "Lecheniya", "Profilaktika"],
  menuPl: "Menular",
  subMenuPl: "Ichki Menyular",
  searchCom: "Qidiruv",
  editProfilTitle: "Ma'lumotlarni o'zgartirish",
  ordersHistory: "Buyurtmalar tarixi",
  editPassword: "Parolni o'zgartirish",
  logout: "Chiqish",
  email: "Elektron pochta",
  order: "Buyurtma qilish",
  filter: "Filtr",
  regions: "Viloyatlar",
  clinics: "Klinikalar",
  news: "Yangiliklar",
  help: "Savol Javoblar",
  services: "Xizmatlar",
  categories: "Kategoriyalar",
  price: "Narx",
  distance: "Masofa",
  connect: "Telefon orqali shifokor bilan uchrashuvga yoziling",
  navigation: "Navigatsiya",
  profile: "Ma'lumotlarim",
  oldPassword: "Avvalgi parol",
  newPassword: "Yangi parol",
  repeatPassword: "Parolni takrorlang",
  edit: "O'zgartirish",
  name: "Ism",
  lname: "Familiya",
  phoneNumber: "Telefon raqam",
  save: "Saqlash",
  toHome: "Bosh menu",
  gallery: "Sevimlilar",
  img: "Rasm",
  illness: "Kasallik",
  illnesses: "Kasalliklar",
  servic: "Xizmat",
  quantity: "Soni",
  totalPrice: "Jami narxi",
  cartTotals: "Jami savatcha",
  total: "Jami",
  sending: "Jo'natish",
  send: "Jo'natish",
  resend: "Qayta jo'natish",
  freeCart: "Savatcha bo'sh",
  freeCartDesc: "Savatda hali hech narsa yo'q",
  yourBooks: "Sizning buyurtmangiz",
  payInf: "To'lov ma'lumotlari",
  required: "Majburiy",
  any: "Ixtiyoriy",
  orderNote: "Buyurtma eslatmalari",
  from: "Dan",
  to: "Gacha",
  return: "Qaytgan mijozmi? ?",
  clickHere: "Kirish uchun shu erni bosing",
  sendAdmin: "Administratorga jo'natish",
  sentAdmin: "Administratorga jo'natilgan",
  connectUs: "Biz bilan aloqa",
  ourPos: "Bizning manzil",
  workingTime: "Ish vaqti",
  inf: "Ma'lumot",
  links: "Ijtimoiy tarmoqlar",
  connectMass: "Bizni ijtimoiy tarmoqlarda kuzatib boring",
  schet: "Tarix",
  message: "Xabar",
  sendMessage: "Xabarni jo'natish",
  comments: "Izohlar",
  onMap: "Xaritada ko'rish",
  display: "Soni",
  sorting: "Sortlash",
  whatHelp: "Sizga nima yordam kerak ?",
  helpPl:
    "Vaziyatni tasvirlab bering, shunda biz qanday harakat qilishni tushunamiz",
  commonHos: "Eng yaxshi shifoxonalar",
  lastNews: "Oxirgi yangiliklar",
  more: "Batafsil",
  distanceUs: "Uzoqligi",
  roadPrice: "Yo'l narxi",
  roadAndServicePrice: "Yo'l kira va xizmat narxi",
  clinic: "Shifoxona",
  from2: "/",
  sortOptions: [
    "Narx bo'yicha o'sish",
    "Narx bo'yicha kamayish",
    "Yangilari",
    "Mashhurlari",
  ],
  km: "km",
  login: "Kirish",
  remindMe: "Eslab qolinsinmi ?",
  directorFIO: "Bosh rahbar, Dilshod Yakubov Damirovich",
  sortIll: ["Nomi (Z-A)", "Nomi (А-Z)"],
  compare: "Taqqoslash",
  doctors: "Shifokorlar",
  doctor: "Shifokor",
  consultation: "Yozilish",
  noConsultation: "Ruxsat yo'q",
  symptoms: "Simptomlar",
  time: "Vaqt",
  medCart: "MedKarta",
  packages: "Paketlar",
  medData: "Tibbiy Ma'lumotlar",
  ordersStatus: "Buyurtmalar holati",
  bestIllnessCategories: "Kasallik kategoriyalari",
  allNews: "Barcha yangiliklar",
  watchClinics: "Shifoxonalarni ko'rish",
  username: "Foydalanuvchi nomi",
  enterConfirmPassword: "Istimos tasdiqlash kodini kiriting !",
  passwordMatchError: "Siz kiritgan ikkita parol mos emas!",
  notFilled: "To'ldirilmagan !!!",
  loginError: "Parol yoki foydalanuvchi nomi xato !",
  addCart: "Qo'shish",
  addCompare: "Taqqoslash",
  location: "Kasalxona joylashgan manzil",
  youseeallclinics: "Siz barcha shifoxonalarni ko'rib bo'ldingiz !",
  amount: "ta",
  sortClinics: "Klinikalarni sortlash",
  increasename: "Nomi bo'yicha o'sish",
  decreasename: "Nomi bo'yicha kamayish",
  increaseprice: "Narx bo'yicha o'sish",
  decreaseprice: "Narx bo'yicha kamayish",
  increaselocation: "Masofasi bo'yicha o'sish",
  decreaselocation: "Masofasi bo'yicha kamayish",
  mostfamous: "Eng mashhurlari",
  all: "Barchasi",
  noSymptoms: "Simptomlar mavjud emas",
  readMore: "To'liqroq o'qish",
  allmonths: [
    "Yanvar",
    "Fevral",
    "Mart",
    "April",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentabr",
    "Oktabr",
    "Noyabr",
    "Dekabr",
  ],
  byMade: "muallif",
  selectDoctorService: "Shifokor xizmatini tanlang",
  selectServiceDoctor: "Xizmat uchun shifokorni tanlang",
  youseeallnews: "Siz barcha yangiliklarni ko'rib bo'ldingiz !",
  youseeall: "Siz barchasini ko'rib bo'ldingiz !",
  closing: "Yopish",
  seeResults: "Natijalarni ko'rish",
  appointmentToDoctor: "Shifokor qabuliga yozilish",
  questionsendsuccessfully: "Siz savolingiz muvaffaqqiyatli yuborildi !",
  messagesendsuccessfully: "Siz xabaringiz muvaffaqqiyatli yuborildi !",
  emailValidation: "Elekron pochtani to'g'ri kiriting !",
  messageTitle: "Xabar sarlavhasi",
  title: "Sarlavha",
  description: "Tavsif",
  characteristics: "Xususiyatlar",
  technologies: "Texnologiya",
  map: "Karta",
  selectTime: "Vaqtni tanlash",
  back: "Orqaga",
  enterCode: "Kodni kiriting !",
  noTimeTable: "Vaqtlari mavjud emas !",
  noDoctorServices: "Doktorning xizmatlari mavjud emas !",
  aboutPackage: "Paket haqida",
  age: "Yoshi",
  gender: "Jins",
  permanentAddress: "Doimiy yashash manzili",
  address: "Yashash manzili",
  nationality: "Millati",
  profession: "Kasb",
  userpicture: "Foydalanuvchi rasmi",
  biologicData: "Biologik ma'lumotlar",
  placeOfBirth: "Tug'ilgan joyi",
  date: "Sana",
  dateOfBirth: "Tug'ilgan sanasi",
  numberOfChildrenInFamily: "Oiladagi farzand soni",
  peopleLivedWithIll: "Bemor bilan yashaydigan odamlar",
  degree: "Ma'lumoti",
  degreeOptions: [
    "Boshlang'ich",
    "O'rta",
    "O'rta maxsus",
    "Oliy",
    "To'liq",
    "To'liqmas",
  ],
  sexualHistory: "Jinsiy anamniz",
  timeStartedSexualLife: "Jinsiy hayotni boshlagan vaqti",
  menarche: "Menarxe",
  menstruation: "Xayzning kechishi",
  menstruationOptions: ["Og'riqli", "Og'riqsiz", "O'z vaqtida", "Kech"],
  pregnancy: "Xomiladorlik",
  numberOfAbortions: "Abortlar soni",
  maritalStatus: "Oilaviy holati",
  marriedStatusIll: "Bemorning turmush holati",
  married: "Oilali",
  single: "Bo'ydoq",
  numberMarriage: "Nigoh soni",
  numberChildren: "Bolalari soni",
  laborHistory: "Mehnat anamnezi",
  ageStartedWork: "Necha yoshdan ishlaydi ?",
  characterAndconditionCurrentWork:
    "Hozirgi paytdagi ishining xarakteri va sharoiti",
  disadvantagesOfProfession: "Kasbining zararli tomonlari",
  allPlacesWorked: "Barcha ishlagan joylari",
  typeLabor: "Mehnat turi",
  mental: "Aqliy",
  physical: "Jismoniy",
  processLabor: "Mehnat jarayoni",
  whileSitting: "O'tirgan holda",
  whileStanding: "Turgan holda",
  conditionCurrentLabor: "Bemor ishlayotgan joyning sharoti",
  low: "Past",
  middle: "O'rtacha",
  high: "Yuqori",
  light: "Yorug'",
  middlelight: "O'rtacha",
  dark: "Qorong'u",
  underGround: "Yer ostida",
  openAir: "Ochiq havoda",
  inRoom: "Xonada",
  harmfulHabits: "Zararli odatlari",
  drinkingAlcogol: "Alkogol istemol qiladimi ?",
  smoking: "Chekadimi ?",
  drinkOfIll: "Istemol qiladigan ichimliklari",
  bitterTea: "Achchiq choy",
  cofe: "Kofe",
  drugs: "Narkotik",
  passedSickness: "O'tkazgan kasalliklari",
  allergologicHistory: "Allergik anamnez",
  specificAllergens: "Konkret allergen",
  reactionToMedicine: "Dorilarga nisbatan reaksiya",
  allergySymptoms: "Allergiya belgilari",
  epidemilogicalHistory: "Epidemiologik anamnez",
  haveJaundice: "Sariqlik bilan ogʼriganmi ?",
  receivedBloodTransfusion: "Bemorga qon quyilganmi ?",
  haveContactWithInfectedPatients:
    "Infektsion bemorlar bilan muloqotda boʼlganmi ?",
  withWhatInfectiousPatient: "Qanday infeksion bemor bilan",
  haveBeenAbroadOrDentist: "Oxirgi 6 oy ichida chet elda boʼlganmi ?",
  countriesWent: "Borgan mamlakatlaringiz",
  hereditaryDiseasesParents: "Ota yoki ona tomonidan nasliy kasalliklar",
  hereditaryPredisposition: "Irsiy moyillik",
  halthOfCloseRelatives: "Yaqin qarindoshlarning sogʼligʼi",
  yes: "Ha",
  no: "Yo'q",
  saveSuccessfully: "Muvaffaqqiyatli saqlandi",
  skip: "O'tkazib yuborish",
  sendingCodeToPhone: "Kod sizning telefoningizga qayta yuborildi !",
  forgetPassword: "Parolni unutdingizmi ?",
  resetPassword: "Parolni tiklash",
  gettingSms: "Sms ni olish",
  changePasswordSuccessfully: "Parolingiz muvaffaqqiyatli o'zgartirildi !",
  medcartPricingOptions: "Hajm narxlari",
  buy: "Sotib olish",
  districts: "Tumanlar",
  totalWrittenBytes: "Barcha fayllar",
  noServiceTimetable: "Xizmatning vaqt jadvali mavjud emas !",
  noData: "Ma'lumotlar mavjud emas !",
  selectGender: "Iltimos jinsingizni tanlang !",
  temperature: "Harorat",
  lightness: "Yorug'ligi",
  fromWhatYear: "Nechanchi yildan beri",
  inWhatQuantity: "Qanday miqdorda",
  carts: "Kartalarim",
  cardName: "Karta nomi",
  cardNumber: "Karta raqami",
  cardExpiry: "Muddati",
  timeScheduleOfService: "Xizmatning vaqt jadvali",
  maxNumberOfSelectingTime: `Siz maksimal ${MAX_NUMBER_SELECTING_TIME} ta vaqtni tanlay olasiz`,
  orderSendSuccessfully: "Siz buyurtmangiz shifoxona qabuliga jo'natildi",
  male: "Erkak",
  female: "Ayol",
  MALE: "Erkak",
  FEMALE: "Ayol",
  liveOrder: "Jonli navbat",
  descLiveOrder:
    "Siz ushbu kunda klinikaga borasiz hamda jonli navbatda turasiz !",
  reservedServices: "Jo'natilganlar",
  approvedServices: "Ma'qullaganlar",
  sum: "so'm",
  yourMedData: "Sizning tibbiy ma'lumotnomangiz",
  createdBySelf: "Bemor yaratganlari",
  createdByDoctor: "Shifokor yaratganlari",
  payment: "To'lash",
  makePayment: "To'lovni amalga oshirish",
  compareServices: "Solishtirish uchun xizmatlar",
  delete: "O'chirish",
  access: "Foydalanish huquqi",
  mainpage: "Bosh sahifa",
  maindata: "Asosiy malumotlari",
  fullname: "To'liq ism",
  languagesknow: "Biladigan tillari",
  education: "Ta'lim",
  experience: "Tajriba",
  graduatedUniversity: "Tamomlagan bilim yurti",
  numberOfDoctors: "Shifokorlar soni",
  numberOfBeds: "Krovatlar soni",
  totalTerritory: "Umumiy maydon yuzi",
  cancel: "Bekor qilish",
  statuses: {
    created: "YARATILDI",
    active: "TO'LANDI",
    progress: "DOKTOR QABUL QILDI",
    canceled: "BEKOR QILINDI",
    completed: "YAKUNLANDI",
  },
  samarqand: "Samarqand",
  tashkent: "Toshkent",
  whatCanOurSiteGiveYou: "Bizning sayt sizga nima bera oladi ?",
  procurementProcess: "Sotib olish jarayoni",
  notApprovedServices:
    "Shifoxonada tomonidan ma'qullangan xizmatlar hozircha sizda yo'q",
  notReservedServices:
    "Shifoxonaga tasdiqlash uchun jo'natilgan xizmatlar mavjud emas",
  existingApprovedServices:
    "Sizda shifoxona tomonidan ma'qullangan xizmatlar mavjud !",
  seeing: "Ko'rish",
  workExperience: "Ish tajribasi",
  year: "yil",
  searchingSymptom: "Simptomini qidirish",
  searchingSickness: "Kasallikni qidirish",
  comment: "Izoh",
  thanksForComment: "Izoh uchun rahmat",
  searchingDoctors: "Shifokorlarni qidirish",
  searchingClinics: "Shifoxonalarni qidirish",
  searchingServices: "Xizmatlarni qidirish",
  searchingSymptoms: "Simptomlarni qidirish",
  searchingSicknesses: "Kasalliklarni qidirish",
  selectNumberOfMonths: "Oylar sonini tanlang",
  thanksForOrder: "Buyurtma uchun rahmat",
  weReceivedYourOrder: "Bizning sizning buyurtmangizni qabul qildik",
  numberOfOrder: "Buyurtma raqami",
  typeOfPayment: "To'lov usuli",
  creditCard: "Kredit karta",
  download: "Yuklab olish",
  code: "Kod",
  createdTime: "Yaratilish vaqti",
  enterUsername: "Foydalanuvchi nomini kiriting !",
  enterPassword: "Parolni kiriting !",
  typeService: "Xizmat turi",
  moreInformationAboutService: "Xizmat haqidagi batafsil ma'lumotlar",
  reallyDelete: "Rostan ham o'chirmoqchimisiz ?",
  enterCodeSendedToPhone: "Telefoningizga kelgan sms ni kiriting",
  enterCodeSendedToThisPhone: "Ushbu raqamga yuborilgan kodni kiriting",
  enterCardData: "Kredit karta ma'lumotlarini kiriting !",
  pleaseLogin: "Iltimos avval tizimga kiring !",
  addesFavouriteCart: "Sevimlilar bo'limiga qo'shildi !",
  addedFavouriteCart: "Sevimlilar bo'limiga qo'shilgan !",
  addesCompareCart: "Taqqoslash bo'limiga qo'shildi !",
  dontAllowToLocation: "Manzilga ruxsat yo'q",
  allowLocation: "Lokatsiyaga ruxsat berish",
  enterPhoneOrUsername: "Telefon raqam yoki foydanaluvchi nomi",
  user: "Foydalanuvchi",
  allConveniencesYouCanWant:
    "Bizning sayt sizga yaratish mumkin bo'lgan barcha qulayliklarni yaratib berishga harakat qiladi",
  conveniences: [
    {
      name: "Ko'p tarmoqli qidiruv tizimi",
      desc: "Siz istalgan xizmatni bizning qidiruv tizimimiz orqali topa olasiz ",
      img: "images/conveniences/search.png",
    },
    {
      name: "Tibbiy karta",
      desc: "Bizning saytda har bir sotib olgan xizmatingiz natijasini saqlab boramiz.",
      img: "images/conveniences/archive.png",
    },
    {
      name: "To'lovlar bo'yicha imtiyozlar",
      desc: "Siz bizning saytimiz orqali borganingiz uchun sizga maxsus chegirmalar bor.",
      img: "images/conveniences/discount.png",
    },
    {
      name: "Yaqin shifoxonalarni aniqlash",
      desc: "Siz bizning saytda turgan joyingizdan ixtiyoriy masofadagi shifoxonalarni topa olasiz",
      img: "images/conveniences/location.png",
    },
    {
      name: "Navbatni oldindan belgilash",
      desc: "Siz navbatni oldindan belgilash orqali qadrli vaqtingizni tejab qolasiz !",
      img: "images/conveniences/timetable.png",
    },
    {
      name: "Online to'lov tizimi",
      desc: "To'lovlarni siz istalgan karta orqali qila olasiz !",
      img: "images/conveniences/online-payment.png",
    },
    // {
    //   name: "Muddatli to'lov",
    //   desc: "",
    //   img: "images/conveniences/time-payment.png",
    // },
    // {
    //   name: "Chegirmali xizmatlar to'plami",
    //   desc: "",
    //   img: "images/conveniences/discount-packets.png",
    // },
  ],
  currentProfileSize: "Hozirdagi profil sig'imi",
  commonProfileSize: "Umumiy profil sig'imi",
  downloadedFilesSize: "Yuklab olingan fayllar hajmi",
  noUser: "Ushbu foydalanuvchi mavjud emas !",
  userExist: "Ushbu foydalanuvchi mavjud !",
  refresh: "Yangilash",
  free: "Tekin",
  errorUsernameOrPassword: "Foydalanuvchi nomi yoki parol xato !",
  noSale: "Sotilmaydi",
  enterValidUsername: "5-20 ta harf, bo'sh joy qolmasin !",
  en: "Ingliz",
  ru: "Rus",
  uz: "O'zbek",
  level: "Daraja",
  category: "Kategoriya",
  countAnalysis: "Analizlar soni",
  countStaff: "Xodimlar soni",
  treatedPatients: "Davolangan bemorlar",
  license: "Litsenziya",
  officialDate: "Rasmiy sana",
  medDiagnosticTechnologies: "Diagnostik texnologiyalar",
  medTreatmentTechnologies: "Tibbiy davolash texnologiyalari",
  legalInformation: "Yuridik ma'lumotlar",
  clientDiscount: "Mijoz chegirma",
  totalDiscount: "Umumiy chegirma",
  optinal: "Ixtiyoriy",
  internships: "Amaliyot",
  diplom_series: "Diplom seriyasi",
  scientific_works: "Ilmiy ishlar",
  professional_development: "Kasbiy rivojlanish",
  academic_degree: "Ilmiy daraja",
  training_courses: "Mashg'ulot kurslari",
  previous_workplace: "Oldingi ish joyi",
  achievements: "Yutuqlar",
  treated_patients: "Davolangan bemorlar",
  used_medical_equipment: "Ishlatilgan tibbiy asbob-uskunalar",
  treated_diseases: "Davolangan kasalliklar",
  to_whom_obey: "Kimga itoat qiladi",
  methods_treatment: "Davolash usullari",
  speciality: "Mutaxassislik",
  enterRightNumber: "Raqamni togri kiriting",
  unauthorized: "Avtorizatsiya qilinmagan",
  ordersSelected: "Tanlangan buyurtmalar",
  medpayOrder: "MedPay web sayt buyurtmasi",
  medpayOrderWarning: [
    "Ushbu hujjatda buyurtma berilgan xizmatni qabul qilish uchun hamma ma`lumotlar mavjud.",
    "Sizdan faqat kelishilgan vaqtdan kechikmasdan borishingizni so`raymiz.",
    "Vaqtiga ulgirmagan taqdirda iltimos ushbu klinika xodimlari bilan kelishib boshqa vaqtda xizmatni qabul qilishingiz mumkin. ",
    "Ushbu hujjatda sizni buyurtmangizni tasdiqlovchi kod mavjud.",
    "Ushbu kod bilan buyurtmangizni qabul qilgan klinikaga borib ism-sharifingiz va ushbu kod orqali sizni buyurtmangiz qabul qilishingiz mumkin.",
    "Agar biron savolingiz bo`lsa medpay.uz web sayti ishonchli raqamiga murojat qiling",
  ],
  upload: "Yuklash",
  doPayment: "To'lov",
  noDoctorTimeTable: "Shifokor ish jadvali shakllantirilmagan",
};

import axios from "axios";
import { RU } from "../../locales/Ru";
import { UZ } from "../../locales/Uz";
import {
  API_URL_FRONT,
  COMPARE_SERVICES,
  SITE_LANG,
  TOKEN,
} from "../../tools/constants";
import { UPDATE_APP_STATE } from "../types";
import { toast } from "react-toastify";
import { sendData, getData } from "../../server/requestFunctions";
import { getCookie } from "../../functions/useCookies";
import { lan } from "../reducers/appReducer";
import { FAVOURITE_SERVICES } from "./../../tools/constants";

export function updateState(data) {
  return {
    type: UPDATE_APP_STATE,
    payload: data,
  };
}

export const changeLang = (lan) => {
  return (dispatch) => {
    localStorage.setItem(SITE_LANG, lan);
    window.location.reload();
    if (localStorage.getItem(SITE_LANG) === "ru") {
      dispatch(updateState({ language: RU }));
    } else {
      dispatch(updateState({ language: UZ }));
    }
  };
};

// cart
export const getCartProducts = () => {
  return (dispatch) => {
    dispatch(updateState({ loading: true }));
    getCookie(TOKEN) &&
      getData("user-basket/get-list")
        .then((res) => {
          dispatch(
            updateState({
              cartProducts: res.data.filter((ser) => ser.status !== "checked"),
              approvedServices: res.data.filter(
                (ser) => ser.status === "checked"
              ),
            })
          );
        })
        .finally(() => {
          dispatch(updateState({ loading: false }));
        });
  };
};

export const getStoreServices = () => {
  return (dispatch) => {
    dispatch(updateState({ loading: true }));
    getCookie(TOKEN) &&
      getData("order/get-created-list").then((res) => {
        dispatch(
          updateState({
            storeServices: res.data.data,
          })
        );
      });
    dispatch(updateState({ loading: false }));
  };
};

export const deleteCart = (basket_id) => {
  return (dispatch) => {
    sendData(`user-basket/destroy`, { basket_id }).then(() => {
      dispatch(getCartProducts());
    });
  };
};

export const getStatusOrders = (page) => {
  return (dispatch) => {
    getCookie(TOKEN) &&
      getData(`order/user/?page=${page || 0}&size=10`).then((res) => {
        dispatch(updateState({ statusOrders: res.data.content }));
        dispatch(updateState({ statusOrdersNumber: res.data.totalElements }));
      });
  };
};

// like

export const getLikeServices = (page) => {
  return (dispatch) => {
    if (getCookie(TOKEN)) {
      getData(`favorites/get-list`).then((res) => {
        dispatch(
          updateState({
            likeServices: res.data,
            likeServicesNumber: res.data.length,
          })
        );
      });
    } else {
      let likeServicesJson = localStorage.getItem(FAVOURITE_SERVICES);
      let likeServices = JSON.parse(likeServicesJson);
      if (likeServices) {
        dispatch(
          updateState({
            likeServices,
            likeServicesNumber: likeServices.length,
          })
        );
      }
    }
  };
};

export const addLike = (service) => {
  return (dispatch) => {
    if (getCookie(TOKEN)) {
      if (service.is_favorite) {
        dispatch(deleteLike(service));
      } else {
        sendData("favorites/set", {
          service_id: service.id,
        }).then(() => {
          dispatch(getLikeServices());
        });
      }
    } else {
      let likeServicesJson = localStorage.getItem(FAVOURITE_SERVICES);
      let likeServices = JSON.parse(likeServicesJson);
      if (likeServices) {
        if (likeServices.find((serv) => serv.id === service.id)) {
          dispatch(deleteLike(service));
        } else {
          likeServices.push(service);
          toast.success(lan.addesFavouriteCart);
          localStorage.setItem(
            FAVOURITE_SERVICES,
            JSON.stringify(likeServices)
          );
        }
      } else {
        likeServices = [service];
        toast.success(lan.addesFavouriteCart);
        localStorage.setItem(FAVOURITE_SERVICES, JSON.stringify(likeServices));
      }
      dispatch(getLikeServices());
    }
  };
};

export const deleteLike = (service) => {
  return (dispatch) => {
    if (getCookie(TOKEN)) {
      sendData(`favorites/destroy`, { service_id: service.id }).then(() => {
        dispatch(getLikeServices());
      });
    } else {
      let likeServicesJson = localStorage.getItem(FAVOURITE_SERVICES);
      let likeServices = JSON.parse(likeServicesJson);
      localStorage.setItem(
        FAVOURITE_SERVICES,
        JSON.stringify(likeServices.filter((serv) => serv.id !== service.id))
      );
      dispatch(getLikeServices());
    }
  };
};

export const moveToServerFromLocalLikeServices = () => {
  return async () => {
    let likeServicesJson = localStorage.getItem(FAVOURITE_SERVICES);
    let likeServices = JSON.parse(likeServicesJson);
    await likeServices.forEach((ser) => {
      sendData("favorites/set", { service_id: ser.id });
    });
    localStorage.setItem(FAVOURITE_SERVICES, "[]");
  };
};

// compare

export const getCompareServices = () => {
  return (dispatch) => {
    if (getCookie(TOKEN)) {
      getData(`compares/get-list`).then((res) => {
        dispatch(
          updateState({
            compareServices: res.data,
            compareServicesNumber: res.data.length,
            roadPrice: res.data.distanceFee || 1000,
          })
        );
      });
    } else {
      let compareServicesJson = localStorage.getItem(COMPARE_SERVICES);
      let compareServices = JSON.parse(compareServicesJson);
      if (compareServices) {
        dispatch(
          updateState({
            compareServices,
            compareServicesNumber: compareServices.length,
            roadPrice: 1000,
          })
        );
      }
    }
  };
};

export const addCompare = (service) => {
  return (dispatch) => {
    if (getCookie(TOKEN)) {
      if (service.is_compare) {
        dispatch(deleteCompare(service));
      } else {
        sendData(`compares/set`, {
          service_id: service.id,
        }).then(() => {
          dispatch(getCompareServices());
        });
      }
    } else {
      let compareServicesJson = localStorage.getItem(COMPARE_SERVICES);
      let compareServices = JSON.parse(compareServicesJson);
      if (compareServices) {
        if (compareServices.find((serv) => serv.id === service.id)) {
          dispatch(deleteCompare(service));
        } else {
          compareServices.push(service);
          toast.success(lan.addesCompareCart);
          localStorage.setItem(
            COMPARE_SERVICES,
            JSON.stringify(compareServices)
          );
        }
      } else {
        compareServices = [service];
        toast.success(lan.addesCompareCart);
        localStorage.setItem(COMPARE_SERVICES, JSON.stringify(compareServices));
      }
      dispatch(getCompareServices());
    }
  };
};

export const deleteCompare = (service) => {
  return (dispatch) => {
    if (getCookie(TOKEN)) {
      sendData(`compares/destroy`, { service_id: service.id }).then(() => {
        dispatch(getCompareServices());
      });
    } else {
      let compareServicesJson = localStorage.getItem(COMPARE_SERVICES);
      let compareServices = JSON.parse(compareServicesJson);
      localStorage.setItem(
        COMPARE_SERVICES,
        JSON.stringify(compareServices.filter((serv) => serv.id !== service.id))
      );
      dispatch(getCompareServices());
    }
  };
};

export const moveToServerFromLocalCompareServices = () => {
  return async () => {
    let compareServicesJson = localStorage.getItem(COMPARE_SERVICES);
    let compareServices = JSON.parse(compareServicesJson);
    await compareServices.forEach((ser) => {
      sendData("compares/set", { service_id: ser.id });
    });
    localStorage.setItem(COMPARE_SERVICES, "[]");
  };
};

export const getUserData = () => {
  return (dispatch) => {
    axios
      .get(
        `${API_URL_FRONT}user/profile?lang=${localStorage.getItem(SITE_LANG)}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie(TOKEN)}`,
          },
        }
      )
      .then((res) => {
        dispatch(updateState({ userData: res.data.user }));
      });
  };
};

export const getLanguages = () => {
  return (dispatch) => {
    getData("basic-list/languages").then((res) => {
      dispatch(updateState({ languages: res.data }));
    });
  };
};

export const getSocialContactList = () => {
  return (dispatch) => {
    getData("get-social-contact-list").then((res) => {
      dispatch(updateState({ socialContactList: res.data }));
    });
  };
};

export const getGetFooterInfo = () => {
  return (dispatch) => {
    getData("get-footer-info").then((res) => {
      dispatch(updateState({ footerInfo: res.data }));
    });
  };
};

export const getRegions = () => {
  return (dispatch) => {
    getData("basic-list/regions").then((res) => {
      dispatch(updateState({ regions: res.data }));
    });
  };
};

export const getClinicData = (id) => {
  return (dispatch) => {
    getData(`clinic/show/${id}`).then(async (res) => {
      dispatch(updateState({ clinic: res.data }));
    });
  };
};

export const changeAccountKey = (data) => {
  return updateState({ accountKey: data });
};

export const changeActiveDataStep = (data) => {
  return updateState({ activeDataStep: data });
};

export const changeBasketCallback = (data) => {
  return updateState({ basketCallback: data });
};

export const changeActiveCartTab = (data) => {
  return updateState({ activeCartTab: data });
};

export const getStandardServices = () => {
  return (dispatch) => {
    dispatch(updateState({ loading: true }));
    getData("services/category-list", { type: "standard" })
      .then((res) => {
        dispatch(updateState({ standardServices: res.data }));
      })
      .then(() => {
        dispatch(updateState({ loading: false }));
      });
  };
};

import React, { useState } from "react";
import { Row, Col, Card, Progress, Modal, Input } from "antd";
import AccountLayout from "../../components/Layouts/AccountLayout";
import useFetchAuth from "../../hooks/useFetchAuth";
import { getDataAuth, sendDataAuth } from "./../../server/config/common";
import StorageProfileCard from "./../../components/Cards/StorageProfileCard";
import { useSelector } from "react-redux";
import CreditCard from "./../../components/Shared/CreditCard";
import Loading from "./../../components/Shared/Loading";

// const { Option } = Select;

const StorageProfileP = () => {
  const lan = useSelector((state) => state.app.language);
  const [callback, setCallback] = useState(false);
  const [storageData] = useFetchAuth("storage-profile/user/status", [callback]);
  const [options, loading] = useFetchAuth("storage-profile/");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [storageProfileId, setStorageProfileId] = useState("");
  // const [paymentData, setPaymentData] = useState({});
  // const [month, setMonth] = useState(1);
  const [cardData, setCardData] = useState({});
  const [phoneCode, setPhoneCode] = useState("");
  const [transId, setTransId] = useState(0);

  const changeOption = (id) => {
    showModal();
    setStorageProfileId(id);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    sendDataAuth("storage-profile/purchase", {
      ...cardData,
      profileId: storageProfileId,
      monthCountPaid: 12,
    })
      .then((res) => {
        setTransId(res.data.transactionId);
      })
      .catch((err) => {
        setIsModalVisible(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // function onChange(value) {
  //   setMonth(value);
  //   getDataAuth(
  //     `storage-profile/calculate?profileId=${storageProfileId}&monthCount=${value}`
  //   ).then((res) => {
  //     setPaymentData(res.data);
  //   });
  // }

  const trans = () => {
    getDataAuth(
      `storage-profile/purchase/confirm?transactionId=${transId}&code=${phoneCode}`
    ).then((res) => {
      setIsModalVisible(false);
      setCallback(!callback);
      setPhoneCode("");
    });
  };

  const getCreditCard = (data) => {
    setCardData(data);
  };

  const handlePhoneCode = (e) => {
    setPhoneCode(e.target.value);
  };

  return (
    <AccountLayout>
      <div className="card p-3">
        {loading ? (
          <Loading />
        ) : (
          <Row>
            <Col md={{ span: 18, offset: 3 }} span={24}>
              <Card
                title="Current Storage Profile"
                className="current-storage-profile mb-3"
              >
                <h4>{lan.currentProfileSize}</h4>
                <p>
                  {storageData.writtenBytes} / {storageData.profileWrittenBytes}
                </p>
                <Progress
                  strokeLinecap="square"
                  type="circle"
                  percent={(
                    (storageData.writtenBytes * 100) /
                    storageData.profileWrittenBytes
                  ).toFixed(2)}
                  width={160}
                />
                <div className="d-flex justify-content-between">
                  <div>
                    <Progress
                      strokeLinecap="square"
                      type="circle"
                      percent={(
                        (storageData.totalWrittenBytes * 100) /
                        storageData.profileTotalWrittenBytes
                      ).toFixed(2)}
                    />
                    <h4>{lan.commonProfileSize}</h4>
                    <p>{`${storageData.totalWrittenBytes} / ${storageData.profileTotalWrittenBytes}`}</p>
                  </div>
                  <div>
                    <Progress
                      strokeLinecap="square"
                      type="circle"
                      percent={(
                        (storageData.downloadedBytes * 100) /
                        storageData.profileDownloadedBytes
                      ).toFixed(2)}
                    />
                    <h4>{lan.downloadedFilesSize}</h4>
                    <p>{`${storageData.downloadedBytes} / ${storageData.profileDownloadedBytes}`}</p>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>
        )}
        <Row gutter={[8, 8]}>
          {options.map((item) => (
            <Col md={8} span={24} key={item.storageProfileId}>
              <StorageProfileCard
                changeOption={changeOption}
                {...item}
                activeStatus={storageData.profileName}
              />
            </Col>
          ))}
        </Row>
      </div>
      <Modal
        maskClosable={false}
        title={transId ? lan.enterCodeSendedToPhone : lan.enterCardData}
        visible={isModalVisible}
        onOk={transId ? trans : handleOk}
        okText={transId ? lan.sending : lan.buy}
        cancelText={lan.cancel}
        onCancel={handleCancel}
      >
        {transId ? (
          <Input
            onChange={handlePhoneCode}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            value={phoneCode}
            placeholder="123456"
            maxLength="6"
          />
        ) : (
          <>
            {/* <Select
              showSearch
              className="w-100"
              placeholder={lan.selectNumberOfMonths}
              optionFilterProp="children"
              onChange={onChange}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => (
                <Option key={num} value={num}>
                  {num}
                </Option>
              ))}
            </Select>
            {JSON.stringify(paymentData)} */}
            <CreditCard getCreditCard={getCreditCard} />
          </>
        )}
      </Modal>
    </AccountLayout>
  );
};

export default StorageProfileP;

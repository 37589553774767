import React, { useEffect } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { Tabs } from "antd";
import { FcNext, FcPrevious } from "react-icons/fc";
import Slider from "react-slick";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import hospital from "../../../static/images/hospital.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import DirectionTop from "../../Shared/DirectionTop";
import CustomComment from "./../../Shared/CustomComment";
import { getClinicData } from "../../../redux/actions/appActions";
import "../../../style/SpecificClinicComp.scss";

const mapState = {
  center: [45.4507, 68.8319],
  zoom: 11,
  behaviors: ["default", "scrollZoom"],
};

const { TabPane } = Tabs;

const SpecificClinicLayout = (props) => {
  const id = props.match.params.id;
  const lan = useSelector((state) => state.app.language);
  const routes = [
    { path: "", name: lan.doctors },
    { path: "/services", name: lan.services },
    { path: "/packages", name: lan.packages },
    { path: "/technologies", name: lan.technologies },
  ];
  const dispatch = useDispatch();
  const clinic = useSelector((state) => state.app.clinic);
  useEffect(() => {
    dispatch(getClinicData(props.match.params.id));
  }, []);
  return (
    <div className="specificClinicComp top-bottom">
      <DirectionTop
        breadcrumbs={[lan.clinics, clinic.name, ...props.breadcrumbs]}
      />
      <div className="container-lg px-lg-0">
        <h3 className="block-header__title">{clinic.name}</h3>
        <div
          className="section-header__divider"
          style={{ marginBottom: "20px" }}
        ></div>
        <div className="row mx-0 mb-3">
          <div className="pr-lg-0 col-lg-6 col-12 order-1 order-lg-2 slider-div">
            <div className="clinicImages">
              <Slider {...settings}>
                {clinic.images &&
                  clinic.images.map((item, index) => (
                    <div key={index}>
                      <LazyLoadImage
                        // placeholderSrc="/images/no-hospital.png"
                        className="w-100"
                        style={{ height: "400px", objectFit: "cover" }}
                        src={item.image}
                        alt="clinic-image"
                      />
                    </div>
                  ))}
              </Slider>
            </div>
          </div>
          <div className="px-lg-0 col-lg-6 col-12 px-0 order-2 order-lg-1">
            <div className="card clinic-about">
              <Tabs defaultActiveKey="1" centered>
                <TabPane tab={lan.description} key="1">
                  <div className="clinic-description">
                    <div className="typography">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: clinic.description,
                        }}
                      />
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={lan.characteristics} key="2">
                  <div className="spec">
                    <div className="spec__section">
                      <div className="spec__row">
                        <div className="spec__name">{lan.phoneNumber}</div>
                        <div className="spec__value">{clinic.phone}</div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.totalTerritory}</div>
                        <div className="spec__value">
                          {clinic.total_territory} m<sup>2</sup>
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.numberOfBeds}</div>
                        <div className="spec__value">{clinic.count_beds}</div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.countAnalysis}</div>
                        <div className="spec__value">
                          {clinic.count_analysis}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.countStaff}</div>
                        <div className="spec__value">{clinic.count_staff}</div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.numberOfDoctors}</div>
                        <div className="spec__value">
                          {clinic.count_doctors}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.treatedPatients}</div>
                        <div className="spec__value">
                          {clinic.treated_patients}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.license}</div>
                        <div className="spec__value">{clinic.license}</div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.officialDate}</div>
                        <div className="spec__value">
                          {clinic.official_date}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">
                          {lan.med_diagnostic_technologies}
                        </div>
                        <div className="spec__value">
                          {clinic.medDiagnosticTechnologies}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">
                          {lan.medTreatmentTechnologies}
                        </div>
                        <div className="spec__value">
                          {clinic.med_treatment_technologies}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.legalInformation}</div>
                        <div className="spec__value">
                          {clinic.legal_information}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.clientDiscount}</div>
                        <div className="spec__value">
                          {clinic.client_discount}
                        </div>
                      </div>
                      <div className="spec__row">
                        <div className="spec__name">{lan.totalDiscount}</div>
                        <div className="spec__value">
                          {clinic.total_discount}
                        </div>
                      </div>
                    </div>
                  </div>
                </TabPane>
                <TabPane tab={lan.comments} key="3">
                  <div className="clinic-comment">
                    <CustomComment
                      sendurl={"clinic/comment-store/" + id}
                      url={"clinic/comments/" + id}
                    />
                  </div>
                </TabPane>
                <TabPane tab={lan.map} key="4">
                  <YMaps className="react-yandex-map">
                    <Map
                      state={{
                        ...mapState,
                        center: [clinic.location_y, clinic.location_x],
                      }}
                    >
                      <Placemark
                        geometry={[clinic.location_y, clinic.location_x]}
                        options={{
                          preset: "islands#violetIcon",
                          iconLayout: "default#image",
                          iconImageHref: hospital,
                          iconImageSize: [35, 35],
                        }}
                      />
                    </Map>
                  </YMaps>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <div className="row mx-0">
          <div className="col-lg-12 px-0">
            {/* <div className="clinic-menu-tabs">
              <Tabs type="card">
                <TabPane tab={lan.doctors} key="1">
                  <InfiniteScroll
                    dataLength={totalDoctors}
                    next={totalDoctors && fetchMoreDoctors}
                    hasMore={hasMoreDoctors}
                    loader={[<Loading key={0} />]}
                    endMessage=""
                  >
                    {doctors.length !== 0 ? (
                      <Row gutter={[16, 16]}>
                        {doctors.map((doctor) => (
                          <Col
                            span={24}
                            sm={12}
                            md={8}
                            lg={6}
                            xl={4}
                            key={doctor.doctorId}
                          >
                            <ClinicDoctorCard {...doctor} />
                          </Col>
                        ))}
                      </Row>
                    ) : (
                      <NoData />
                    )}
                  </InfiniteScroll>
                </TabPane>
                <TabPane tab={lan.services} key="2">
                  {props.children}
                </TabPane>
                <TabPane tab={lan.packages} key="3"></TabPane>
                <TabPane tab={lan.technologies} key="4"></TabPane>
              </Tabs>
            </div> */}
            <div className="clinic-menus">
              <div className="view-options__legend d-flex justify-content-around w-100">
                {routes.map((item, index) => (
                  <NavLink
                    exact
                    activeStyle={{
                      fontWeight: "bold",
                      color: "#2EACE5",
                    }}
                    to={`/clinics/${props.match.params.id}${item.path}`}
                    className="clinic-menu"
                    key={index}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SpecificClinicLayout);

function SampleNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="next-arrow"
      style={{
        ...style,
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        right: 0,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <FcNext />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className="prev-arrow"
      style={{
        ...style,
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        left: 0,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <FcPrevious />
    </div>
  );
}

const settings = {
  customPaging: () => (
    <div
      style={{
        margin: "0 4px",
        background: "0 0",
        cursor: "pointer",
        width: "12px",
        height: "12px",
        color: "blue",
        borderRadius: "50%",
        opacity: 0.3,
      }}
    ></div>
  ),
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

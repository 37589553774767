import { Empty } from "antd";
import React from "react";
import { useSelector } from "react-redux";

const NoData = ({ title }) => {
  const lan = useSelector((state) => state.app.language);
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={title || lan.noData}
    />
  );
};

export default NoData;

import axios from "axios";
import { deleteCookie, getCookie } from "../functions/useCookies";
import { TOKEN, API_URL_FRONT } from "../tools/constants";
import { toast } from "react-toastify";
import { removeRegisterLocalStorageData } from "../functions/removeRegisterLocalStorageData";
// import { lanProp } from "../redux/reducers/appReducer";

export const requestFront = (config = null, other_headers) => {
  let token = getCookie(TOKEN);
  // const lan = lanProp();
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...other_headers,
  };
  token && (headers.Authorization = `Bearer ${token}`);

  let axiosInstance = axios.create({
    baseURL: `${API_URL_FRONT}`,
    headers,
    timeout: 100000,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.data) {
        console.log(error.response.data);
        Array.isArray(error.response.data.errors)
          ? toast.error(
              Object.values(error.response.data.errors)
                .map((item) => item[0])
                .join(", ")
            )
          : Object.keys(error.response.data).length === 1 &&
            toast.error(error.response.data.message);
        if (error.response.data.errors && error.response.data.errors.message) {
          removeRegisterLocalStorageData();
          deleteCookie(TOKEN);
        }
      }
      // if (error.response && error.response.status === 401) {
      //   toast.error(lan.unauthorized);
      // }
      throw error;
    }
  );

  return axiosInstance(config);
};

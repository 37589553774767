import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import { compose, createStore, applyMiddleware } from "redux";
import { AllReducers } from "./redux/reducers/rootReducer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tabs/style/react-tabs.css";
import "react-input-range/lib/css/index.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "react-accessible-accordion/dist/fancy-example.css";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "aos/dist/aos.css";
import "antd/dist/antd.css";
import "react-lazy-load-image-component/src/effects/blur.css";

import thunk from "redux-thunk";

const store = createStore(
  AllReducers,
  compose(
    applyMiddleware(thunk)
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

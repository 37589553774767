import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  InputNumber,
  Select,
  Col,
  Row,
  Upload,
  Space,
  Modal,
} from "antd";
import { toast } from "react-toastify";
import { getData, sendData } from "../../server/requestFunctions";
import { useDispatch, useSelector } from "react-redux";
import { changeActiveDataStep } from "../../redux/actions/appActions";
import { getCookie } from "../../functions/useCookies";
import { TOKEN } from "../../tools/constants";
import { removeRegisterLocalStorageData } from "./../../functions/removeRegisterLocalStorageData";

const UserProfileForm = (props) => {
  const lan = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [picture, setPicture] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [callback, setCallback] = useState(false);

  useEffect(() => {
    getCookie(TOKEN)
      ? getData("user/profile").then(async (res) => {
          let user = res.data.user;
          user.avatar &&
            setPicture([
              {
                url: user.avatar,
                status: "done",
              },
            ]);

          form.setFieldsValue(user);
        })
      : setCallback(!callback);
  }, [callback]);

  const onFinish = (values) => {
    if (values.gender) {
      let formData = new FormData();
      Object.keys(values).forEach((item) =>
        formData.append(item, values[item] || "")
      );
      if (picture[0] && picture[0].originFileObj) {
        formData.append("avatar", picture[0].originFileObj);
      } else if (picture[0]) {
      } else {
        formData.append("avatar", "");
      }

      setButtonLoading(true);

      sendData("user/profile-update", formData, null, {
        "Content-type": "multipart/form-data",
      })
        .then(() => {
          toast.success(lan.saveSuccessfully);
          dispatch(changeActiveDataStep(2));
          removeRegisterLocalStorageData();
        })
        .catch((err) => {
          console.log(err.response.data);
        })
        .finally((res) => {
          setButtonLoading(false);
        });
    } else {
      toast.error(lan.selectGender);
    }
  };
  const handlePreview = () => {
    setPreviewVisible(true);
    setPreviewImage(picture.length !== 0 && picture[0].url);
  };
  const handleUpload = (info) => {
    info.fileList[0] ? setPicture(info.fileList) : setPicture([]);
  };
  const deleteImg = () => {
    setPicture([]);
  };

  return (
    <Col span={24}>
      {/* <div className="card flex-grow-1">
        <div className="card-body"> */}
      <Row gutter={[8, 8]}>
        <Col lg={15} span={24}>
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            onFinish={onFinish}
            name="accountInfo"
            initialValues={{
              name: "",
              surname: "",
              username: "",
              email: "",
              age: null,
              nationality: "",
              profession: "",
              address: "",
            }}
          >
            <Form.Item label={lan.name} name="name">
              <Input placeholder={lan.name} />
            </Form.Item>
            <Form.Item label={lan.lname} name="surname">
              <Input placeholder={lan.lname} />
            </Form.Item>
            <Form.Item label={lan.username} name="username">
              <Input disabled placeholder={lan.username} />
            </Form.Item>
            <Form.Item
              label={lan.email}
              name="email"
              rules={[
                {
                  type: "email",
                  message: lan.emailValidation,
                },
              ]}
            >
              <Input placeholder={lan.email} />
            </Form.Item>
            <Form.Item label={lan.age} name="age">
              <InputNumber placeholder={lan.age} style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              label={lan.gender}
              name="gender"
              rules={[
                {
                  required: true,
                  message: lan.selectGender,
                },
              ]}
            >
              <Select placeholder={lan.gender}>
                <Select.Option value="male">{lan.male}</Select.Option>
                <Select.Option value="female">{lan.female}</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label={lan.nationality} name="nationality">
              <Input placeholder={lan.nationality} />
            </Form.Item>
            <Form.Item label={lan.profession} name="profession">
              <Input placeholder={lan.profession} />
            </Form.Item>
            <Form.Item label={lan.permanentAddress} name="address">
              <Input placeholder={lan.permanentAddress} />
            </Form.Item>
            <div className="d-flex justify-content-between">
              <Form.Item>
                <Button
                  htmlType="submit"
                  type="primary"
                  loading={buttonLoading}
                  disabled={buttonLoading}
                >
                  {lan.save}
                </Button>
              </Form.Item>
              {props.isVisible || (
                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    onClick={() => {
                      if (form.getFieldValue("gender")) {
                        dispatch(changeActiveDataStep(2));
                      } else {
                        toast.error(lan.selectGender);
                      }
                    }}
                  >
                    {lan.skip}
                  </Button>
                </Form.Item>
              )}
            </div>
          </Form>
        </Col>
        <Col lg={9} span={24}>
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <Upload
              fileList={picture}
              listType="picture-card"
              onPreview={handlePreview}
              onChange={handleUpload}
              onRemove={deleteImg}
              beforeUpload={() => false}
              maxCount={1}
              accept=".jpg, .jpeg, .png"
            >
              {picture.length !== 0 ? (
                ""
              ) : (
                <div>
                  <div className="ant-upload-text">{lan.userpicture}</div>
                </div>
              )}
            </Upload>
          </Space>
        </Col>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
          cancelText="Yopish"
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </Row>
      {/* </div>
      </div> */}
    </Col>
  );
};

export default UserProfileForm;

import React from "react";
import { deleteCookie } from "../../functions/useCookies";
import { IS_VERIFIED, TOKEN, USER_ID } from "../../tools/constants";
import { useSelector } from "react-redux";
import { sendData } from "../../server/requestFunctions";

const LogoutButton = () => {
  const lan = useSelector((state) => state.app.language);
  return (
    <button
      type="button"
      style={{ color: "red" }}
      onClick={() => {
        sendData("logout");
        deleteCookie(TOKEN);
        localStorage.removeItem(IS_VERIFIED);
        deleteCookie(USER_ID);
        window.location.href = "/";
      }}
    >
      {lan.logout}
    </button>
  );
};

export default LogoutButton;

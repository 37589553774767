import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteLike } from "../../redux/actions/appActions";
import useBuyService from "./../../hooks/useBuyService";

const LikeCard = (props) => {
  const lan = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const item = props.item;
  const [ButtonOpeningTimeTable] = useBuyService(item);

  return (
    <tr className="cart-table__row">
      <td className="cart-table__column cart-table__column--image">
        <div className="image image--type--product">
          <Link className="image__body" to={`/clinics/${item.clinic_id}`}>
            <img
              alt=""
              className="image__tag"
              src={
                item.clinic && item.clinic.images[0]
                  ? item.clinic.images[0].image
                  : "/images/no-hospital.png"
              }
            />
          </Link>
        </div>
      </td>
      <td className="cart-table__column cart-table__column--product">
        {item.name}
      </td>
      <td className="cart-table__column cart-table__column--product">
        <Link
          className="cart-table__product-name"
          to={`/clinics/${item.clinic_id}`}
        >
          {item.clinic && item.clinic.name}
        </Link>
      </td>
      {/* <td className="cart-table__column cart-table__column--product">
        <Link
          className="cart-table__product-name"
          to={`/doctors/${item.doctorId}`}
        >
          {item.doctorName}
        </Link>
      </td> */}
      <td
        className="cart-table__column cart-table__column--price"
        data-title="Price"
      >
        {`${item.price} ${lan.sum}`}
      </td>
      <td className="cart-table__column wishlist__column wishlist__column--body wishlist__column--button">
        {ButtonOpeningTimeTable}
      </td>
      <td className="cart-table__column cart-table__column--remove">
        <button
          onClick={() => dispatch(deleteLike(item))}
          type="button"
          className="cart-table__remove btn btn-sm btn-icon btn-muted"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
            <path d="M10.8 10.8c-.4.4-1 .4-1.4 0L6 7.4l-3.4 3.4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L4.6 6 1.2 2.6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L6 4.6l3.4-3.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L7.4 6l3.4 3.4c.4.4.4 1 0 1.4z"></path>
          </svg>
        </button>
      </td>
    </tr>
  );
};

export default LikeCard;

import React, { useState } from "react";
import "./ClinicLocation.scss";
import { Modal, ModalBody } from "reactstrap";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import hospital from "../../static/images/hospital.png";
import { useSelector } from "react-redux";
import useLocation from "./../../hooks/useLocation";
import { countDistance } from "./../../functions/countDistance";

const mapState = {
  center: [41.346269760432435, 69.21482057595402],
  zoom: 11,
  behaviors: ["default", "scrollZoom"],
};

const ClinicLocation = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [location] = useLocation();
  const toggle = () => {
    setModalOpen(!modalOpen);
  };
  const lan = useSelector((state) => state.app.language);
  return (
    <React.Fragment>
      <div
        onClick={toggle}
        className="clinic-location"
        style={{ bottom: 5, right: 0, backgroundColor: "white" }}
      >
        <GrLocation />
        <span>{props.name || lan.location}</span>
        {(props.location[0] && props.location[0] === "undefined") ||
          `(${countDistance(location, props.location)} ${lan.km})`}
      </div>
      <Modal className="mapModal" toggle={toggle} isOpen={modalOpen}>
        <ModalBody>
          <button className="map-modal-close">
            <AiOutlineCloseCircle onClick={toggle} />
          </button>
          <YMaps className="react-yandex-map">
            <Map state={mapState}>
              <Placemark
                geometry={props.location}
                options={{
                  preset: "islands#violetIcon",
                  iconLayout: "default#image",
                  iconImageHref: hospital,
                  iconImageSize: [35, 35],
                }}
              />
            </Map>
          </YMaps>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ClinicLocation;

import React from "react";
import { useSelector } from "react-redux";
import FilterItem from "../Filters/FilterItem";
import FilterLayout from "../Filters/FilterLayout";
import CommonRadioFilter from "./CommonRadioFilter";
import FilterByDistance from "../Shared/FilterByDistance";

const ClinicsFilter = (props) => {
  const lan = useSelector((state) => state.app.language);

  return (
    <div>
      <FilterLayout
        title={lan.filter}
        quantity={props.quantity}
        mobile={props.mobile}
      >
        <FilterItem title={props.placeName}>
          <CommonRadioFilter
            value={props.city}
            handleValue={props.handlePlace}
            data={props.places}
          />
        </FilterItem>
        <FilterByDistance handleDistance={props.handleDistance} />
      </FilterLayout>
    </div>
  );
};

export default ClinicsFilter;

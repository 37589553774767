import { useEffect, useState } from "react";

const useLocation = () => {
  const [location, setLocation] = useState([]);
  const [callback, setCallback] = useState(false);

  const success = (pos) => {
    var crd = pos.coords;
    setLocation([crd.latitude, crd.longitude]);
  };
  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };
  const errors = (err) => {};
  const allowLocation = () => {
    setCallback(!callback);
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          navigator.geolocation.getCurrentPosition((pos) => {
            var crd = pos.coords;
            setLocation([crd.latitude, crd.longitude]);
          });
        } else if (result.state === "prompt") {
          navigator.geolocation.getCurrentPosition(success, errors, options);
        } else if (result.state === "denied") {
        }
        result.onchange = function () {};
      });
    } else {
      alert("Sorry Not available!");
    }
  }, [callback]);

  return [location, allowLocation];
};

export default useLocation;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import FreeBlock from "../components/Shared/FreeBlock";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Modal } from "antd";
import { useState } from "react";
import { Input } from "antd";
import CreditCard from "../components/Shared/CreditCard";
import { useHistory } from "react-router-dom";
import { getStoreServices, updateState } from "./../redux/actions/appActions";
import { Checkbox } from "antd";
import "../components/Cards/CheckOutCard.scss";
import Loading from "./../components/Shared/Loading";
import { getCookie } from "./../functions/useCookies";
import { getData, sendData } from "./../server/requestFunctions";
import { TOKEN } from "../tools/constants";
import { isEmpty } from "../functions";
import { toast } from "react-toastify";

const CheckOutPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const storeServices = useSelector((state) => state.app.storeServices);
  const loading = useSelector((state) => state.app.loading);
  const storeServicesId = storeServices.map((ser) => ser.id);
  const lan = useSelector((state) => state.app.language);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [phoneCode, setPhoneCode] = useState(null);
  const [transId, setTransId] = useState(0);
  const [cardData, setCardData] = useState({});
  const [sum, setSum] = useState(null);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);

  const getCheckValue = (e) => {
    let val = e.target.value;
    let res = [];
    checkedList.includes(val)
      ? (res = checkedList.filter((el) => el !== val))
      : (res = [...checkedList, val]);
    setIndeterminate(!!res.length && res.length < storeServicesId.length);
    setCheckAll(res.length === storeServicesId.length);
    setCheckedList(res);
    countSum(res);
  };

  const onCheckAllChange = (e) => {
    let res = e.target.checked ? storeServicesId : [];
    setCheckedList(res);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
    countSum(res);
  };

  const countSum = (res) => {
    setSum(
      res.reduce(
        (s, cur) => s + storeServices.find((el) => el.id === cur).price,
        0
      )
    );
  };

  useEffect(() => {
    dispatch(getStoreServices());
    getCookie(TOKEN) &&
      getData("order/get-created-list").then((res) => {
        setCheckedList(res.data.data.map((ser) => ser.id));
        setSum(res.data.data.reduce((s, cur) => s + cur.price, 0));
      });
  }, []);

  const sendApprovedTimes = () => {
    sendData("billing/prepare", {
      subvendors: checkedList,
      ...cardData,
    })
      .then((res) => {
        showModal();
        setTransId(res.data.bank_transaction_id);
      })
      .catch((err) => {
        err.response.data &&
          err.response.data &&
          toast.error(err.response.data.errors.message);
      })
      .finally(() => {});
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    sendData(`billing/confirm`, {
      bank_transaction_id: transId,
      confirmation_code: phoneCode * 1,
    }).then((res) => {
      history.push(`/checkout/${res.data.transaction_id}`);
      dispatch(
        updateState({
          purchasedServices: checkedList.map((id) =>
            storeServices.find((ser) => ser.id === id)
          ),
        })
      );
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handlePhoneCode = (e) => {
    setPhoneCode(e.target.value);
  };

  const getCreditCard = (data) => {
    setCardData(data);
  };

  const clinic_slug = (item) => item.clinic && item.clinic.clinic_id;

  return (
    <div className="checkOutComp top-bottom">
      {loading ? (
        <Loading />
      ) : storeServices.length === 0 ? (
        <div className="container-lg px-lg-0">
          <FreeBlock name="Ваша корзина пуста!" about="" />
        </div>
      ) : (
        <div className="container container--max--xl">
          <div className="row">
            <div className="col-12 col-lg-6 col-xl-8 pl-lg-0">
              <div className="card">
                <table className="cart-table__table">
                  <thead className="cart-table__head">
                    <tr className="cart-table__row">
                      <th className="cart-table__column cart-table__column--image">
                        {lan.img}
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        {lan.servic}
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        {lan.doctor}
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        {lan.clinic}
                      </th>
                      <th className="cart-table__column cart-table__column--time">
                        {lan.time}
                      </th>
                      <th className="cart-table__column cart-table__column--price">
                        {lan.price}
                      </th>
                      <th className="cart-table__column cart-table__column--price">
                        <Checkbox
                          indeterminate={indeterminate}
                          onChange={onCheckAllChange}
                          checked={checkAll}
                        ></Checkbox>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cart-table__body">
                    {storeServices.map((item) => (
                      <tr key={item.id} className="cart-table__row">
                        <td className="cart-table__column cart-table__column--image">
                          <div className="image image--type--product">
                            <Link
                              className="image__body"
                              to={`/clinics/${clinic_slug(item)}`}
                            >
                              <img
                                alt=""
                                className="image__tag"
                                src={item.clinic.images[0].image}
                              />
                            </Link>
                          </div>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <Link
                            className="cart-table__product-name"
                            to={`/services/${item.service.service_category_id}`}
                          >
                            {item.service.name}
                          </Link>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <Link
                            className="cart-table__product-name"
                            to={`/doctors/${item.doctor.user_id}`}
                          >
                            {item.doctor.user.fio}
                          </Link>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <Link
                            className="cart-table__product-name"
                            to={`/clinics/${clinic_slug(item)}`}
                          >
                            {item.clinic.name}
                          </Link>
                        </td>
                        <td
                          className="cart-table__column cart-table__column--time"
                          data-title={lan.time}
                        >
                          <div>
                            <div
                              className="timetablecard"
                              style={{
                                font: "bold 12px/22px Arial",
                                width: "62px",
                              }}
                            >
                              {item.service_time.split(" ").map((el) => (
                                <p key={el} className="p-0">
                                  {el}
                                </p>
                                // sana uchun
                                // <div
                                //   key={index}
                                //   className="timetablecard"
                                //   style={{
                                //     font: "bold 12px/22px Arial",
                                //     width: "62px",
                                //   }}
                                // >
                                //   <p className="p-0">{day.objectDate}</p>
                                // </div>
                              ))}
                            </div>
                          </div>
                        </td>
                        <td
                          className="cart-table__column cart-table__column--price"
                          data-title="Price"
                        >
                          {item.price}
                        </td>
                        <td className="cart-table__column cart-table__column--price">
                          <Checkbox
                            checked={checkedList.includes(item.id)}
                            value={item.id}
                            onChange={getCheckValue}
                          ></Checkbox>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl-4 mt-4 mt-lg-0 px-lg-0">
              <div className="checkOutCard">
                <div className="card">
                  <div className="card-body card-body--padding--2">
                    <h3 className="mb-2">{lan.makePayment}</h3>
                    <Divider />
                    <div className="d-flex justify-content-between">
                      <h4>{lan.total}</h4>
                      <h4>
                        {sum} {lan.sum}
                      </h4>
                    </div>
                    <CreditCard getCreditCard={getCreditCard} />
                    <button
                      type="submit"
                      disabled={isEmpty(checkedList)}
                      onClick={sendApprovedTimes}
                      className="btn btn-primary btn-xl btn-block"
                    >
                      {lan.payment}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal
        title={lan.enterCodeSendedToPhone}
        visible={isModalVisible}
        okText={lan.sending}
        cancelText={lan.cancel}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <Input
          onChange={handlePhoneCode}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          value={phoneCode}
          placeholder="123456"
          maxLength="6"
        />
      </Modal>
    </div>
  );
};

export default CheckOutPage;

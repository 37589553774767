import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Select } from "antd";
import FilterLayout from "../components/Filters/FilterLayout";
import FilterItem from "../components/Filters/FilterItem";
import CommonRadioFilter from "../components/Filters/CommonRadioFilter";
import DoctorCard from "../components/Cards/DoctorCard";
import SmallLoading from "../components/Shared/SmallLoading";
import useFetch from "../hooks/useFetch";
import { updateState } from "./../redux/actions/appActions";
import CommonLayout from "./../components/Layouts/CommonLayout";
import InfiniteScrollCustom from "./../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from "../hooks/useFetchWithScrollPgtn";

const { Option } = Select;

const DoctorsPage = (props) => {
  const lan = useSelector((state) => state.app.language);
  const search = useSelector((state) => state.app.search);
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(null);
  const [changes, setChanges] = useState(null);
  const {
    data: doctors,
    total,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("doctor/list", filterData, [changes]);
  const [specialities] = useFetch("doctor/specialties");
  useEffect(() => {
    if (search.length >= 3) {
      setFilterData({ ...filterData, search });
    } else {
      if (filterData && filterData.search) {
        let newFilterData = filterData;
        delete newFilterData.search;
        setFilterData({ ...newFilterData });
      }
    }
    setChanges(!changes);
  }, [search]);
  useEffect(() => {
    dispatch(updateState({ search: "" }));
  }, []);
  const handleSpeciality = (e) => {
    if (e.target.value) {
      setFilterData({ ...filterData, speciality: e.target.value });
    } else {
      delete filterData.speciality;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleSort = (value) => {
    if (value) {
      setFilterData({ ...filterData, sort: value });
    } else {
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  return (
    <CommonLayout isNotSearchComp={true} searchTitle={lan.searchingDoctors}>
      <div id="doctorsComp" className="top-bottom">
        <div className="container-lg px-lg-0">
          <h3 className="block-header__title">{lan.doctors}</h3>
          <div
            className="section-header__divider"
            style={{ marginBottom: "20px" }}
          ></div>
          <div className="row mx-0">
            <div className="col-lg-3 px-0">
              <FilterLayout
                quantity={<DoctorsSort lan={lan} handleSort={handleSort} />}
              >
                <FilterItem title={lan.categories}>
                  <CommonRadioFilter
                    data={specialities.map((item) => {
                      return {
                        id: item.id,
                        name: item.name,
                      };
                    })}
                    handleValue={handleSpeciality}
                  />
                </FilterItem>
              </FilterLayout>
            </div>
            <div className="col-lg-9 pl-lg-3 px-0">
              <div className="sort-card">
                <div className="view-options__legend">
                  <h4 className="d-flex align-items-center">
                    {loading ? (
                      <SmallLoading />
                    ) : (
                      <span
                        style={{ marginRight: "2px", display: "inline-block" }}
                      >
                        {total}{" "}
                      </span>
                    )}
                    <span>
                      {" " + lan.amount + " " + lan.doctors.toLowerCase()}
                    </span>
                  </h4>
                </div>
                <div className="sort-selections d-flex justify-content-between flex-sm-row flex-column">
                  <div className="view-options__select d-none d-lg-block">
                    <label htmlFor="view-option-sort">{lan.sorting}:</label>
                    <DoctorsSort lan={lan} handleSort={handleSort} />
                  </div>
                </div>
              </div>
              <div>
                <InfiniteScrollCustom
                  data={doctors}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loading={loading}
                >
                  {doctors.map((item) => (
                    <div key={item.id} className="mb-3">
                      <DoctorCard item={item} />
                    </div>
                  ))}
                </InfiniteScrollCustom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default DoctorsPage;

const DoctorsSort = ({ lan, handleSort }) => {
  return (
    <Select
      style={{ width: 200 }}
      placeholder={lan.sortClinics}
      defaultValue="ALL"
      onChange={(e) => handleSort(e)}
    >
      <Option value="ALL">{lan.all}</Option>
      <Option value="RATE">{lan.mostfamous}</Option>
      <Option value="asc_fio">{lan.increasename}</Option>
      <Option value="desc_fio">{lan.decreasename}</Option>
    </Select>
  );
};

import React from "react";
import { Collapse } from "antd";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import "../style/QuesAns.scss";
import { sendData } from "../server/requestFunctions";
import { toast } from "react-toastify";
import useFetch from "../hooks/useFetch";

const { TextArea } = Input;
const { Panel } = Collapse;

const QuesAnsPage = (props) => {
  const lan = props.lan;
  const [form] = Form.useForm();
  const [data] = useFetch("get-faq-list");
  const onFinish = (values) => {
    sendData("store-faq-feedback", values).then((res) => {
      toast.success(lan.questionsendsuccessfully);
      form.resetFields();
    });
  };

  return (
    <div className="quesAnsComp top-bottom">
      <div className="container-lg px-lg-0">
        <h3 className="block-header__title">{lan.whatHelp}</h3>
        <div
          className="section-header__divider"
          style={{ marginBottom: "20px" }}
        ></div>
      </div>
      <div className="container-lg px-lg-0">
        <Form
          name="login"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={onFinish}
        >
          <Form.Item
            name="question"
            rules={[{ required: true, message: lan.notFilled }]}
          >
            <TextArea rows={2} placeholder={lan.whatHelp} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" className="w-100">
              {lan.sendMessage}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="container-lg px-lg-0 justify-content-center d-flex">
        <div className="quesAccordion">
          <Collapse
            accordion
            defaultActiveKey={["0"]}
            expandIconPosition="right"
          >
            {data.map((item, index) => (
              <Panel header={item.question} key={index + ""}>
                <div>{item.answer}</div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(QuesAnsPage);

// const items = [
//   {
//     uuid: "1",
//     heading: "Можно ли отменить бронирование?",
//     content:
//       "Ааа! Штраф за отмену устанавливает вариант размещения, вы выплачиваете его напрямую. Условия указаны в правилах отмены.",
//   },
//   {
//     uuid: "2",
//     heading:
//       "Если мне нужно отменить бронирование, придется ли платить штраф за его отмену?",
//     content:
//       "Вы не будете платить штраф, если у вас бронирование с бесплатной отменой. Если оно невозвратное или его больше нельзя отменить бесплатно, возможно, придется оплатить штраф. Сумма устанавливается вариантом размещения и выплачивается напрямую.",
//   },
//   {
//     uuid: "3",
//     heading: "Можно ли изменить даты в бронировании по невозвратному тарифу?",
//     content:
//       "За отмену бронирования по невозвратному тарифу обычно берется штраф. Однако вы можете запросить бесплатную отмену в разделе управления бронированием. Ваш запрос будет отправлен в объект размещения, в котором могут решить не брать с вас плату за отмену. Изменение дат бронирования по невозвратному тарифу невозможно. Однако, если с вас не будут взимать штраф за отмену, вы можете вновь оформить бронирование на желаемые даты.",
//   },
//   {
//     uuid: "4",
//     heading: "Как узнать, что мое бронирование было отменено?",
//     content:
//       "Вы должны получить письмо с подтверждением отмены. Проверьте почту и папку «Спам». Если вы не получите подтверждение в течение 24 часов, свяжитесь с объектом размещения, чтобы уточнить, получили ли они запрос на отмену.",
//   },
//   {
//     uuid: "5",
//     heading: "Где найти правила отмены моего варианта размещения?",
//     content: "Эту информацию можно найти в подтверждении бронирования.",
//   },
// ];

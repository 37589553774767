import React from "react";
import { Link } from "react-router-dom";
import "./ClinicServiceCard.scss";
import { addLike, addCompare } from "../../redux/actions/appActions";
import { connect } from "react-redux";
import ClinicLocation from "../Shared/ClinicLocation";
import { Divider, Rate } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useBuyService from "./../../hooks/useBuyService";

const ClinicServiceCard = (props) => {
  const item = props.item;
  const lan = props.lan;
  const [ButtonOpeningTimeTable] = useBuyService(item);
  return (
    <div className="clinicServiceCard">
      <div className="clinic-card">
        <div className="clinic-card__image">
          <div className="image image--type--clinic">
            <button className="like-img" onClick={() => props.addLike(item)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill={
                  props.likeServices.find((service) => service.id === item.id)
                    ? "red"
                    : "white"
                }
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-heart"
              >
                <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
              </svg>
            </button>
            <Link className="image__body" to={`/clinics/${item.clinic.id}`}>
              <LazyLoadImage
                alt=""
                className="image__tag"
                placeholderSrc="/images/no-hospital.png"
                src={item.clinic.image}
              />
            </Link>
            {/* <Link
              className="clinic-name"
              to={`/clinics/${generateClinicUrl(
                item.clinicsId,
                item.clinicsName
              )}`}
            >
              {item.clinicsName}
            </Link> */}
          </div>
        </div>
        <div className="clinic-card__info">
          <div className="clinic-card__name">
            <h5 className="service_name">{item.name}</h5>
            <Divider />
            {/* <h5>
              <Link to={`/doctors/${item.id}`}>
                {item.showDoctor === false || item.doctorName}
              </Link>
            </h5> */}
            <div
              className="clinic-card__features"
              dangerouslySetInnerHTML={{
                __html: item && item.clinic && item.clinic.description,
              }}
            />
          </div>
          <div className="d-md-none d-lg-block d-block">
            <ClinicLocation
              location={[item.clinic.location_y, item.clinic.location_x]}
              name={item.clinic.name}
            />
          </div>
        </div>
        <div className="clinic-card__footer">
          <div className="clinic-card__prices">
            {item.price} {lan.sum}
            <div className="d-md-block d-lg-none d-none">
              <ClinicLocation
                location={[item.clinic.location_y, item.clinic.location_x]}
                name={item.clinic.name}
              />
            </div>
          </div>
          {item.score ? (
            <Rate
              disabled
              defaultValue={Math.round(item.score * 2) / 2}
              allowHalf
            />
          ) : (
            <Rate disabled defaultValue={5} />
          )}

          <div className="d-flex justify-content-between align-items-center flex-md-column w-100">
            <button
              type="button"
              className="product-card__compare mb-md-4"
              onClick={() => props.addCompare(item)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill={
                  props.compareServices.find(
                    (service) => service.id === item.id
                  )
                    ? "red"
                    : "currentColor"
                }
              >
                <path d="M9 15H7c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v12c0 .6-.4 1-1 1zM1 9h2c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1H1c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1zM15 5h-2c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1z"></path>
              </svg>
              <span>{lan.addCompare}</span>
            </button>
            {ButtonOpeningTimeTable}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    compareServices: state.app.compareServices,
    totalPrice: state.app.totalPrice,
    likeServices: state.app.likeServices,
    lan: state.app.language,
  };
};

export default connect(mapStateToProps, {
  addLike,
  addCompare,
})(ClinicServiceCard);

import React, { useState } from "react";
import { sendData } from "../../server/requestFunctions";
import { Rate, Input, Button, Avatar, Comment } from "antd";
import NoData from "./NoData";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Loading from "./Loading";
import useFetchWithPgtn from "../../hooks/useFetchWithPgtn";
import { getCookie } from "./../../functions/useCookies";
import { TOKEN } from "../../tools/constants";

const { TextArea } = Input;

const CustomComment = (props) => {
  const lan = useSelector((state) => state.app.language);
  const [changes, setChanges] = useState(false);
  const [rate, setRate] = useState(5);
  const [comment, setComment] = useState("");
  const {
    data: comments,
    pgtn,
    loading,
  } = useFetchWithPgtn(props.url, null, [changes]);
  const handleRate = (rate) => {
    setRate(rate);
  };
  const handleComment = (e) => {
    setComment(e.target.value);
  };
  const sendComment = () => {
    sendData(props.sendurl, {
      score: rate,
      comment,
    }).then((res) => {
      toast.success(lan.thanksForComment);
      setChanges(!changes);
      setComment("");
    });
  };
  return (
    <div>
      {getCookie(TOKEN) && (
        <div className="px-3">
          <Rate
            allowClear={false}
            onChange={handleRate}
            allowHalf
            defaultValue={5}
          />
          <TextArea
            className="mt-3"
            value={comment}
            placeholder={lan.comment}
            showCount
            rows={2}
            maxLength={200}
            allowClear
            onChange={handleComment}
          />
          <Button className="mt-3" type="primary" onClick={sendComment}>
            {lan.sendMessage}
          </Button>
        </div>
      )}
      {loading ? (
        <Loading />
      ) : comments && comments.length !== 0 ? (
        <>
          <div className="px-3">
            {comments.map((comment) => (
              <Comment
                key={comment.id}
                author={
                  <div className="comment-rate">
                    <h4>{comment.user.username}</h4>{" "}
                    <Rate
                      allowClear={false}
                      disabled
                      onChange={handleRate}
                      value={comment.score || 5}
                    />
                    <span>({comment.score || 5})</span>
                  </div>
                }
                avatar={
                  <Avatar
                    src={comment.user.avatar}
                    alt={comment.user.name + " " + comment.user.surname}
                  />
                }
                content={<p>{comment.comment}</p>}
                datetime={
                  <span>
                    {comment.created_at.slice(0, 10)},{" "}
                    {comment.created_at.slice(11, 19)}
                  </span>
                }
              />
            ))}
          </div>
          {pgtn}
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default CustomComment;

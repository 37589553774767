import React from "react";
import { GiNurseMale } from "react-icons/gi";
import { AiOutlineLike } from "react-icons/ai";
import { RiHospitalLine } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import "./Numbers.scss";

const Numbers = () => {
  const lan = useSelector((state) => state.app.language);
  const [data] = useFetch("get-about-info");

  return (
    <div className="numbersComp">
      <div className="d-flex flex-wrap justify-content-md-between mx-0">
        <div className="px-0 d-flex numbers-item">
          <div className="numbers-item-img d-flex flex-column justify-content-end">
            <VscPreview />
          </div>
          <div className="numbers-item-inf d-flex justify-content-around flex-column">
            <p>{data.count_comments || 0}</p>
            <span>{lan.comments}</span>
          </div>
        </div>
        <div className="px-0 d-flex numbers-item">
          <div className="numbers-item-img d-flex flex-column justify-content-end">
            <GiNurseMale />
          </div>
          <div className="numbers-item-inf d-flex justify-content-around flex-column">
            <p>{data.count_doctors || 0}</p>
            <span>{lan.allDoctors}</span>
          </div>
        </div>
        <div className="px-0 d-flex numbers-item">
          <div className="numbers-item-img d-flex flex-column justify-content-end">
            <AiOutlineLike />
          </div>
          <div className="numbers-item-inf d-flex justify-content-around flex-column">
            <p>{data.count_clients || 0}</p>
            <span>{lan.allClients}</span>
          </div>
        </div>
        <div className="px-0 d-flex numbers-item">
          <div className="numbers-item-img d-flex flex-column justify-content-end">
            <RiHospitalLine />
          </div>
          <div className="numbers-item-inf d-flex justify-content-around flex-column">
            <p>{data.count_clinics || 0}</p>
            <span>{lan.allHosp}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Numbers;

export const CitiesData = [
  {
    id: 0,
    img: "/images/cities/andijon.jpg",
    name: "Aндижон",
    clinicsNumber: 13,
  },
  {
    id: 1,
    img: "/images/cities/buxaro.jpg",
    name: "Бухаро",
    clinicsNumber: 43,
  },
  {
    id: 2,
    img: "/images/cities/jizzax.jpg",
    name: "Жиззах",
    clinicsNumber: 23,
  },
  {
    id: 3,
    img: "/images/cities/qashqadaryo.jpg",
    name: "Қашқадарё",
    clinicsNumber: 56,
  },
  {
    id: 4,
    img: "/images/cities/samarqand.jpg",
    name: "Самарқанд",
    clinicsNumber: 98,
  },
  {
    id: 5,
    img: "/images/cities/tashkent.jpg",
    name: "Ташкент",
    clinicsNumber: 139,
  },
  {
    id: 6,
    img: "/images/cities/navoiy.jpg",
    name: "Навоий",
    clinicsNumber: 31,
  },
  {
    id: 7,
    img: "/images/cities/namangan.jpg",
    name: "Наманган",
    clinicsNumber: 51,
  },
  {
    id: 8,
    img: "/images/cities/fargana.jpg",
    name: "Фарғона",
    clinicsNumber: 65,
  },
  {
    id: 9,
    img: "/images/cities/xorazm.jpg",
    name: "Хоразм",
    clinicsNumber: 98,
  },
  {
    id: 10,
    img: "/images/cities/sirdaryo.jpg",
    name: "Сирдарё",
    clinicsNumber: 58,
  },
  {
    id: 11,
    img: "/images/cities/surxondaryo.jpg",
    name: "Сурхандарё",
    clinicsNumber: 47,
  },
  {
    id: 12,
    img: "/images/cities/surxondaryo.jpg",
    name: "Ташкент область",
    clinicsNumber: 67,
  },
];

import React from "react";
import { Document, Page, StyleSheet, Font } from "@react-pdf/renderer";
import Header from "./Header";
import ServiceInformation from "./ServiceInformation";
import Footer from "./Footer";

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 15,
  },
});

Font.register({
  family: "Open Sans",
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`,
});

Font.register({
  family: "Lato",
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`,
});

Font.register({
  family: "Lato Italic",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`,
});

Font.register({
  family: "Lato Bold",
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`,
});

Font.register({
  family: "Roboto",
  src: `https://fonts.googleapis.com/css2?family=Roboto&display=swap`,
});

// Create Document Component
const OrderPDFGenerator = (props) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Header name={props.name} />
      <ServiceInformation {...props} />
      <Footer />
    </Page>
  </Document>
);

export default OrderPDFGenerator;

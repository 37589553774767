import React from "react";
import { Link } from "react-router-dom";

const FreeBlock = (props) => {
  return (
    <div className="block-empty__body">
      <h1 className="block-empty__title">{props.name}</h1>
      <div>{props.about}</div>
      <div className="block-empty__action">
        <Link className="btn btn-primary btn-sm" to="/">
          На главную
        </Link>
      </div>
    </div>
  );
};

export default FreeBlock;

import React from "react";
import TechCard from "../../components/Cards/TechCard";
import SpecificClinicLayout from "../../components/Layouts/SpecificClinicLayout";
import InfiniteScrollCustom from "../../components/Shared/InfiniteScrollCustom";
import { useSelector } from "react-redux";
import useFetchWithScrollPgtn from "../../hooks/useFetchWithScrollPgtn";

const SpecificClinicTechPage = (props) => {
  const {
    data: tools,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("clinic/tools/" + props.match.params.id);

  const lan = useSelector((state) => state.app.language);

  return (
    <SpecificClinicLayout breadcrumbs={[lan.technologies]}>
      <InfiniteScrollCustom
        data={tools}
        loading={loading}
        next={fetchMoreData}
        hasMore={hasMore}
      >
        {tools.map((tool) => (
          <div className="mb-3" key={tool.id}>
            <TechCard {...tool} />
          </div>
        ))}
      </InfiniteScrollCustom>
    </SpecificClinicLayout>
  );
};

export default SpecificClinicTechPage;

import React from "react";
import { useSelector } from "react-redux";
import "../style/SpecificNewsComp.scss";
import useFetch from "./../hooks/useFetch";
import Loading from "./../components/Shared/Loading";

const SpecialNewsPage = (props) => {
  const [data, loading] = useFetch(`get-article/${props.match.params.id}`);

  const lan = useSelector((state) => state.app.language);
  return (
    <div className="specialNewsComp">
      {loading ? (
        <Loading />
      ) : (
        <div className="block post-view">
          <div className="post-view__header post-header post-header--has-image">
            <div
              className="post-header__image"
              style={{
                backgroundImage: `url(${data.image})`,
              }}
            ></div>
            <div className="post-header__body">
              <h1 className="post-header__title">{data.title}</h1>
              <div className="post-header__meta">
                <ul className="post-header__meta-list">
                  <li className="post-header__meta-item">{data.badge}</li>
                  <li className="post-header__meta-item">
                    {lan.allmonths[new Date(data.created_at).getMonth()] +
                      " " +
                      new Date(data.created_at).getDate() +
                      "," +
                      new Date(data.created_at).getFullYear()}
                    {" , "}
                    {lan.byMade} {data.author}
                  </li>
                </ul>
              </div>
            </div>
            <div className="decor decor--type--bottom post-header__decor">
              <div className="decor__body">
                <div className="decor__start"></div>
                <div className="decor__end"></div>
                <div className="decor__center"></div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="post-view__body">
              <div className="post-view__item post-view__item-post">
                <div className="post-view__card post">
                  <div className="post__body typography">
                    <p
                      className="section-heading"
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SpecialNewsPage;

const IP_ADDRESS = "https://gateway.medpay.uz/api/v1/";
const API_URL = "https://welse.uz/api";

export const SITE_LANG = "language";
export const TOKEN = "MEDPAY_TOKEN";
export const REGISTER_SMS = "MEDPAY_REGISTER_SMS";
export const MEDPAY_USER_USERNAME = "MEDPAY_USER_USERNAME";
export const REFRESH_TOKEN = "MEDPAY_REFRESH_TOKEN";
export const USER_ID = "MEDPAY_USER_ID";
export const IS_VALIABLE_REGISTER_SMS = "IS_VALIABLE_REGISTER_SMS";
export const RETURN_CODE_DATE = "RETURN_CODE_DATE";
export const SENDED_DATA_RESET_PASSWORD_TIME =
  "SENDED_DATA_RESET_PASSWORD_TIME";
export const IS_VERIFIED = 'IS_VERIFIED'
export const RETURN_CODE_RESET_DATE = "RETURN_CODE_RESET_DATE";
export const COMPARE_SERVICES = "COMPARE_SERVICES";
export const FAVOURITE_SERVICES = "FAVOURITE_SERVICES";

export const API_URL_FRONT = `${API_URL}/front/`;

export const API_WEB_URL = `${IP_ADDRESS}web/`;
export const API_AUTH = `${IP_ADDRESS}auth/`;
export const API_V_URL = `${IP_ADDRESS}`;
export const WEB_SOCKET_URL = `https://gateway.medpay.uz/mb-websocket`;

export const API_IMAGE_URL = API_V_URL + "files/public/";
export const API_USER_IMAGE_URL = API_V_URL + "files/";

export const SMS_EXPIRED_MINUT = 5;
export const CODE_RETURN_TIME = 1;
export const ORDER_EXPIRED_MINUT = 5;
export const PGNTN_LIMIT = 10;
export const MAX_NUMBER_SELECTING_TIME = 4;
export const NUMBER_WEEKS_SERVICE_SCHEDULE = 1;

import React from "react";
import { useSelector } from "react-redux";
import { Rate } from "antd";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ClinicLocation from "../Shared/ClinicLocation";
import "./DoctorCard.scss";
import useDoctorService from "./../../hooks/useDoctorService";

const DoctorCard = (props) => {
  const doctor = props.item;
  const lan = useSelector((state) => state.app.language);
  const [ApplyingButton] = useDoctorService(doctor);

  return (
    <div className="doctorCard">
      <div className="clinic-card">
        <div className="clinic-card__image">
          <div className="image image--type--clinic d-flex flex-column justify-content-center">
            <Link
              className="image__body text-center"
              to={`/doctors/${doctor.user_id}`}
            >
              <LazyLoadImage
                alt=""
                placeholderSrc="/images/doctor-avatar.jpg"
                className="image__tag"
                effect="blur"
                src={doctor.user.avatar}
              />
            </Link>
            <div className="stage">
              <h5 className="mb-0">
                {props.rate ? (
                  <div className="clinic-card-rate">
                    <Rate
                      disabled
                      defaultValue={Math.round(props.rate * 2) / 2}
                      allowHalf
                    />
                    <p>({props.rate})</p>
                  </div>
                ) : (
                  <div className="clinic-card-rate">
                    <Rate disabled defaultValue={5} />
                    <p>(5)</p>
                  </div>
                )}
              </h5>
            </div>
          </div>
          <div className="doctor-about-price d-md-none d-flex align-items-center justify-content-between flex-column">
            <h5>
              {lan.workExperience}: {doctor.specialty_experience || 5}{" "}
              {lan.year}
            </h5>
            <h5>
              {lan.category}: {doctor.category}
            </h5>
            <h5>
              {lan.level}: {doctor.academic_degree}
            </h5>
            <div className="mt-3">{ApplyingButton}</div>
          </div>
        </div>
        <div className="clinic-card__info">
          <div className="clinic-card__name">
            <Link to={`/doctors/${doctor.user_id}`}>
              <h4 className="mb-1">{doctor.user.fio}</h4>
            </Link>
            <p>
              {doctor.specialty && (
                <span className="doctorsList30ItemSpecialisation">
                  {doctor.specialty.map((spe) => spe.name).join(", ")}
                </span>
              )}
            </p>
          </div>
          <div className="clinic-card__features">
            <p>
              {`${lan.languagesknow}: ${
                doctor.languages &&
                doctor.languages.map((lang) => lang.name).join(", ")
              }`}
            </p>
            <p>{`${lan.gender}: ${lan[doctor.user.gender]}`}</p>
          </div>
          <ClinicLocation
            name={doctor.clinic.name}
            location={[doctor.clinic.location_y, doctor.clinic.location_x]}
          />
        </div>
        <div className="clinic-card__footer d-md-flex flex-md-column justify-content-between d-none">
          <h5>
            {lan.workExperience}: {doctor.specialty_experience || 5} {lan.year}
          </h5>
          <h5>
            {lan.category}: {doctor.category}
          </h5>
          <h5>
            {lan.level}: {doctor.academic_degree}
          </h5>
          {ApplyingButton}
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;

import React from "react";
import { Row, Col } from "antd";
import ClinicDoctorCard from "../../components/Cards/ClinicDoctorCard";
import SpecificClinicLayout from "../../components/Layouts/SpecificClinicLayout";
import InfiniteScrollCustom from "../../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from "../../hooks/useFetchWithScrollPgtn";

const SpecificClinicDoctorsPage = (props) => {
  const {
    data: doctors,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("clinic/doctors/" + props.match.params.id);
  return (
    <SpecificClinicLayout breadcrumbs={[]}>
      <InfiniteScrollCustom
        data={doctors}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
      >
        <Row gutter={[16, 16]}>
          {doctors.map((doctor) => (
            <Col span={24} sm={12} md={8} lg={6} xl={4} key={doctor.id}>
              <ClinicDoctorCard {...doctor} />
            </Col>
          ))}
        </Row>
      </InfiniteScrollCustom>
    </SpecificClinicLayout>
  );
};

export default SpecificClinicDoctorsPage;

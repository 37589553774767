import { useEffect, useState } from "react";
import { getAllDataWithPagination } from "../server/config/common";

const usePaginationFetch = (url, changes) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalElements, setTotalElements] = useState(0);
  const [loading, setLoading] = useState(false);
  useEffect(
    () => {
      setLoading(true);
      getAllDataWithPagination(url, 0)
        .then((res) => {
          setData(res.data.content);
          setTotalElements(res.data.totalElements);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    changes ? [...changes] : []
  );
  const handlePaginationChange = (page) => {
    setLoading(true);
    setPage(page);
    getAllDataWithPagination(url, page - 1).then((res) => {
      setData(res.data.content);
      setLoading(false);
    });
  };
  return [data, page, totalElements, loading, handlePaginationChange];
};

export default usePaginationFetch;

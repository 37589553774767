import React, { useState } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";

const { Option } = Select;

const SearchSymptom = (props) => {
  const [search, setSearch] = useState("");
  const [searchedSymptoms] = useFetch(
    "symptoms/symptom-list",
    { page: 1, search },
    [search]
  );
  function onSearch(value) {
    setSearch(value);
  }

  const lan = useSelector((state) => state.app.language);

  return (
    <div className="sort-card">
      <div className="row w-100 mx-0">
        <div className="col-12">
          <Select
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder={`${lan.searchingSymptom}...`}
            optionFilterProp="children"
            onSearch={onSearch}
            onSelect={props.handleSymptompId}
            onClear={props.searchClear}
          >
            {searchedSymptoms.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default SearchSymptom;

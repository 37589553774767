import React, { useState } from "react";
import { Input, Form, Row, Col } from "antd";
import { useSelector } from "react-redux";
import "./CreditCard.scss";

const CreditCard = (props) => {
  const lan = useSelector((state) => state.app.language);
  const [data, setData] = useState({ card_number: "", card_expire: "" });

  const handleCardNumber = (e) => {
    setData({
      ...data,
      card_number: cc_format(e.target.value),
    });
    props.getCreditCard({
      card_expire: data.card_expire.replace(/\\|\//g, ""),
      card_number: cc_format(e.target.value).replace(/\s/g, ""),
    });
  };

  const handleDate = (e) => {
    setData({
      ...data,
      card_expire: ccd_format(e.target.value),
    });
    props.getCreditCard({
      card_number: data.card_number.replace(/\s/g, ""),
      card_expire: ccd_format(e.target.value).replace(/\\|\//g, ""),
    });
  };

  const ccd_format = (e) => {
    var v = e
      .replace(/\s+/g, "")
      .replace(/[^0-9]/gi, "")
      .replace(
        /^([0-1])([3-9])$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^([0-9]\/|[2-9])$/g,
        "0$1/" // 3 > 03/
      );

    var matches = v.match(/\d{0,4}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];
    let len = match.length;
    for (let i = 0; i < len; i += 2) {
      parts.push(match.substring(i, i + 2));
    }

    if (parts.length) {
      return parts.join("/");
    } else {
      return v;
    }
  };

  const cc_format = (e) => {
    var v = e.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];
    let len = match.length;
    for (let i = 0; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      return parts.join(" ");
    } else {
      return e;
    }
  };

  return (
    <div className="credit-card">
      <Row gutter={[8, 8]}>
        <Col span={16}>
          <Form.Item
            label={lan.cardNumber}
            rules={[
              {
                required: true,
                message: lan.notFilled,
              },
            ]}
            className="flex-column"
          >
            <Input
              onChange={handleCardNumber}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              value={data.card_number}
              placeholder="8600 1782 4291 8724 9836"
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={lan.cardExpiry}
            rules={[
              {
                required: true,
                message: lan.notFilled,
              },
            ]}
          >
            <Input
              maxLength="5"
              placeholder="MM/YY"
              type="text"
              value={data.card_expire}
              onChange={handleDate}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default CreditCard;

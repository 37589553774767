import { useState, useEffect } from "react";
import { getDataAuth } from "../server/config/common";

const useFetchAuth = (url, changes) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(
    () => {
      setLoading(true);
      setError(false);
      getDataAuth(url)
        .then((res) => {
          res.data.content
            ? setData([...data, ...res.data.content])
            : setData(res.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    changes ? [...changes] : []
  );
  return [data, loading, error];
};

export default useFetchAuth;

import React from "react";
import ServiceCard from "../components/Cards/ServiceCard";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import useFetch from "../hooks/useFetch";
import Title from "../components/Shared/Title";
import { isEmpty } from "../functions";

const { TabPane } = Tabs;

const SpecificIllnessPage = (props) => {
  const id = props.match.params.id;
  const [illness] = useFetch(`sickness/sickness/${id}`);
  const lan = useSelector((state) => state.app.language);

  return (
    <div className="specificIllnessComp top-bottom">
      <div className="container-lg px-lg-0">
        <Title title={illness.name} />
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab={lan.description} key="1">
            <p
              dangerouslySetInnerHTML={{
                __html: illness.description,
              }}
            />
          </TabPane>
          <TabPane tab={lan.services} key="2">
            <div className="pt-3">
              <div className="row mx-0">
                <div className="col-lg-12 px-lg-0">
                  {isEmpty(illness) ||
                    illness.services.map((item) => (
                      <div key={item.id} className="mb-3">
                        <ServiceCard {...item} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SpecificIllnessPage;

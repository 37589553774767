import React, { useEffect, useState } from "react";
import { getData } from "../server/requestFunctions";
import { Pagination } from "antd";

const useFetchWithPgtn = (url, query, changes) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(
    () => {
      setLoading(true);
      getData(url, { ...query, page: 1 })
        .then((res) => {
          let resData = res.data.data || res.data;
          setData(resData);
          setTotal(res.data.total);
          setPage(2);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    changes ? [...changes] : []
  );
  const handlePaginationChange = (page) => {
    setLoading(true);
    setPage(page);
    getData(url, { ...query, page })
      .then((res) => {
        let resData = res.data.data || res.data;
        setData(resData);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const pgtn = total > 10 && (
    <Pagination
      showSizeChanger={false}
      onChange={handlePaginationChange}
      defaultCurrent={page}
      total={total}
    />
  );
  return { data, page, total, loading, handlePaginationChange, error, pgtn };
};

export default useFetchWithPgtn;

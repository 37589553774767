import React, { useState } from "react";
import "./Search.scss";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getData } from "../../server/requestFunctions";

const Search = (props) => {
  const history = useHistory();
  const customStyles = {
    control: (styles, { isDisabled }) => {
      return {
        ...styles,
        cursor: isDisabled ? "not-allowed" : "default",
        opacity: isDisabled ? "0.6" : "1",
        color: isDisabled ? "#999" : "white",
        fontSize: 20,
        height: 50,
      };
    },
    menu: (base) => ({
      ...base,
      fontSize: 16,
    }),
  };
  const [value, setValue] = useState("");
  const [subMenuValue, setSubMenuValue] = useState("");
  const [subMenus, setSubMenus] = useState([]);
  const handleInput = (e) => {
    setValue(e.value);
    getData("services/category-list", {
      // type: "standard",
      category_id: e.value,
    }).then((res) => {
      setSubMenus(
        res.data.map((item) => {
          return { label: item.name, value: item.id };
        })
      );
    });
  };
  const handleSubMenuInput = (value) => {
    setSubMenuValue(value);
  };
  const lan = useSelector((state) => state.app.language);

  return (
    <div className="search py-lg-3 pt-3">
      <div className="container-lg px-0">
        {props.children}
        <form>
          <div className="row mx-0">
            <div className="col-lg-9 px-0">
              <div className="row mx-0 search-boxes">
                <div className="col-lg-3 px-0 col-12">
                  <div className="menus-selection mr-lg-1 mb-lg-0 mb-2">
                    <Select
                      onChange={handleInput}
                      styles={{
                        control: (base) => ({
                          ...base,
                          fontSize: 20,
                          height: 50,
                        }),
                        menu: (base) => ({
                          ...base,
                          fontSize: 16,
                        }),
                        background: "black",
                      }}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#1A79FF",
                          primary: "#333",
                        },
                      })}
                      className="w-100"
                      options={props.menus}
                      placeholder={lan.menuPl + " ..."}
                    ></Select>
                  </div>
                </div>
                <div className="col-lg-9 px-0 col-12">
                  <div className="submenus-selection mr-lg-1">
                    <Select
                      onChange={handleSubMenuInput}
                      styles={customStyles}
                      defaultMenuIsOpen={value ? true : false}
                      isDisabled={value ? false : true}
                      theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                          ...theme.colors,
                          primary25: "#1A79FF",
                          primary: "#333",
                        },
                      })}
                      openOnFocus={true}
                      autofocus={true}
                      options={subMenus}
                      placeholder={lan.subMenuPl + " ... "}
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 px-0 search-button">
              <button
                disabled={subMenuValue === "" ? true : false}
                button="submit"
                className="searchButton btn btn-primary mr-lg-3 mt-lg-0 mt-2"
                onClick={() => history.push(`/services/${subMenuValue.value}`)}
              >
                {lan.searchCom}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Search;

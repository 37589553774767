import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./NewsCard.scss";

const NewsCard = (props) => {
  const date = new Date(props.created_at);
  const lan = useSelector((state) => state.app.language);
  return (
    <div className="newsCard">
      <div className="posts-list__item">
        <div className="post-card post-card--layout--list">
          <div className="post-card__image">
            <Link to={`/news/${props.id}`}>
              <LazyLoadImage
                alt={props.title}
                src={props.image || "/news.jpg"}
              />
            </Link>
          </div>
          <div className="post-card__content">
            <div className="post-card__category">
              <Link to="/news">{props.badge}</Link>
            </div>
            <div className="post-card__title">
              <h2>
                <Link to={`/news/${props.id}`}>{props.title}</Link>
              </h2>
            </div>
            <div className="post-card__excerpt">
              <div
                className="typography"
                dangerouslySetInnerHTML={{ __html: props.description }}
              />
            </div>
            <div className="post-card__date">
              {lan.allmonths[date.getMonth()] +
                " " +
                date.getDate() +
                "," +
                date.getFullYear()}
              {" , "}
              {lan.byMade} {props.author}
            </div>
            <div className="post-card__more">
              <Link className="btn btn-primary btn-sm" to={`/news/${props.id}`}>
                {lan.readMore}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCard;

import axios from "axios";
import { deleteCookie, getCookie, setCookie } from "../functions/useCookies";
import {
  API_V_URL,
  SITE_LANG,
  TOKEN,
  REFRESH_TOKEN,
  API_AUTH,
  API_WEB_URL,
} from "../tools/constants";
import { toast } from "react-toastify";
import { refreshToken } from "./config/user";
import { lan } from "../redux/reducers/appReducer";

export const HttpRequestHub = (config = null) => {
  let token = getCookie(TOKEN);

  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: token ? `Bearer ${token}` : "",
    Language: localStorage.getItem(SITE_LANG),
  };

  let axiosInstance = axios.create({
    baseURL: `${API_WEB_URL}`,
    headers,
    timeout: 100000,
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.data) {
        error.response.data.message && toast.error(error.response.data.message);
        error.response.data.error && toast.error(error.response.data.error);
      }

      throw error;
    }
  );
  return axiosInstance(config);
};

export const HttpRequestHubFile = (config = null) => {
  let token = getCookie(TOKEN);
  let fileHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-type": "multipart/form-data",
    Authorization: token ? `Bearer ${token}` : "",
  };
  let axiosInstanceFile = axios.create({
    baseURL: `${API_V_URL}files/`,
    headers: fileHeaders,
    timeout: 100000,
  });

  return axiosInstanceFile(config);
};

export const HttpRequestHubAuth = (config = null) => {
  let token = getCookie(TOKEN);
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: token ? `Bearer ${token}` : "",
    Language: localStorage.getItem(SITE_LANG),
  };
  let axiosInstanceAuth = axios.create({
    baseURL: `${API_AUTH}`,
    headers,
    timeout: 100000,
  });
  axiosInstanceAuth.interceptors.response.use(
    (response) => response,
    (error) => {
      error.response.data.message && toast.error(error.response.data.message);
      throw error;
    }
  );

  return axiosInstanceAuth(config);
};

export const HttpRequestHubCommon = (config = null) => {
  let token = getCookie(TOKEN);
  let headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: token ? `Bearer ${token}` : "",
    Language: localStorage.getItem(SITE_LANG),
  };
  let instance = axios.create({
    baseURL: `${API_V_URL}`,
    headers,
    timeout: 100000,
  });
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      let token = getCookie(TOKEN);
      if (error.response) {
        if (error.response.status === 403) {
          if (!token) {
            toast.error(lan.pleaseLogin);
          } else {
            deleteCookie(TOKEN);
            deleteCookie(REFRESH_TOKEN);
            refreshToken().then((res) => {
              setCookie(TOKEN, res.data.access_token);
              setCookie(REFRESH_TOKEN, res.data.refresh_token);
            });
            toast.error(lan.refresh);
            window.location.href = "/account";
          }
        }
        error.response.data && toast.error(error.response.data.message);
      }
      throw error;
    }
  );
  return instance(config);
};

export const HttpRequestHubAuthRefresh = (config = null) => {
  let refresh_token = getCookie(REFRESH_TOKEN);
  let refresh_headers = {
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: refresh_token ? `Bearer ${refresh_token}` : "",
    Language: localStorage.getItem(SITE_LANG),
  };
  let axiosInstanceAuthRefresh = axios.create({
    baseURL: `${API_AUTH}`,
    headers: refresh_headers,
    timeout: 100000,
  });

  return axiosInstanceAuthRefresh(config);
};

import React from "react";
import { Link } from "react-router-dom";
import "./HomeNewsCard.scss";

const HomeNewsCard = (props) => {
  return (
    <div className="newsItem p-1">
      <div>
        <Link to={`/news/${props.id}`}>
          <img alt={props.title} src={props.image || "/news.jpg"} />
        </Link>
      </div>
      <div className="newsItem__content">
        <div className="newsItem__category">{props.badge}</div>
        <div className="newsItem__title">
          <h2>
            <Link to={`/news/${props.id}`}>{props.title}</Link>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default HomeNewsCard;

import React from "react";
import CityCard from "../components/Cards/CityCard";
import { CitiesData } from "../data/CititesData";

const CitiesPage = () => {
  return (
      <div className="citiesComp top-bottom">
        <div className="container-lg px-lg-0">
          <h3>Болезни</h3>
          <div
            className="section-header__divider"
            style={{ marginBottom: "20px" }}
          ></div>
          <div className="row mx-0 mx-lg-0">
            {CitiesData.map((item, index) => (
              <div className="oneIllnessCard col-lg-3 col-md-4 col-sm-6 col-12 mb-3">
                <CityCard
                  key={index}
                  name={item.name}
                  img={item.img}
                  clinicsNumber={item.clinicsNumber}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default CitiesPage;

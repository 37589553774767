import React from "react";
import { connect } from "react-redux";
import { Form, Input, Button, Col, Row } from "antd";
import "../style/ContactUs.scss";
import useFetch from "../hooks/useFetch";
import { sendData } from "../server/requestFunctions";
import { toast } from "react-toastify";
import { YMaps, Map, Placemark } from "react-yandex-maps";

const { TextArea } = Input;

const ContactUsPage = (props) => {
  const lan = props.lan;
  const [form] = Form.useForm();
  const [info] = useFetch("get-contact-info");

  const onFinish = (values) => {
    sendData("store-feedback", values).then((res) => {
      form.resetFields();
      toast.success(lan.messagesendsuccessfully);
    });
  };

  return (
    <div className="contactUs">
      <div className="our-map-location">
        <YMaps className="react-yandex-map">
          <Map
            state={{
              zoom: 11,
              behaviors: ["default", "scrollZoom"],
              center: [info.location_y, info.location_x],
            }}
          >
            <Placemark
              geometry={[info.location_y, info.location_x]}
              options={{
                preset: "islands#violetIcon",
                iconLayout: "default#image",
                // iconImageHref: hospital,
                iconImageSize: [35, 35],
              }}
            />
          </Map>
        </YMaps>
      </div>
      <div className="block top-bottom">
        <div className="container">
          <h1 className="block-header__title">{lan.connectUs}</h1>
          <div
            className="section-header__divider"
            style={{ marginBottom: "20px" }}
          ></div>
          <div className="card">
            <div className="card-body card-body--padding--2">
              <div className="row">
                <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                  <div className="mr-1">
                    <div
                      className="contact-us__address"
                      dangerouslySetInnerHTML={{ __html: info.contact_text }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    form={form}
                    onFinish={onFinish}
                    name="contact"
                  >
                    <Row gutter={[8, 8]}>
                      <Col xs={24} lg={12} sm={24}>
                        <Form.Item
                          label={lan.name}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: lan.notFilled,
                            },
                          ]}
                        >
                          <Input placeholder={lan.name} />
                        </Form.Item>
                      </Col>
                      <Col xs={24} lg={12} sm={24}>
                        <Form.Item
                          label={lan.email}
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: lan.notFilled,
                            },
                            {
                              pattern: /^\S+@\S+$/,
                              message: lan.emailValidation,
                            },
                          ]}
                        >
                          <Input placeholder={lan.email} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      label={lan.messageTitle}
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: lan.notFilled,
                        },
                      ]}
                    >
                      <Input placeholder={lan.messageTitle} />
                    </Form.Item>
                    <Form.Item
                      label={lan.message}
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: lan.notFilled,
                        },
                      ]}
                    >
                      <TextArea rows={2} />
                    </Form.Item>
                    <Form.Item>
                      <Button htmlType="submit" type="primary" className="mt-4">
                        {lan.sendMessage}
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(ContactUsPage);

import { getData } from "../server/requestFunctions";
import { TOKEN, USER_ID } from "../tools/constants";
import { setCookie } from "./useCookies";

export const saveAuth = (res, callback) => {
  setCookie(TOKEN, res.data.token);
  getData(`user/profile`).then(async (res) => {
    await setCookie(USER_ID, res.data.user.id);
    callback && callback();
  });
};

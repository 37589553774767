import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { countDistance } from "../../functions/countDistance";
import ClinicLocation from "../Shared/ClinicLocation";
import "./ClinicCard.scss";

const ClinicCard = (props) => {
  const lan = useSelector((state) => state.app.language);

  const url = props.id;
  return (
    <div className="clinicCard mb-4 card">
      <div className="clinic-card">
        <div className="clinic-card__image">
          <div className="image image--type--clinic">
            <Link className="image__body" to={`/clinics/${url}`}>
              <LazyLoadImage
                alt=""
                className="image__tag"
                effect="blur"
                placeholderSrc="/images/no-hospital.png"
                src={
                  props.images.length !== 0 ? props.images[0].image : undefined
                }
              />
            </Link>
          </div>
        </div>
        <div className="clinic-card__info">
          <div>
            <div className="clinic-card__name">
              <h4>
                <Link to={`/clinics/${url}`}>{props.name}</Link>
              </h4>
            </div>
            <div
              className="clinic-card__features"
              dangerouslySetInnerHTML={{ __html: props.description }}
            />
          </div>
          <ClinicLocation location={props.location} />
        </div>
        <div className="clinic-card__footer d-flex flex-md-column justify-content-between">
          <h5>{`Chegirma: ${props.client_discount} %`}</h5>
          {(props.location[0] && props.location[0] === "undefined") || (
            <p className="distance">
              {props.userLocation.length !== 0
                ? `${lan.distanceUs}: ${countDistance(
                    props.userLocation,
                    props.location
                  )} ${lan.km}`
                : lan.dontAllowToLocation}
            </p>
          )}
          <Link to={`/clinics/${url}`} className="linkM">
            {lan.more}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ClinicCard;

import React from "react";
import { connect } from "react-redux";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import "./BigServiceCard.scss";
import { addLike, addCompare } from "../../redux/actions/appActions";

import useBuyService from "./../../hooks/useBuyService";

const BigServiceCard = (props) => {
  const lan = useSelector((state) => state.app.language);
  const item = props.item;

  const [ButtonOpeningTimeTable] = useBuyService(item);

  return (
    <div className="service-card big-service-card">
      <div className="product__info">
        <div className="product__info-card">
          <div className="product__info-body">
            {/*             <div className="product__badge tag-badge tag-badge--sale">
              Сервис
            </div> */}
            <div className="d-flex justify-content-between">
              <h4>{item.name}</h4>
              <h3>
                {item.price} {lan.sum}
              </h3>
            </div>

            <Divider />

            <div className="product__actions">
              <div className="product__actions-item">
                <button
                  type="button"
                  className="product-card__action product-card__action--wishlist"
                  aria-label="В избранное"
                  onClick={() => {
                    props.addLike(item);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={
                      props.likeServices.find(
                        (service) =>
                          service.id === item.id
                      )
                        ? "red !important"
                        : "currentColor"
                    }
                  >
                    <path
                      fill={
                        props.likeServices.find(
                          (service) => service.id === item.id
                        )
                          ? "red"
                          : "currentColor"
                      }
                      d="M13.9 8.4l-5.4 5.4c-.3.3-.7.3-1 0L2.1 8.4C.6 6.9.6 4.6 2.1 3.1 2.8 2.4 3.8 2 4.8 2s1.9.4 2.6 1.1l.6.6.6-.6c.7-.7 1.7-1.1 2.7-1.1 1 0 1.9.4 2.6 1.1 1.5 1.5 1.5 3.8 0 5.3z"
                    ></path>
                  </svg>
                </button>
              </div>
              <div className="product__actions-item">
                <button
                  type="button"
                  className="product-card__action product-card__action--wishlist"
                  aria-label="В избранное"
                  onClick={() => props.addCompare(item)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill={
                      props.compareServices.find(
                        (service) => service.id === item.id
                      )
                        ? "red"
                        : "currentColor"
                    }
                  >
                    <path
                      fill={
                        props.compareServices.find(
                          (service) => service.id === item.id
                        )
                          ? "red"
                          : "currentColor"
                      }
                      d="M9 15H7c-.6 0-1-.4-1-1V2c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v12c0 .6-.4 1-1 1zM1 9h2c.6 0 1 .4 1 1v4c0 .6-.4 1-1 1H1c-.6 0-1-.4-1-1v-4c0-.6.4-1 1-1zM15 5h-2c-.6 0-1 .4-1 1v8c0 .6.4 1 1 1h2c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1z"
                    ></path>
                  </svg>
                </button>
              </div>
              {ButtonOpeningTimeTable}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    compareServices: state.app.compareServices,
    likeServices: state.app.likeServices,
    totalPrice: state.app.totalPrice,
    basketCallback: state.app.basketCallback,
  };
};

export default connect(mapStateToProps, {
  addLike,
  addCompare,
})(BigServiceCard);

  import React from "react";
  import { connect } from "react-redux";
  import AccountLayout from "../../components/Layouts/AccountLayout";
  import UserProfileForm from "../../components/Shared/UserProfileForm";

  const AccountEditPage = (props) => {
    const lan = props.lan;
    return (
      <AccountLayout>
        <div className="card">
          <div className="card-header" style={{ backgroundColor: "white" }}>
            <h4>{lan.editPassword}</h4>
          </div>
          <div className="card-divider"></div>
          <div className="card-body">
            <UserProfileForm isVisible={true} />
          </div>
        </div>
      </AccountLayout>
    );
  };

  const mapStateToProps = (state) => {
    return {
      lan: state.app.language,
    };
  };

  export default connect(mapStateToProps)(AccountEditPage);

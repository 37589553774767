import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./ConvenienceCard2.scss";

const ConvenienceCard2 = (props) => {
  return (
    <div className="convenienceCard2 d-flex">
      <div className="convenience__img" style={{ marginRight: "20px" }}>
        <LazyLoadImage
          style={{ width: "80px", height: "80px" }}
          src={props.img}
          alt={props.name}
        />
      </div>
      <div className="convenience__content">
        <h4>{props.name}</h4>
        <p style={{ marginTop: "10px" }}>{props.desc && props.desc}</p>
      </div>
    </div>
  );
};

export default ConvenienceCard2;

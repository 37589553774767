import React from "react";
import { connect } from "react-redux";
import useFetch from "../hooks/useFetch";
import "../style/AboutUs.scss";

const AboutUsPage = (props) => {
  const lan = props.lan;
  const [data] = useFetch("get-about-info");
  return (
    <div className="aboutUs">
      <div className="about__body">
        <div className="about__image">
          <div
            className="about__image-bg"
            style={{ backgroundImage: "url(./images/hospital.png)" }}
          ></div>
          <div className="decor decor--type--bottom about__image-decor">
            <div className="decor__body">
              <div className="decor__start"></div>
              <div className="decor__end"></div>
              <div className="decor__center"></div>
            </div>
          </div>
        </div>
        <div className="about__card">
          <div className="about__card-title">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>{lan.aboutMenu}</font>
            </font>
          </div>
          <div className="about__card-text">
            <font style={{ verticalAlign: "inherit" }}>
              <font
                style={{ verticalAlign: "inherit" }}
                dangerouslySetInnerHTML={{ __html: data.about_text }}
              />
            </font>
          </div>
          <div className="about__card-author">
            <font style={{ verticalAlign: "inherit" }}>
              <font style={{ verticalAlign: "inherit" }}>
                {lan.directorFIO}
              </font>
            </font>
          </div>
          {/* <div className="about__card-signature">
            <img alt="" width="160" height="55" src="/images/hospital.png" />
          </div> */}
        </div>
        <div className="about__indicators">
          <div className="about__indicators-body">
            <div className="about__indicators-item">
              <div className="about__indicators-item-value">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {data.count_clinics}
                  </font>
                </font>
              </div>
              <div className="about__indicators-item-title">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {lan.allHosp}
                  </font>
                </font>
              </div>
            </div>
            <div className="about__indicators-item">
              <div className="about__indicators-item-value">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {data.count_doctors}
                  </font>
                </font>
              </div>
              <div className="about__indicators-item-title">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {lan.allDoctors}
                  </font>
                </font>
              </div>
            </div>
            <div className="about__indicators-item">
              <div className="about__indicators-item-value">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {data.count_clients}
                  </font>
                </font>
              </div>
              <div className="about__indicators-item-title">
                <font style={{ verticalAlign: "inherit" }}>
                  <font style={{ verticalAlign: "inherit" }}>
                    {lan.allClients}
                  </font>
                </font>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(AboutUsPage);

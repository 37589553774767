import React from "react";
import { FcNext } from "react-icons/fc";

const SampleNextArrow = (props) => {
  const { style, onClick } = props;
  return (
    <div
      className="next-arrow"
      style={{
        ...style,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "30px",
        height: "30px",
        background: "white",
        boxShadow:
          "0 1px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 3px -1px rgba(0, 0, 0,0.2)",
        position: "absolute",
        zIndex: 2,
        top: "calc(50% - 15px)",
        right: "-10px",
        cursor: "pointer",
        color: "black",
      }}
      onClick={onClick}
    >
      <FcNext />
    </div>
  );
};

export default SampleNextArrow;

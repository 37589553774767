import React from "react";
import { Link } from "react-router-dom";
import "./CityCard.scss";
import { RiHospitalLine } from "react-icons/ri";
import { connect } from "react-redux";

const CityCard = (props) => {
  const lan = props.lan;
  return (
    <div className="cityCard">
      <div className="textbox-4_module__2gJjK">
        <Link to="/clinics">
          <div className="textbox-4_background__3bSqa">
            <div className="wil-overlay"></div>
            <div
              className="textbox-4_img__2_DKb bg-cover"
              data-info="[]"
              style={{ backgroundImage: "url(" + props.img + ")" }}
            >
              <img className="" src={props.img} alt="" />
            </div>
          </div>
          <div className="textbox-4_top__1919H">
            <i className="la la-edit color-primary">
              <RiHospitalLine />
            </i>
            <span>
              {props.clinicsNumber} {lan.clinics.toLowerCase()}{" "}
            </span>
          </div>
          <div className="textbox-4_content__1B-wJ">
            <h3 className="textbox-4_title__pVQr7">{props.name}</h3>
            <span className="btn btn-primary btn-block btn-lg btn-round">
              {lan.more}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(CityCard);

import React from "react";
import { NavLink } from "react-router-dom";
import "./DirectionTop.scss";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { useSelector } from 'react-redux';

const DirectionTop = (props) => {
  const breadcrumb_urls = useBreadcrumbs();
  const lan = useSelector((state) => state.app.language);
  const breadcrumbs = [lan.mainpage, ...props.breadcrumbs];
  return (
    <div className="container-lg px-lg-0 position-relative">
      <div className="directionTop position-absolute">
        {breadcrumb_urls.map(({ match, breadcrumb }, index) => (
          <span key={match.url}>
            <NavLink to={match.url}>{breadcrumbs[index]}</NavLink>
          </span>
        ))}
      </div>
    </div>
  );
};

export default DirectionTop;

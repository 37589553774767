import React, { useState } from "react";
import { Col, Empty, Pagination, Row, Select } from "antd";
import PackageCard from "../components/Cards/PackageCard";
import usePaginationFetch from "../hooks/usePaginationFetch";
import CommonRadioFilter from "../components/Filters/CommonRadioFilter";
import FilterLayout from "../components/Filters/FilterLayout";
import FilterItem from "../components/Filters/FilterItem";
import { useSelector } from "react-redux";
import useFetch from "../hooks/useFetch";
import { PGNTN_LIMIT } from "../tools/constants";
import SmallLoading from "../components/Shared/SmallLoading";

const PackagesPage = () => {
  const lan = useSelector((state) => state.app.language);
  const [categories] = useFetch("service-packet-category?page=0&size=30");
  const [url, setUrl] = useState("service-packet");
  const [packages, page, totalElements, loading, handlePaginationChange] =
    usePaginationFetch(url, [url]);
  const handleCategory = (e) => {
    setUrl(`service-packet/service-packet-category/${e.target.value}`);
  };
  return (
    <div className="top-bottom">
      <div className="container-lg px-lg-0">
        <Row gutter={[12, 12]}>
          <Col lg={6} span={24}>
            <FilterLayout>
              <FilterItem title={lan.categories}>
                <CommonRadioFilter
                  handleValue={handleCategory}
                  data={categories.map((item) => {
                    return { id: item.categoryId, name: item.name };
                  })}
                />
              </FilterItem>
            </FilterLayout>
          </Col>
          <Col lg={18} span={24}>
            <div className="sort-card">
              <div className="view-options__legend">
                <h4 className="d-flex align-items-center">
                  {loading ? <SmallLoading /> : <span>{totalElements}</span>}
                  <span>
                    {" " + lan.amount + " " + lan.packages.toLowerCase()}
                  </span>
                </h4>
              </div>
              <div className="sort-selections d-flex justify-content-between flex-sm-row flex-column">
                <div className="view-options__select d-none d-lg-block">
                  <label htmlFor="view-option-sort">{lan.sorting}:</label>
                  <Select
                    style={{ width: 200 }}
                    placeholder={lan.sortClinics}
                    defaultValue=""
                  >
                    <Select.Option value="">{lan.all}</Select.Option>
                    <Select.Option value="1">{lan.mostfamous}</Select.Option>
                    <Select.Option value="2">{lan.increasename}</Select.Option>
                    <Select.Option value="3">{lan.decreasename}</Select.Option>
                  </Select>
                </div>
              </div>
            </div>
            {packages.length !== 0 ? (
              <Row gutter={[12, 12]}>
                {packages.map((item, index) => (
                  <Col className="" key={index} lg={8} sm={12} span={24}>
                    <PackageCard {...item} />
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={lan.noData}
              />
            )}
            <Pagination
              showSizeChanger={false}
              current={page}
              total={totalElements}
              pageSize={PGNTN_LIMIT}
              onChange={handlePaginationChange}
              showTotal={false}
              className={totalElements === 0 ? `d-none` : "mt-3"}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PackagesPage;

import React from "react";
import { NavLink, Link } from "react-router-dom";
import { connect } from "react-redux";
import { MdLibraryBooks, MdContactPhone } from "react-icons/md";
import { RiQuestionAnswerLine } from "react-icons/ri";
import { GiNurseMale } from "react-icons/gi";
import { FcAbout } from "react-icons/fc";
import { AiOutlineFileSearch } from "react-icons/ai";
// import { GrServices } from "react-icons/gr";
import { RiHospitalLine, RiVirusLine } from "react-icons/ri";
import { updateState, changeLang } from "../../../redux/actions/appActions";
import { FcLike } from "react-icons/fc";
import { RiScales3Fill } from "react-icons/ri";
import "./ToggleMenu.scss";

const ToggleMenu = (props) => {
  const lan = props.lan;
  // useEffect(() => {});
  return (
    <div className="toggleMenu">
      <div
        className="left-menu"
        style={{ left: props.openMenuModal ? "0" : "-300px" }}
      >
        <div className="second">
          {/*           <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/cities"
            >
              <FaCity />
              <span>{lan.regions}</span>
            </NavLink>
          </div> */}
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/clinics"
            >
              <RiHospitalLine />
              <span>{lan.clinics}</span>
            </NavLink>
          </div>
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/doctors"
            >
              <GiNurseMale />
              <span>{lan.doctors}</span>
            </NavLink>
          </div>
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/illness"
            >
              <RiVirusLine />
              <span>{lan.illness}</span>
            </NavLink>
          </div>
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/symptoms"
            >
              <AiOutlineFileSearch />
              <span>{lan.symptoms}</span>
            </NavLink>
          </div>
          {/* <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/packages"
            >
              <GrServices />
              <span>{lan.packages}</span>
            </NavLink>
          </div> */}
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/news"
            >
              <MdLibraryBooks />
              <span>{lan.news}</span>
            </NavLink>
          </div>
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/contactus"
            >
              <MdContactPhone />
              <span>{lan.contactMenu}</span>
            </NavLink>
          </div>
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/aboutus"
            >
              <FcAbout />
              <span>{lan.aboutMenu}</span>
            </NavLink>
          </div>
          <div>
            <NavLink
              activeClassName="active"
              onClick={() => props.updateState({ openMenuModal: false })}
              to="/quesans"
            >
              <RiQuestionAnswerLine />
              <span>{lan.help}</span>
            </NavLink>
          </div>
        </div>
        <div className="d-flex">
          <div
            className="indicator"
            onClick={() => props.updateState({ openMenuModal: false })}
          >
            <Link to="/like" className="indicator__button">
              <span className="indicator__icon">
                <FcLike />
                <span className="indicator__counter">
                  {props.likeServicesNumber}
                </span>
              </span>
            </Link>
          </div>
          <div
            className="indicator"
            onClick={() => props.updateState({ openMenuModal: false })}
          >
            <Link to="/compare" className="indicator__button">
              <span className="indicator__icon">
                <RiScales3Fill />
                <span className="indicator__counter">
                  {props.compareServicesNumber}
                </span>
              </span>
            </Link>
          </div>
        </div>
        <div className="third mt-1 d-inline-block">
          <div
            className="d-flex justify-content-center"
            style={{ color: "white", backgroundColor: "black" }}
          >
            <button
              className="topbar__menu-item language-box d-flex align-items-center"
              type="button"
              style={{ color: "white" }}
              onClick={() => {
                props.changeLang("uz");
              }}
            >
              <img
                alt="Uzbek"
                width="24"
                height="15"
                src="/images/uz-lan.png"
              />
              <span>Uz</span>
            </button>
            <button
              className="topbar__menu-item language-box d-flex align-items-center"
              type="button"
              style={{ color: "white" }}
              onClick={() => {
                props.changeLang("ru");
              }}
            >
              <img alt="Russian" src="/images/rus-lan.png" />
              <span>Ру</span>
            </button>
          </div>
          <div className="d-flex flex-column align-items-center mt-3">
            <a href="tel: +998993807738">+998993807738</a>
          </div>
        </div>
      </div>
      <div
        onClick={() => props.updateState({ openMenuModal: false })}
        className="modal-overlay"
        style={{
          zIndex: props.openMenuModal && "10",
          opacity: props.openMenuModal ? "0.5" : "0",
        }}
      ></div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    openMenuModal: state.app.openMenuModal,
    lan: state.app.language,
    likeServicesNumber: state.app.likeServicesNumber,
    compareServicesNumber: state.app.compareServicesNumber,
  };
};

export default connect(mapStateToProps, { updateState, changeLang })(ToggleMenu);

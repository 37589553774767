import React from "react";
import Slider from "react-slick";
// import { FcNext, FcPrevious } from "react-icons/fc";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./LastNews.scss";
import HomeNewsCard from "../Cards/HomeNewsCard";
import useFetch from "../../hooks/useFetch";
import Loading from "../Shared/Loading";
import NoData from "../Shared/NoData";
import SampleNextArrow from "../Shared/SampleNextArrow";
import SamplePrevArrow from "../Shared/SamplePrevArrow";

const LastNews = (props) => {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplaySpeed: 2000,
    // className: "center",
    // centerMode: true,
    pauseOnHover: true,
    autoplay: true,
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const lan = useSelector((state) => state.app.language);

  const [lastnews, loading] = useFetch("get-article-list");
  return (
    <div>
      <div className="section-header__body">
        <h2 className="section-header__title">{lan.lastNews}</h2>
        <div className="section-header__spring"></div>
        <ul className="section-header__links">
          <li className="section-header__links-item">
            <Link className="section-header__links-link" to="/news">
              {lan.allNews}
            </Link>
          </li>
        </ul>
        <div className="section-header__divider"></div>
      </div>
      {loading ? (
        <Loading />
      ) : lastnews.length !== 0 ? (
        <div className="lastNews">
          <Slider {...settings}>
            {lastnews.map((news) => (
              <HomeNewsCard key={news.id} {...news} />
            ))}
          </Slider>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default LastNews;

// function SampleNextArrow(props) {
//   const { onClick } = props;
//   return (
//     <div className="next-arrow" onClick={onClick}>
//       <FcNext />
//     </div>
//   );
// }

// function SamplePrevArrow(props) {
//   const { onClick } = props;
//   return (
//     <div className="prev-arrow" onClick={onClick}>
//       <FcPrevious />
//     </div>
//   );
// }

import React, { useState } from "react";
import { Select } from "antd";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";

const { Option } = Select;

const SearchIllness = (props) => {
  const [search, setSearch] = useState("");
  const [searchedSicknesses] = useFetch(
    "sickness/sickness-list",
    { page: 1, search },
    [search]
  );
  function onSearch(value) {
    setSearch(value);
  }
  const lan = useSelector((state) => state.app.language);
  return (
    <div className="sort-card">
      <div className="row w-100 mx-0">
        <div className="col-12">
          <Select
            showSearch
            allowClear
            style={{ width: "100%" }}
            placeholder={`${lan.searchingSickness} ...`}
            optionFilterProp="children"
            onSearch={onSearch}
            onSelect={props.handleSicknessId}
            onClear={props.searchClear}
            // filterOption={(input, option) =>
            //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
          >
            {searchedSicknesses.map((item) => (
              <Option key={item.id} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </div>
      </div>
    </div>
  );
};

export default SearchIllness;

import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Statistic } from "antd";
import {
  deleteCart,
  getCartProducts,
  updateState,
  changeActiveCartTab,
} from "../redux/actions/appActions";
import { ORDER_EXPIRED_MINUT } from "../tools/constants";
import Title from "../components/Shared/Title";
import moment from "moment";
import { Tabs } from "antd";
import { BiCheck } from "react-icons/bi";
import NoData from "../components/Shared/NoData";
import "../style/CartPage.scss";
import { sendData } from "../server/requestFunctions";
import { isEmpty } from "../functions";
import Loading from "../components/Shared/Loading";

const { TabPane } = Tabs;

const { Countdown } = Statistic;

const CartPage = (props) => {
  const [selectedTimes, setSelectedTimes] = useState([]);
  const history = useHistory();
  const cartProducts = useSelector((state) => state.app.cartProducts);
  const approvedServices = useSelector((state) => state.app.approvedServices);
  const lan = useSelector((state) => state.app.language);
  const loading = useSelector((state) => state.app.loading);
  useEffect(() => {
    props.getCartProducts();
  }, []);
  const handleKey = (key) => {
    props.changeActiveCartTab(key);
  };
  const selectTime = (service, selected_date) => {
    let date = selected_date.slice(0, -3);
    let dataFinded = selectedTimes.find(
      (item) => item.basket_id === service.id
    );
    if (dataFinded) {
      if (dataFinded.service_time === date) {
        setSelectedTimes(() =>
          selectedTimes.filter(
            (item) => item.basket_id !== dataFinded.basket_id
          )
        );
      } else {
        setSelectedTimes(() =>
          selectedTimes.map((dt) =>
            dt.basket_id === service.id ? { ...dt, service_time: date } : dt
          )
        );
      }
    } else {
      setSelectedTimes([
        ...selectedTimes,
        {
          basket_id: service.id,
          service_id: service.service_id,
          doctor_id: service.doctor_id,
          service_time: date,
        },
      ]);
    }
  };
  const clinic_slug = (item) => item.image && item.image.clinic_id;
  const checkTime = (service, date) => {
    let finded = selectedTimes.find((dt) => dt.basket_id === service.id);
    return finded && finded.service_time === date.selected_date.slice(0, -3);
  };
  const sendServicesToStore = () => {
    sendData("order/set", { orders: selectedTimes }).then(() => {
      history.push("checkout");
    });
  };
  return (
    <div className="cartComp top-bottom">
      <div className="container-lg px-lg-0">
        <Title title={lan.procurementProcess} />
        <Tabs
          type="card"
          centered
          activeKey={props.activeCartTab}
          onChange={handleKey}
        >
          <TabPane tab={lan.reservedServices} key="1">
            {loading ? (
              <Loading />
            ) : isEmpty(cartProducts) ? (
              <div className="block-empty__body">
                <NoData title={lan.notReservedServices} />
                <div className="block-empty__action">
                  <Link className="btn btn-primary btn-sm" to="/">
                    {lan.toHome}
                  </Link>
                </div>
              </div>
            ) : (
              <div className="cart__table cart-table">
                <table className="cart-table__table">
                  <thead className="cart-table__head">
                    <tr className="cart-table__row">
                      <th className="cart-table__column cart-table__column--image">
                        {lan.img}
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        {lan.servic}
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        {lan.doctor}
                      </th>
                      <th className="cart-table__column cart-table__column--product">
                        {lan.clinic}
                      </th>
                      <th className="cart-table__column cart-table__column--time">
                        {lan.time}
                      </th>
                      <th className="cart-table__column cart-table__column--time">
                        {lan.time}
                      </th>
                      <th className="cart-table__column cart-table__column--price">
                        {lan.price} {lan.sum}
                      </th>
                      <th className="cart-table__column cart-table__column--remove">
                        <span className="sr-only">Remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="cart-table__body">
                    {cartProducts.map((item) => (
                      <tr className="cart-table__row" key={item.id}>
                        <td className="cart-table__column cart-table__column--image">
                          <div className="image image--type--product">
                            <Link
                              className="image__body"
                              to={`/clinics/${clinic_slug(item)}`}
                            >
                              <img
                                alt=""
                                className="image__tag"
                                src={item.image && item.image.image}
                              />
                            </Link>
                          </div>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <Link
                            className="cart-table__product-name"
                            to={`/services/${item.service.service_category_id}`}
                          >
                            {item.service.name}
                          </Link>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <Link
                            className="cart-table__product-name"
                            to={`/doctors/${item.doctor.user.id}`}
                          >
                            {item.doctor.user.fio}
                          </Link>
                        </td>
                        <td className="cart-table__column cart-table__column--product">
                          <Link
                            className="cart-table__product-name"
                            to={`/clinics/${clinic_slug(item)}`}
                          >
                            {item.clinic_name}
                          </Link>
                        </td>
                        <td
                          className="cart-table__column cart-table__column--time"
                          data-title={lan.time}
                        >
                          <div>
                            {item.dates.map((date) => (
                              <div
                                key={date.id}
                                className="timetablecard"
                                style={{
                                  font: "bold 12px/22px Arial",
                                  width: "62px",
                                }}
                              >
                                {date.selected_date.split(" ").map((el) => (
                                  <p key={el} className="p-0">
                                    {el}
                                  </p>
                                ))}
                              </div>
                            ))}
                          </div>
                        </td>
                        <td
                          className="cart-table__column cart-table__column--time"
                          data-title={lan.time}
                        >
                          <Countdown
                            format="mm:ss"
                            value={
                              moment(item.created_at).valueOf() +
                              ORDER_EXPIRED_MINUT * 60 * 1000
                            }
                          />
                        </td>
                        <td
                          className="cart-table__column cart-table__column--price"
                          data-title="Price"
                        >
                          {item.service.price} {lan.sum}
                        </td>
                        <td className="cart-table__column cart-table__column--remove">
                          <button
                            onClick={() => props.deleteCart(item.id)}
                            type="button"
                            className="cart-table__remove btn btn-sm btn-icon btn-muted"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="12"
                            >
                              <path d="M10.8 10.8c-.4.4-1 .4-1.4 0L6 7.4l-3.4 3.4c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L4.6 6 1.2 2.6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L6 4.6l3.4-3.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L7.4 6l3.4 3.4c.4.4.4 1 0 1.4z"></path>
                            </svg>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </TabPane>
          <TabPane tab={lan.approvedServices} key="2">
            {loading ? (
              <Loading />
            ) : isEmpty(approvedServices) ? (
              <div className="block-empty__body">
                <NoData title={lan.notApprovedServices} />
                <div className="block-empty__action">
                  <Link className="btn btn-primary btn-sm" to="/">
                    {lan.toHome}
                  </Link>
                </div>
              </div>
            ) : (
              <div className="cartPayment">
                <div className="cart__table cart-table">
                  <table className="cart-table__table">
                    <thead className="cart-table__head">
                      <tr className="cart-table__row">
                        <th className="cart-table__column cart-table__column--image">
                          {lan.img}
                        </th>
                        <th className="cart-table__column cart-table__column--product">
                          {lan.servic}
                        </th>
                        <th className="cart-table__column cart-table__column--product">
                          {lan.doctor}
                        </th>
                        <th className="cart-table__column cart-table__column--product">
                          {lan.clinic}
                        </th>
                        <th className="cart-table__column cart-table__column--time">
                          {lan.time}
                        </th>
                        <th className="cart-table__column cart-table__column--time">
                          {lan.time}
                        </th>
                        <th className="cart-table__column cart-table__column--price">
                          {lan.price} {lan.sum}
                        </th>
                        <th className="cart-table__column cart-table__column--remove">
                          <span className="sr-only">Remove</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="cart-table__body">
                      {approvedServices.map((item) => (
                        <tr className="cart-table__row" key={item.id}>
                          <td className="cart-table__column cart-table__column--image">
                            <div className="image image--type--product">
                              <Link
                                className="image__body"
                                to={`/clinics/${clinic_slug(item)}`}
                              >
                                <img
                                  alt=""
                                  className="image__tag"
                                  src={item.image && item.image.image}
                                />
                              </Link>
                            </div>
                          </td>
                          <td className="cart-table__column cart-table__column--product">
                            <Link
                              className="cart-table__product-name"
                              to={`/services/${item.service.service_category_id}`}
                            >
                              {item.service.name}
                            </Link>
                          </td>
                          <td className="cart-table__column cart-table__column--product">
                            <Link
                              className="cart-table__product-name"
                              to={`/doctors/${item.doctor.user.id}`}
                            >
                              {item.doctor.user.fio}
                            </Link>
                          </td>
                          <td className="cart-table__column cart-table__column--product">
                            <Link
                              className="cart-table__product-name"
                              to={`/clinics/${clinic_slug(item)}`}
                            >
                              {item.clinic_name}
                            </Link>
                          </td>
                          <td
                            className="cart-table__column cart-table__column--time"
                            data-title={lan.time}
                          >
                            <div>
                              {item.dates
                                .filter((date) => date.reception_check === 1)
                                .map((date) => (
                                  <div
                                    key={date.id}
                                    onClick={() =>
                                      selectTime(item, date.selected_date)
                                    }
                                    className="timetablecard"
                                    style={{
                                      font: "bold 12px/22px Arial",
                                      width: "62px",
                                    }}
                                  >
                                    {checkTime(item, date) && (
                                      <div>
                                        <BiCheck />
                                      </div>
                                    )}
                                    {date.selected_date.split(" ").map((el) => (
                                      <p key={el} className="p-0">
                                        {el}
                                      </p>
                                    ))}
                                  </div>
                                ))}
                            </div>
                          </td>
                          <td
                            className="cart-table__column cart-table__column--time"
                            data-title={lan.time}
                          >
                            <Countdown
                              format="mm:ss"
                              value={
                                moment(item.created_at).valueOf() +
                                ORDER_EXPIRED_MINUT * 60 * 1000
                              }
                            />
                          </td>
                          <td
                            className="cart-table__column cart-table__column--price"
                            data-title="Price"
                          >
                            {item.service.price} {lan.sum}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="cart__totals">
                  <div className="orderCard">
                    <div className="card">
                      <div className="card-body card-body--padding--2">
                        <button
                          onClick={sendServicesToStore}
                          disabled={isEmpty(selectedTimes)}
                          className="btn btn-primary btn-xl btn-block"
                        >
                          {lan.sending}
                        </button>
                        <Link
                          to="/checkout"
                          className="btn btn-primary btn-xl btn-block"
                        >
                          {lan.ordersSelected}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    totalPrice: state.app.totalPrice,
    activeCartTab: state.app.activeCartTab,
    selectedServicesForOrdered: state.app.selectedServicesForOrdered,
  };
};

export default connect(mapStateToProps, {
  deleteCart,
  getCartProducts,
  updateState,
  changeActiveCartTab,
})(CartPage);

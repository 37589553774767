import React from "react";
import AccountComp from "../../components/Layouts/AccountLayout";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import useFetch from "../../hooks/useFetch";

const AccountDashboardPage = (props) => {
  const lan = props.lan;
  const [data] = useFetch("user/profile");
  const user = data.user || {};
  return (
    <AccountComp>
      <Row gutter={[8, 8]} className="dashboard">
        <Col md={12} span={24} className="card">
          <div className="card-body profile-card__body">
            <div className="profile-card__avatar">
              <img alt="" src={user.avatar || "/images/avatar.png"} />
            </div>
            <div className="profile-card__name">
              {`${user.name ? user.name : ""} ${
                user.surname ? user.surname : ""
              }`}
            </div>
            <div className="profile-card__email">{user.email}</div>
            <div className="profile-card__edit">
              <Link className="btn btn-secondary btn-sm" to="/account/profile">
                {lan.editProfilTitle}
              </Link>
            </div>
          </div>
        </Col>
        <Col
          md={12}
          span={24}
          className="card address-card dashboard__address address-card--featured"
        >
          <div className="address-card__body">
            <div className="address-card__name">{lan.profile}</div>
            <div className="address-card__row">
              <div className="address-card__row-title">{lan.phoneNumber}</div>
              <div className="address-card__row-content">{user.phone}</div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">
                {lan.permanentAddress}
              </div>
              <div className="address-card__row-content">
                {user.permanentAddress}
              </div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">{lan.nationality}</div>
              <div className="address-card__row-content">
                {user.nationality}
              </div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">{lan.profession}</div>
              <div className="address-card__row-content">{user.profession}</div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">{lan.age}</div>
              <div className="address-card__row-content">{user.age}</div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">{lan.gender}</div>
              <div className="address-card__row-content">{user.gender}</div>
            </div>
          </div>
        </Col>
      </Row>
    </AccountComp>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(AccountDashboardPage);

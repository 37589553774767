import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import IllCard from "../components/Cards/IllCard";
import SearchSymptom from "../components/Search/SearchSymptom";
import FilterItem from "../components/Filters/FilterItem";
import FilterLayout from "../components/Filters/FilterLayout";
import CommonRadioFilter from "../components/Filters/CommonRadioFilter";
import useFetch from "../hooks/useFetch";
import "../style/IllnessComp.scss";
import CommonLayout from "./../components/Layouts/CommonLayout";
import { updateState } from "../redux/actions/appActions";
import InfiniteScrollCustom from "./../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from "../hooks/useFetchWithScrollPgtn";

const IllnessPage = () => {
  const lan = useSelector((state) => state.app.language);
  const search = useSelector((state) => state.app.search);
  const [categories] = useFetch("sickness/category-list");
  const [changes, setChanges] = useState(null);
  const [filterData, setFilterData] = useState({});
  const {
    data: illnesses,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("sickness/sickness-list", filterData, [changes]);
  const dispatch = useDispatch();

  const handleIllnessCategory = (e) => {
    setFilterData({ sickness_category_id: e.target.value });
    e.target.value ? setChanges(e.target.value) : setChanges(null);
  };
  useEffect(() => {
    if (search.length >= 3) {
      setFilterData({ ...filterData, search });
    } else {
      if (filterData && filterData.search) {
        let newFilterData = filterData;
        delete newFilterData.search;
        setFilterData({ ...newFilterData });
      }
    }
    setChanges(!changes);
  }, [search]);
  useEffect(() => {
    dispatch(updateState({ search: "" }));
  }, []);
  const handleSymptompId = (id) => {
    setFilterData({ symptom_id: id });
    setChanges(id);
  };
  const searchClear = () => {
    setChanges(null);
  };
  return (
    <CommonLayout searchTitle={lan.searchingSicknesses} isNotSearchComp={true}>
      <div className="illnessComp top-bottom">
        <div className="container-lg px-lg-0">
          <h2 className="block-header__title">{lan.illness}</h2>
          <div
            className="section-header__divider"
            style={{ marginBottom: "20px" }}
          ></div>
          <div className="row mx-0">
            <div className="col-lg-3 px-0">
              <FilterLayout>
                <FilterItem title={lan.categories}>
                  <CommonRadioFilter
                    handleValue={handleIllnessCategory}
                    data={categories}
                  />
                </FilterItem>
              </FilterLayout>
            </div>
            <div className="col-lg-9 pl-lg-3 px-0">
              <SearchSymptom
                handleSymptompId={handleSymptompId}
                searchClear={searchClear}
              />
              <div>
                <InfiniteScrollCustom
                  data={illnesses}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loading={loading}
                >
                  {illnesses.map((item) => (
                    <div key={item.id} className="mb-3">
                      <IllCard item={item} to={"/illness/" + item.id} />
                    </div>
                  ))}
                </InfiniteScrollCustom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CommonLayout>
  );
};

export default IllnessPage;

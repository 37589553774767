import React, { useState } from "react";
import ClinicCard from "../components/Cards/ClinicCard";
import ClinicsFilter from "../components/Filters/ClinicsFilter";
import { connect } from "react-redux";
import { Select } from "antd";
import useFetch from "../hooks/useFetch";
import SmallLoading from "../components/Shared/SmallLoading";
import { ButtonM } from "../components/Buttons/ButtonM";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Modal, ModalBody } from "reactstrap";
import SearchHospital from "../components/Search/SearchHospital";
import useLocation from "../hooks/useLocation";
import { API_IMAGE_URL } from "./../tools/constants";
import InfiniteScrollCustom from "./../components/Shared/InfiniteScrollCustom";
import "../style/ClinicsPage.scss";
import useFetchWithScrollPgtn from "./../hooks/useFetchWithScrollPgtn";

const { Option } = Select;

const ClinicsPage = (props) => {
  const id = props.selectedCity || localStorage.getItem("regionId");
  const [distanceValue, setDistanceValue] = useState(0);
  const [city, setCity] = useState("");
  const [changes, setChanges] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [districts] = useFetch(
    `basic-list/districts`,
    {
      region_id: id,
    },
    [id],
    callback
  );
  const [location] = useLocation([]);
  const [filterData, setFilterData] = useState({
    region: id,
  });
  function callback() {
    setChanges(!changes);
    setFilterData({ ...filterData, region: id });
  }

  const {
    data: clinics,
    total,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("clinic/list", filterData, [changes]);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const handleDistrict = (e) => {
    setCity(e.target.value);
    if (e.target.value) {
      setFilterData({ ...filterData, district: e.target.value });
    } else {
      delete filterData.district;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };

  const handleDistance = (dis) => {
    setDistanceValue(dis);
    if (dis) {
      setFilterData({
        ...filterData,
        maxDistance: dis * 1000,
        longitude: location[1],
        latitude: location[0],
      });
    } else {
      if (filterData.maxDistance) {
        delete filterData.maxDistance;
        delete filterData.longitude;
        delete filterData.latitude;
      }
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleSort = (value) => {
    if (value) {
      setFilterData({ ...filterData, sort: value });
    } else {
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const lan = props.lan;

  return (
    <div className="clinicsComp top-bottom">
      <div className="container-lg px-lg-0">
        <h2 className="block-header__title">{lan.clinics}</h2>
        <div
          className="section-header__divider"
          style={{ marginBottom: "20px" }}
        ></div>
        <div className="row mx-0">
          <div className="col-lg-3 px-0">
            <ClinicsFilter
              quantity={<ClinicsSort lan={lan} handleSort={handleSort} />}
              placeName={lan.districts}
              places={districts.map((item) => {
                return { id: item.id, name: item.name };
              })}
              handlePlace={handleDistrict}
              handleDistance={handleDistance}
              distanceValue={distanceValue}
              city={city}
              location={location}
            />
          </div>
          <div className="col-lg-9 pl-lg-3 px-0">
            <div className="sort-card">
              <div className="view-options__legend">
                <h4 className="d-flex align-items-center">
                  {loading ? <SmallLoading /> : <span>{total}</span>}
                  <span>
                    {" " + lan.amount + " " + lan.clinic.toLowerCase()}
                  </span>
                </h4>
              </div>
              <div className="sort-selections d-flex justify-content-between flex-sm-row flex-column">
                <div className="view-options__select d-none d-lg-block">
                  <label htmlFor="view-option-sort">{lan.sorting}:</label>
                  <ClinicsSort lan={lan} handleSort={handleSort} />
                </div>
              </div>
              <div className="hospitals-in-map">
                <img src="/images/background_map.jpg" alt="" />
                <ButtonM onClick={toggle}>{lan.onMap}</ButtonM>
              </div>
            </div>
            <div>
              <InfiniteScrollCustom
                data={clinics}
                next={fetchMoreData}
                hasMore={hasMore}
                loading={loading}
              >
                {clinics.map((item) => (
                  <ClinicCard
                    key={item.id}
                    {...item}
                    location={[item.location_y, item.location_x]}
                    userLocation={location}
                  />
                ))}
              </InfiniteScrollCustom>
            </div>
          </div>
        </div>
      </div>
      <Modal className="mapModal" isOpen={isOpen} toggle={toggle}>
        <ModalBody>
          <button className="map-modal-close">
            <AiOutlineCloseCircle onClick={toggle} />
          </button>
          <SearchHospital
            places={clinics.map((clinic) => {
              return {
                location: [clinic.latitude, clinic.longitude],
                id: clinic.clinicsId,
                name: clinic.name,
                rating: clinic.rate,
                img: clinic.imageUrls
                  ? API_IMAGE_URL + clinic.imageUrls[0]
                  : "/images/no-hospital.png",
                userLocation: location,
              };
            })}
            filter={
              <ClinicsFilter
                quantity={<ClinicsSort lan={lan} handleSort={handleSort} />}
                placeName={lan.districts}
                places={districts.map((item) => {
                  return { id: item.districtId, name: item.name };
                })}
                handlePlace={handleDistrict}
                handleDistance={handleDistance}
                distanceValue={distanceValue}
                city={city}
                location={location}
              />
            }
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
    selectedCity: state.app.selectedCity,
  };
};

export default connect(mapStateToProps)(ClinicsPage);

const ClinicsSort = ({ lan, handleSort }) => {
  return (
    <Select
      style={{ width: 200 }}
      placeholder={lan.sortClinics}
      defaultValue="ALL"
      onChange={(e) => handleSort(e)}
    >
      <Option value="ALL">{lan.all}</Option>
      <Option value="RATE">{lan.mostfamous}</Option>
      <Option value="NAME_ASC">{lan.increasename}</Option>
      <Option value="NAME_DESC">{lan.decreasename}</Option>
      <Option value="PRICE_ASC">{lan.increaseprice}</Option>
      <Option value="PRICE_DESC">{lan.decreaseprice}</Option>
      <Option value="DIST_ASC">{lan.increaselocation}</Option>
      <Option value="DIST_DESC">{lan.decreaselocation}</Option>
    </Select>
  );
};

import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { lanProp } from "../../../redux/reducers/appReducer";
import List, { Item } from "./List";

const styles = StyleSheet.create({
  footer: {
    marginTop: 15,
  },
  detailContainer: {
    flexDirection: "row",
  },
  title: {
    textAlign: "center",
    marginBottom: 15,
    fontSize: 20,
  },
});

const Footer = () => {
  const lan = lanProp();

  return (
    <View style={styles.footer}>
      <Text style={styles.title}>Quyidagilarga amal qiling !!!</Text>
      <List>
        {lan.medpayOrderWarning.map((detail, i) => (
          <Item key={i} style={styles.detailContainer}>
            {detail}
          </Item>
        ))}
      </List>
    </View>
  );
};

export default Footer;

import React from "react";
import "../../style/ClinicIllnessCategories.scss";
import CategoryCard from "../Cards/CategoryCard";
import { CategoriesData } from "../../data/CategoriesData";
import { withRouter } from "react-router-dom";

const ClinicIllnessCategories = (props) => {
  return (
    <div className="categories-list">
      <ul className="categories-list__body">
        {CategoriesData.map((item) => (
          <CategoryCard
            id={item.id}
            name={item.name}
            img={item.img}
            number={item.number}
            to={`/clinics/${props.match.params.id}/categories/${item.name}/illnesses`}
          />
        ))}
      </ul>
    </div>
  );
};

export default withRouter(ClinicIllnessCategories);

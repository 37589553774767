import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getLikeServices } from "../redux/actions/appActions";
import "../style/LikePage.scss";
import LikeCard from "../components/Cards/LikeCard";
import Title from "../components/Shared/Title";
import NoData from "../components/Shared/NoData";

const LikePage = () => {
  const lan = useSelector((state) => state.app.language);
  const likeServices = useSelector((state) => state.app.likeServices);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLikeServices());
  }, []);
  return (
    <div className="likeComp top-bottom">
      {likeServices.length === 0 ? (
        <div className="container-lg px-lg-0">
          <div className="block-empty__body">
            <h1 className="block-empty__title">{lan.gallery}</h1>
            <NoData />
            <div className="block-empty__action">
              <Link className="btn btn-primary btn-sm" to="/">
                {lan.toHome}
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-lg px-lg-0">
          <Title title={lan.gallery} />
          <div className="cart__table cart-table">
            <table className="cart-table__table">
              <thead className="cart-table__head">
                <tr className="cart-table__row">
                  <th className="cart-table__column cart-table__column--image">
                    {lan.img}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.servic}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.clinic}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.price}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.selectDoctorService}
                  </th>
                  <th className="cart-table__column cart-table__column--remove">
                    <span className="sr-only">Remove</span>
                  </th>
                </tr>
              </thead>
              <tbody className="cart-table__body">
                {likeServices.map((item) => (
                  <LikeCard item={item} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default LikePage;

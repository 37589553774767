import { useState, useEffect } from "react";
import { getData } from "../server/requestFunctions";

const useFetchWithScrollPgtn = (url, query, changes) => {
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(2);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  useEffect(
    () => {
      setLoading(true);
      getData(url, { ...query, page: 1 })
        .then((res) => {
          let resData = res.data.data || res.data;
          setData(resData);
          setTotal(res.data.total);
          if (resData.length === res.data.total) {
            setHasMore(false);
          } else {
            setHasMore(true);
            setPage(2);
          }
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    changes ? [...changes] : []
  );

  const fetchMoreData = () => {
    getData(url, { ...query, page })
      .then((res) => {
        const newData = [...data, ...res.data.data];
        setPage((page) => page + 1);
        setData(newData);
        if (newData.length >= total && total) {
          setHasMore(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, total, hasMore, loading, error, fetchMoreData };
};

export default useFetchWithScrollPgtn;

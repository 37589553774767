import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { lanProp } from "../../../redux/reducers/appReducer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    margin: 5,
  },
  name: {
    fontWeight: "600",
  },
  title: {
    marginRight: 15,
  },
});

const ServiceInformation = (props) => {
  const lan = lanProp();

  return (
    <View>
      <Row title={lan.clinic} value={props.clinic} />
      <Row title={lan.doctor} value={props.doctor} />
      <Row title={lan.servic} value={props.service} />
      <Row title={lan.price} value={props.price} />
      <Row title={lan.time} value={props.time} />
      <Row title={lan.code} value={props.promo_code} />
    </View>
  );
};

const Row = ({ title, value }) => {
  return (
    <View style={styles.row}>
      <Text style={styles.title}>{title}: </Text>
      <Text style={styles.name}>{value}</Text>
    </View>
  );
};

export default ServiceInformation;

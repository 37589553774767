export const CategoriesData = [
  {
    id: 1,
    img: "/images/Orthopedic.png",
    number: 12,
    name: "Онкология",
  },
  {
    id: 2,
    img: "/images/Cardiologist.png",
    number: 12,
    name: "Ортопедия",
  },
  {
    id: 3,
    img: "/images/Orthopedic.png",
    number: 12,
    name: "Акушерство и гинекология",
  },
  {
    id: 4,
    img: "/images/neurology.png",
    number: 12,
    name: "Урология",
  },
  {
    id: 5,
    img: "/images/urology.png",
    number: 12,
    name: "Стоматологическое лечение",
  },
  {
    id: 6,
    img: "/images/Orthopedic.png",
    number: 12,
    name: "Психиатрия",
  },
  {
    id: 7,
    img: "/images/urology.png",
    number: 12,
    name: "Психиатрия",
  },
  {
    id: 8,
    img: "/images/Cardiologist.png",
    number: 12,
    name: "Психиатрия",
  },
  {
    id: 9,
    img: "/images/neurology.png",
    number: 12,
    name: "Онкология",
  },
  {
    id: 10,
    img: "/images/urology.png",
    number: 12,
    name: "Ортопедия",
  },
  {
    id: 11,
    img: "/images/Orthopedic.png",
    number: 12,
    name: "Акушерство и гинекология",
  },
  {
    id: 12,
    img: "/images/neurology.png",
    number: 12,
    name: "Урология",
  },
];

import React from "react";
import Loading from "./Loading";
import NoData from "./NoData";
import InfiniteScroll from "react-infinite-scroll-component";

const InfiniteScrollCustom = ({ data, loading, hasMore, next, children }) => {
  return (
    <>
      {loading ? (
        <Loading />
      ) : data.length !== 0 ? (
        <InfiniteScroll
          dataLength={data.length}
          next={next}
          hasMore={hasMore}
          loader={[<Loading key={0} />]}
          endMessage=""
        >
          {children}
        </InfiniteScroll>
      ) : (
        <NoData />
      )}
    </>
  );
};

export default InfiniteScrollCustom;

import React from "react";
import { Radio, Space } from "antd";
import { useSelector } from "react-redux";

const CommonRadioFilter = (props) => {
  const lan = useSelector((state) => state.app.language);
  const atr = {
    onChange: props.handleValue,
    value: props.value || "",
  };
  if (props.value === undefined) {
    delete atr.value;
  }
  return (
    <Radio.Group {...atr} defaultValue="">
      <Space direction="vertical">
        <Radio value="">{lan.all}</Radio>
        {props.data &&
          props.data.map((item) => (
            <Radio value={item.id} key={item.id}>
              {item.name}
            </Radio>
          ))}
      </Space>
    </Radio.Group>
  );
};

export default CommonRadioFilter;

import {
  REGISTER_SMS,
  RETURN_CODE_DATE,
  MEDPAY_USER_USERNAME,
} from "./../tools/constants";

export const removeRegisterLocalStorageData = () => {
  localStorage.removeItem(REGISTER_SMS);
  localStorage.removeItem(MEDPAY_USER_USERNAME);
  localStorage.removeItem(RETURN_CODE_DATE);
};

export const removePasswordResetLocalStorageData = () => {
  localStorage.removeItem(REGISTER_SMS);
  localStorage.removeItem(MEDPAY_USER_USERNAME);
};

import React from "react";
import { Tabs, Card } from "antd";
import { LazyLoadImage } from "react-lazy-load-image-component";
import useFetch from "../hooks/useFetch";
import "../style/SpecificDoctorPage.scss";
import TimetableCard from "../components/Cards/TimetableCard";
import DirectionTop from "../components/Shared/DirectionTop";
import { useSelector } from "react-redux";
import CustomComment from "./../components/Shared/CustomComment";
import useDoctorService from "./../hooks/useDoctorService";
import { SpecRow, SpecSection } from "../components/Shared/Spec";
import { isEmpty, makingTimetable } from "../functions";
import NoData from "../components/Shared/NoData";

const { TabPane } = Tabs;

const SpecificDoctorPage = (props) => {
  const id = props.match.params.id;
  const lan = useSelector((state) => state.app.language);
  const [doctor] = useFetch(`doctor/show/${id}`);
  const [ApplyingButton] = useDoctorService({
    id: doctor.doctor && doctor.doctor.id,
    name: doctor.user && doctor.user.fio,
    clinic: doctor.doctor && doctor.doctor.clinic,
  });

  const timetable = doctor.time_table;
  const times =
    isEmpty(timetable) ||
    makingTimetable(
      timetable.interval,
      timetable.start_time,
      timetable.end_time
    );
  return (
    <div className="top-bottom specificDoctorPage">
      <DirectionTop
        breadcrumbs={[lan.doctors, doctor.user && doctor.user.fio]}
      />
      <div className="container-lg px-lg-0">
        <h3 className="block-header__title">
          {doctor.user && doctor.user.fio}
        </h3>
        <div
          className="section-header__divider"
          style={{ marginBottom: "20px" }}
        ></div>
        <div className="row mx-0">
          <div className="col-lg-4 col-12 px-0">
            <div className="clinic-card card card-body--padding--2">
              <div className="clinic-img mb-3">
                <div style={{ maxWidth: "100%", width: "100%" }}>
                  <LazyLoadImage
                    placeholderSrc="/images/doctor-avatar.jpg"
                    style={{ width: "100%" }}
                    alt="doctor"
                    effect="blur"
                    src={doctor.user && doctor.user.avatar}
                  />
                </div>
              </div>
              {ApplyingButton}
            </div>
          </div>
          <div className="col-lg-8 col-12 px-0 pl-lg-2 pr-lg-0">
            <div className="card">
              <Tabs defaultActiveKey="1" centered>
                <TabPane tab={lan.workingTime} key="1">
                  <div className="doctor-timetable">
                    {isEmpty(timetable) ? (
                      <NoData title={lan.noDoctorTimeTable} />
                    ) : (
                      timetable.weekdays.map((day, index) => (
                        <Card
                          key={index}
                          size="small"
                          title={day.weekday + " " + day.date}
                          style={{ width: "100%" }}
                        >
                          {times.map((time) => (
                            <TimetableCard
                              key={time}
                              time={time}
                              statusOrder={
                                day.busy_times.includes(time + ":00") ||
                                day.red_times.includes(time + ":00")
                                  ? "red"
                                  : day.yellow_times.includes(time + ":00")
                                  ? "yellow"
                                  : "white"
                              }
                            />
                          ))}
                        </Card>
                      ))
                    )}
                  </div>
                </TabPane>
                <TabPane tab={lan.characteristics} key="2">
                  <div className="spec">
                    {/* <div className="spec__disclaimer">{doctor.about}</div> */}
                    <SpecSection title={lan.maindata}>
                      <SpecRow
                        name={lan.fullname}
                        value={doctor.user && doctor.user.fio}
                      />
                      <SpecRow
                        name={lan.email}
                        value={doctor.user && doctor.user.email}
                      />
                      <SpecRow
                        name={lan.nationality}
                        value={doctor.user && doctor.user.nationality}
                      />
                      <SpecRow
                        name={lan.profession}
                        value={doctor.user && doctor.user.profession}
                      />
                      <SpecRow
                        name={lan.address}
                        value={doctor.user && doctor.user.address}
                      />
                      <SpecRow
                        name={lan.dateOfBirth}
                        value={(doctor.user && doctor.user.birthday) || "-"}
                      />
                      <SpecRow
                        name={lan.languagesknow}
                        value={
                          doctor.doctor &&
                          doctor.doctor.languages &&
                          doctor.doctor.languages
                            .map((lanObj) => lanObj.name)
                            .map((lan, index) => (
                              <span key={index}>{lan + " "}</span>
                            ))
                        }
                      />
                      <SpecRow
                        name={lan.speciality}
                        value={
                          doctor.doctor &&
                          doctor.doctor.specialty &&
                          doctor.doctor.specialty
                            .map((lanObj) => lanObj.name)
                            .map((lan, index) => (
                              <span key={index}>{lan + " "}</span>
                            ))
                        }
                      />
                      <SpecRow
                        name={lan.gender}
                        value={
                          doctor.user && doctor.user.gender === "male"
                            ? lan.male
                            : lan.female
                        }
                      />
                      <SpecRow
                        name={lan.level}
                        value={doctor.doctor && doctor.doctor.score}
                      />
                      <SpecRow
                        name={lan.address}
                        value={doctor.user && doctor.user.address}
                      />
                    </SpecSection>
                    <SpecSection title={lan.education}>
                      <SpecRow
                        name={lan.graduatedUniversity}
                        value={doctor.doctor && doctor.doctor.university}
                      />
                    </SpecSection>
                    <SpecSection title={lan.experience}>
                      <SpecRow
                        name={lan.experience}
                        value={
                          doctor.doctor && doctor.doctor.specialty_experience
                        }
                      />
                    </SpecSection>
                    <SpecRow
                      name={lan.diplom_series}
                      value={doctor.doctor && doctor.doctor.diplom_series}
                    />
                    <SpecRow
                      name={lan.scientific_works}
                      value={doctor.doctor && doctor.doctor.scientific_works}
                    />
                    <SpecRow
                      name={lan.professional_development}
                      value={
                        doctor.doctor && doctor.doctor.professional_development
                      }
                    />
                    <SpecRow
                      name={lan.academic_degree}
                      value={doctor.doctor && doctor.doctor.academic_degree}
                    />
                    <SpecRow
                      name={lan.training_courses}
                      value={doctor.doctor && doctor.doctor.training_courses}
                    />
                    <SpecRow
                      name={lan.previous_workplace}
                      value={doctor.doctor && doctor.doctor.previous_workplace}
                    />
                    <SpecRow
                      name={lan.achievements}
                      value={doctor.doctor && doctor.doctor.achievements}
                    />
                    <SpecRow
                      name={lan.treated_patients}
                      value={doctor.doctor && doctor.doctor.treated_patients}
                    />
                    <SpecRow
                      name={lan.category}
                      value={doctor.doctor && doctor.doctor.category}
                    />
                    <SpecRow
                      name={lan.used_medical_equipment}
                      value={
                        doctor.doctor && doctor.doctor.used_medical_equipment
                      }
                    />
                    <SpecRow
                      name={lan.treated_diseases}
                      value={doctor.doctor && doctor.doctor.treated_diseases}
                    />
                    <SpecRow
                      name={lan.methods_treatment}
                      value={doctor.doctor && doctor.doctor.methods_treatment}
                    />
                    <SpecRow
                      name={lan.to_whom_obey}
                      value={doctor.doctor && doctor.doctor.to_whom_obey}
                    />
                  </div>
                </TabPane>
                <TabPane tab={lan.comments} key="3">
                  <div className="clinic-comment">
                    <CustomComment
                      sendurl={
                        "doctor/comment-store/" +
                        (doctor.doctor && doctor.doctor.id)
                      }
                      url={"doctor/comments/" + id}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecificDoctorPage;

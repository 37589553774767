import { useState, useEffect } from "react";
import { getData } from "../server/requestFunctions";

const useFetch = (url, query, changes, callback) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);

  useEffect(
    () => {
      setLoading(true);
      setError(false);
      getData(url, query)
        .then((res) => {
          res.data.data ? setData(res.data.data) : setData(res.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
      callback && callback();
    },
    changes ? [...changes] : []
  );
  return [data, loading, error];
};

export default useFetch;

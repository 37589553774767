import React from "react";
import "./HospitalCard.scss";
import { useSelector } from "react-redux";
import { countDistance } from "../../functions/countDistance";

const HospitalCard = (props) => {
  const lan = useSelector((state) => state.app.language);
  return (
    <div className="hospitalCard d-flex">
      <div className="hospital-img">
        <img src={props.img} alt="" />
      </div>
      <div className="hos-content">
        <h3>{props.name}</h3>
        <div className="hospitalMark">
          <div className="hos-level">{props.score || 5}</div>
          <div>
            <p> {lan.distanceUs} </p>
            <span>
              {countDistance(props.userLocation, props.location) || 0} {lan.km}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HospitalCard;

import React, { useEffect } from "react";
import { FiFacebook, FiInstagram, FiYoutube } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { getGetFooterInfo, getSocialContactList } from "../../../redux/actions/appActions";
import "./Footer.scss";
import { isEmpty } from "../../../functions";

const Footer = () => {
  const lan = useSelector((state) => state.app.language);
  const footerInfo = useSelector((state) => state.app.footerInfo);
  const socialContactList = useSelector((state) => state.app.socialContactList);
  const dispatch = useDispatch();
  useEffect(() => {
    isEmpty(footerInfo) && dispatch(getGetFooterInfo());
    isEmpty(socialContactList) && dispatch(getSocialContactList());
  }, []);

  return (
    <div className="footer px-0">
      <div className="site-footer__widgets">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-4">
              <div className="footer-contacts">
                <h5 className="footer-contacts__title">{lan.connectUs}</h5>
                <address className="footer-contacts__contacts">
                  <dl>
                    <dt>{lan.phoneNumber}</dt>
                    <dd>{footerInfo.phone}</dd>
                  </dl>
                  <dl>
                    <dt>{lan.workingTime}</dt>
                    <dd>{footerInfo.working_time}</dd>
                  </dl>
                  <dl>
                    <dt>{lan.ourPos}</dt>
                    <dd>{footerInfo.address}</dd>
                  </dl>
                  <dl>
                    <dt>{lan.email}</dt>
                    <dd>{footerInfo.email}</dd>
                  </dl>
                </address>
              </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
              <div className="footer-links">
                <h5 className="footer-links__title">{lan.inf}</h5>
                <ul className="footer-links__list">
                  <li className="footer-links__item">
                    <Link className="footer-links__link" to="/aboutus">
                      {lan.aboutMenu}
                    </Link>
                  </li>
                  <li className="footer-links__item">
                    <Link className="footer-links__link" to="/contactus">
                      {lan.contactMenu}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-6 col-md-3 col-xl-2">
              <div className="footer-links">
                <h5 className="footer-links__title">{lan.schet}</h5>
                <ul className="footer-links__list">
                  <li className="footer-links__item">
                    <Link className="footer-links__link" to="/checkout/id">
                      {lan.hisBooks}
                    </Link>
                  </li>
                  <li className="footer-links__item">
                    <Link className="footer-links__link" to="/like">
                      {lan.gallery}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="footer-newsletter">
                <h5 className="footer-newsletter__title">{lan.links}</h5>
                <div className="footer-newsletter__text footer-newsletter__text--social">
                  {lan.connectMass}
                </div>
                <div className="footer-newsletter__social-links social-links">
                  <ul className="social-links__list">
                    {socialContactList.map((item) => (
                      <li key={item.url} className="social-links__item">
                        <Link
                          to={item.url}
                          dangerouslySetInnerHTML={{ __html: item.icon }}
                        ></Link>
                      </li>
                    ))}
                    <li className="social-links__item social-links__item--facebook">
                      <Link to="/">
                        <FiFacebook />
                      </Link>
                    </li>
                    <li className="social-links__item social-links__item--youtube">
                      <Link to="/">
                        <FiYoutube />
                      </Link>
                    </li>
                    <li className="social-links__item social-links__item--instagram">
                      <Link to="/">
                        <FiInstagram />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*       <div className="site-footer__bottom">
        <div className="container">
          <div className="site-footer__bottom-row">
            <div className="site-footer__copyright">
              Питаться от
              <Link to="hctjs.org/" target="_blank" rel="noopener noreferrer">
                Zako Компания
              </Link>
            </div>
            <div className="site-footer__payments">
              <img alt="" src="/images/payments.jpg" />
              <img
                src="/images/visamastercard.png"
                style={{ background: "transparent", height: "24px" }}
                alt="fadfdsa"
              />
            </div>
          </div>
        </div>
      </div>
   */}{" "}
    </div>
  );
};

export default React.memo(Footer);

import AccountComp from "../../components/Layouts/AccountLayout";
import React, { useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Form, Input, Button } from "antd";
import { sendData } from "../../server/requestFunctions";

const AccountPasswordPage = (props) => {
  const lan = props.lan;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const onFinish = (values) => {
    setLoading(true);
    sendData('user/password-update', values)
      .then(() => {
        form.resetFields();
        toast.success(lan.changePasswordSuccessfully);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <AccountComp>
      <div className="card">
        <div className="card-header" style={{ backgroundColor: "white" }}>
          <h4>{lan.editProfilTitle}</h4>
        </div>
        <div className="card-divider"></div>
        <div className="card-body">
          <div className="row no-gutters">
            <Form
              name="changeUserPassword"
              form={form}
              onFinish={onFinish}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Form.Item
                label={lan.oldPassword}
                name="old_password"
                rules={[
                  {
                    required: true,
                    message: lan.notFilled,
                  },
                ]}
              >
                <Input.Password
                  disabled={loading}
                  placeholder={lan.oldPassword}
                />
              </Form.Item>
              <Form.Item
                label={lan.newPassword}
                name="password"
                rules={[
                  {
                    required: true,
                    message: lan.notFilled,
                  },
                ]}
              >
                <Input.Password
                  disabled={loading}
                  placeholder={lan.newPassword}
                />
              </Form.Item>
              <Form.Item
                name="password_confirmation"
                label={lan.repeatPassword}
                dependencies={["password"]}
                hasFeedback
                allowClear
                rules={[
                  {
                    required: true,
                    message: lan.enterConfirmPassword,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(lan.passwordMatchError));
                    },
                  }),
                ]}
              >
                <Input.Password
                  disabled={loading}
                  placeholder={lan.repeatPassword}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={loading}
                >
                  {lan.edit}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </AccountComp>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(AccountPasswordPage);

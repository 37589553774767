import React from "react";

const Title = (props) => {
  return (
    <div>
      <h3 className="block-header__title">{props.title}</h3>
      <div
        className="section-header__divider"
        style={{ marginBottom: "20px" }}
      ></div>
    </div>
  );
};

export default Title;

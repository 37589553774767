import React from "react";
import "./SmallLoading.scss";

const SmallLoading = () => {
  return (
    <div className="loadingio-spinner-spinner-gg8gszpwcji">
      <div className="ldio-5ue56llzgzb">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default SmallLoading;

import React from "react";
import { Link } from "react-router-dom";
import "./Advertisement.scss";
import { useSelector } from "react-redux";

const Advertisement = () => {
  const lan = useSelector((state) => state.app.language);
  return (
    <div className="advertisement pt-4">
      <div className="block-banners__list">
        <div
          data-aos="zoom-in-up"
          className="block-banners__item block-banners__item--style--one"
        >
          <span className="block-banners__item-image">
            <img alt="" className="reflect-rtl" src="/images/samarqandAd.jpg" />
          </span>
          <span className="block-banners__item-image block-banners__item-image--blur">
            <img alt="" className="reflect-rtl" src="/images/samarqandAd.jpg" />
          </span>
          <span className="block-banners__item-title">{lan.samarqand}</span>
          <span className="block-banners__item-details"></span>
          <Link
            to="/5/clinics"
            className="block-banners__item-button btn btn-primary btn-sm"
          >
            {lan.watchClinics}
          </Link>
        </div>
        <div
          // data-aos="fade-left"
          data-aos="zoom-in-up"
          className="block-banners__item block-banners__item--style--two"
          href="/themes/red"
        >
          <span className="block-banners__item-image">
            <img alt="" className="reflect-rtl" src="/images/tashkentAd.jpg" />
          </span>
          <span className="block-banners__item-image block-banners__item-image--blur">
            <img alt="" className="reflect-rtl" src="/images/tashkentAd.jpg" />
          </span>
          <span className="block-banners__item-title">{lan.tashkent}</span>
          <span className="block-banners__item-details"></span>
          <Link
            to="/10/clinics"
            className="block-banners__item-button btn btn-primary btn-sm"
          >
            {lan.watchClinics}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;

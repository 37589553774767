import {
  HttpRequestHubAuth,
  HttpRequestHubAuthRefresh,
  HttpRequestHubCommon,
} from "../HttpRequestHub";

export const loginUser = (data) => {
  const config = {
    method: "POST",
    url: `signin`,
    data,
  };
  return HttpRequestHubAuth(config);
};

export const refreshToken = (data) => {
  const config = {
    method: "POST",
    url: `refresh`,
    data,
  };
  return HttpRequestHubAuthRefresh(config);
};

export const registerUser = (data) => {
  const config = {
    method: "POST",
    url: `signup`,
    data,
  };
  return HttpRequestHubAuth(config);
};

export const recoveryUser = (data) => {
  const config = {
    method: "POST",
    url: `user/recovery`,
    data,
  };
  return HttpRequestHubAuth(config);
};

export const changeUserPassword = (data) => {
  const config = {
    method: "POST",
    url: `user-profile/password`,
    data,
  };
  return HttpRequestHubCommon(config);
};

export const confirmCode = (url) => {
  const config = {
    method: "GET",
    url,
  };
  return HttpRequestHubAuth(config);
};

import { MAX_NUMBER_SELECTING_TIME } from "../tools/constants";

export const RU = {
  menus: [
    "Регионы",
    "Клиники",
    "Новости",
    "Центр поодержки",
    "Врачи",
    "Медсправочник",
  ],
  reference: "Медсправочник",
  lanName: "Py",
  lan: "Язык",
  lanCat: ["Уз", "Pу"],
  aboutMenu: "О нас",
  allDoctors: "Всего доктора",
  allHosp: "Всего больниц",
  allClients: "Довольных клиентов",
  contactMenu: "Контакты",
  quote: "ЛУЧШИЙ САЙТ ДЛЯ ПАЦИЕНТОВ",
  currercyMenu: "Валюта",
  search: "Ручной поиск",
  currercyCat: [""],
  myAccount: "Личный кабинет",
  hello: "Привет",
  enterSys: "Вход в систему",
  loginEmPl: "customer@example.com",
  password: "Пароль",
  loginForget: "Забыли ?",
  enterButton: "Вход",
  register: "Регистрация",
  registerSuccessfully: "Ro'yxatdan muvaffaqqiyatli o'tdingiz !",
  basketFree: "Ваша корзина пуста!",
  passToSer: "Перейти в раздел Услуги",
  basket: "Корзина",
  searchTabs: ["Анализ", "Диагностика", "Лечение", "Профилактика"],
  menuPl: "Меню",
  subMenuPl: "Подменю",
  searchCom: "Поиск",
  staticsNum: ["записей сегодня", "врачей в базе", "отзывов", "клиник в базе"],
  editProfilTitle: "Изменить профиль",
  ordersHistory: "История заказов",
  editPassword: "Изменить пароль",
  logout: "Выход",
  email: "Email адрес",
  order: "Заказать",
  filter: "Фильтры",
  regions: "Регионы",
  clinics: "Все клиники",
  news: "Новости",
  help: "Центр поддержки",
  services: "Услуги",
  categories: "Категории",
  price: "Цена",
  distance: "Расстояние",
  roadPrice: "Цена на дорогу",
  roadAndServicePrice: "Входные и сервисные расходы",
  connect: "Запишитесь к врачу по телефону",
  navigation: "Навигация",
  profile: "Профиль",
  oldPassword: "Текущий пароль",
  newPassword: "Новый пароль",
  repeatPassword: "Повторите пароль",
  edit: "Изменить",
  name: "Имя",
  lname: "Фамилия",
  phoneNumber: "Номер телефона",
  save: "Сохранить",
  toHome: "На главную",
  gallery: "Избранное",
  img: "Рисунок",
  illness: "Болезнь",
  illnesses: "Болезни",
  servic: "Сервис",
  quantity: "Количество",
  totalPrice: "Общий",
  cartTotals: "Итоги корзины",
  total: "Подытог",
  sending: "Отправить",
  send: "Отправить",
  resend: "Отправить",
  freeCart: "Корзина пуста",
  freeCartDesc: "В корзине пока ничего нет",
  yourBooks: "Ваш заказ",
  payInf: "Платежные реквизиты",
  required: "Обязательное поле.",
  any: "Необязательный",
  orderNote: "Примечания к заказу",
  from: "От",
  to: "До",
  return: "Возвращающийся клиент ?",
  clickHere: "Нажмите здесь, чтобы войти",
  sendAdmin: "Отправить администратору",
  sentAdmin: "Отправлено администратору",
  connectUs: "Связаться с нами",
  ourPos: "Hаше место",
  workingTime: "Рабочее время",
  inf: "Информация",
  links: "Новостная рассылка",
  connectMass: "Следите за нами в социальных сетях",
  schet: "Мой счет",
  message: "Сообщение",
  sendMessage: "Отправить сообщение",
  comments: "Комментарии",
  onMap: "На карте",
  display: "Показано",
  sorting: "Сортировать",
  whatHelp: "Чем мы можем помочь?",
  helpPl: "Опишите ситуацию, чтобы мы понимали, как действовать дальше...",
  commonHos: "Общие клиники",
  lastNews: "Последние новости",
  more: "Подробние",
  distanceUs: "Pасстояние от тебя",
  clinic: "Клиника",
  from2: "из",
  sortOptions: [
    "По умолчанию Цена",
    "По возрастанию Цена",
    "Новые",
    "Популярные",
  ],
  km: "км",
  login: "Войти",
  remindMe: "Запомните меня ?",
  director: "Генеральный директор Дильшод Якубов Дамирович",
  sortIll: ["Имя (Я-А)", "Имя (А-Я)"],
  compare: "Сравнение товаров",
  doctors: "Врачи",
  doctor: "Доктор",
  consultation: "Записаться",
  noConsultation: "Нет записаться",
  symptoms: "Симптомы",
  time: "Время",
  medCart: "Медицинская карта",
  packages: "Пакеты",
  medData: "Медицинские данные",
  ordersStatus: "Статус заказов",
  bestIllnessCategories: "Категории болезней",
  allNews: "Все новости",
  watchClinics: "Посмотреть больницы",
  username: "Имя пользователя",
  enterConfirmPassword: "Пожалуйста, подтвердите свой пароль!",
  passwordMatchError: "Два введенных вами пароля не совпадают!",
  notFilled: "Не заполнено !!!",
  loginError: "Ошибка пароля или имени пользователя!",
  addCart: "В корзину",
  addCompare: "В сравнению",
  location: "Расположение клиники",
  youseeallclinics: "Вы видели все больницы!",
  amount: "",
  sortClinics: "Сортировка клиник",
  increasename: "Рост по имени",
  decreasename: "Отклонить по имени",
  increaseprice: "Увеличение цены",
  decreaseprice: "Снижение цены",
  increaselocation: "Рост на расстоянии",
  decreaselocation: "Уменьшение расстояния",
  mostfamous: "Самый популярный",
  all: "Все",
  noSymptoms: "Нет никаких симптомов",
  readMore: "Прочитайте больше",
  allmonths: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  byMade: "Создано",
  selectDoctorService: "Выберите услугу врача",
  selectServiceDoctor: "Выбрать врача для услуг",
  youseeallnews: "Вы видели все !",
  closing: "Закрывать",
  seeResults: "Посмотреть результаты",
  appointmentToDoctor: "Запись к врачу",
  questionsendsuccessfully: "Ваш вопрос успешно отправлен !",
  messagesendsuccessfully: "Ваше сообщение было отправлено успешно !",
  emailValidation: "Введите адрес электронной почты правильно !",
  messageTitle: "Заголовок сообщения",
  title: "Заголовок",
  description: "Oписание",
  characteristics: "Xарактеристики",
  technologies: "Технологии",
  map: "Карта",
  selectTime: "Выбор времени",
  back: "Назад",
  enterCode: "Введите код !",
  noTimeTable: "Нет времени !",
  noDoctorServices: "Услуги врача недоступны !",
  aboutPackage: "О пакете",
  age: "Возраст",
  gender: "Пол",
  permanentAddress: "Адрес постоянного места жительства",
  address: "Адрес",
  nationality: "Национальность",
  profession: "Профессия",
  userpicture: "Фото пользователя",
  biologicData: "Биографическая информация",
  placeOfBirth: "Место рождения",
  date: "Дата",
  dateOfBirth: "Дата рождения",
  numberOfChildrenInFamily: "Сколько детей в семье",
  peopleLivedWithIll: "С кем проживает",
  degree: "Образование",
  degreeOptions: [
    "Начальное",
    "Среднее",
    "Средне-специальное",
    "Высшее",
    "Полное",
    "Неполное",
  ],
  sexualHistory: "Половой анамнез",
  timeStartedSexualLife: "В каком возрасте началась половая жизнь",
  menarche: "Менархе",
  menstruation: "Характер менструации",
  menstruationOptions: [
    "Болезненно",
    "Безболезненно",
    "Своевременно",
    "C задержкой",
  ],
  pregnancy: "Беременность",
  numberOfAbortions: "Количество абортов",
  maritalStatus: "Семейное положение",
  marriedStatusIll: "Семейное положение пациента",
  married: "Замужем",
  single: "Не замужем",
  numberMarriage: "Количество браков",
  numberChildren: "Количество детей",
  laborHistory: "Трудовой анамнез",
  ageStartedWork: "В каком возрасте начал работать ?",
  characterAndconditionCurrentWork:
    "Условия и характер работы на сегоднешний день",
  disadvantagesOfProfession: "Вредные для здоровья стороны профессии",
  allPlacesWorked: "Перечислить все рабочие места",
  typeLabor: "Вид труда",
  mental: "Умственный",
  physical: "Физический",
  processLabor: "Образ деятельности",
  whileSitting: "Пассивный(сидячий)",
  whileStanding: "Активный(подчеркнуть)",
  conditionCurrentLabor: "Условия труда",
  low: "Низкая",
  middle: "Средняя",
  high: "Высокая",
  light: "Светло",
  middlelight: "Средней",
  dark: "Темно",
  underGround: "Под землей ",
  openAir: "На открытом воздухе",
  inRoom: "В помещении",
  harmfulHabits: "Вредные привычки",
  drinkingAlcogol: "Алкоголь",
  smoking: "Курение",
  drinkOfIll: "Употребление",
  bitterTea: "Крепкого чая",
  cofe: "Кофе",
  drugs: "Наркотиков",
  passedSickness: "Злоупотребление соленых и сладких продуктов",
  allergologicHistory: "Перенесенные заболевания",
  specificAllergens: "Конкретный аллерген",
  reactionToMedicine: "Аллергическая реакция на лекарственные препараты",
  allergySymptoms: "Симптомы аллергических реакций",
  epidemilogicalHistory: "Эпидемиологический анамнез",
  haveJaundice: "Перенесенный гепатит ?",
  receivedBloodTransfusion: "Переливание крови ?",
  haveContactWithInfectedPatients: "Контакт с инфекционными больными ?",
  withWhatInfectiousPatient: "С каким инфекционно больным",
  haveBeenAbroadOrDentist: "За последние 6 мес. Был ли за рубежом ?",
  countriesWent: "В каких странах побывал",
  hereditaryDiseasesParents: "Наследственные заболевания со стороны родителей",
  hereditaryPredisposition: "Генетическая предрасположенность",
  halthOfCloseRelatives:
    "Состояние здоровья близких родственников(братьев и сестер)",
  yes: "Да",
  no: "Нет",
  saveSuccessfully: "Успешно сохранено",
  skip: "Пропускать",
  sendingCodeToPhone: "Код был отправлен обратно на ваш номер телефона !",
  forgetPassword: "Забыл пароль ?",
  resetPassword: "Сброс пароля",
  gettingSms: "Получать смс",
  changePasswordSuccessfully: "Ваш пароль был успешно изменен!",
  medcartPricingOptions: "Размер цена",
  buy: "Купить",
  districts: "Районы",
  noServiceTimetable: "Нет расписания обслуживания !",
  noData: "Нет доступной информации !",
  selectGender: "Пожалуйста, выберите свой пол!",
  temperature: "Температура",
  lightness: "Освещение",
  fromWhatYear: "С какого года?",
  inWhatQuantity: "В каком количестве ?",
  carts: "Мои карты",
  cardName: "Название карты",
  cardNumber: "Номер карты",
  cardExpiry: "Продолжительность",
  timeScheduleOfService: "График обслуживания",
  maxNumberOfSelectingTime: `Вы можете выбрать максимум ${MAX_NUMBER_SELECTING_TIME} раза`,
  orderSendSuccessfully: "Ваш заказ отправлен в больницу",
  male: "Мужчина",
  female: "Женщина",
  MALE: "Мужчина",
  FEMALE: "Женщина",
  liveOrder: "Живая очередь",
  descLiveOrder: "Ты идешь в поликлинику в тот день и стоишь в живой очереди !",
  reservedServices: "Отправил",
  approvedServices: "Одобренный",
  sum: "сум",
  yourMedData: "Ваша медицинская карта",
  createdBySelf: "Создание пациента",
  createdByDoctor: "Создано врачом",
  payment: "Оплата",
  makePayment: "Производить платеж",
  compareServices: "Услуги для сравнения",
  delete: "Удалить",
  access: "Доступ",
  mainpage: "Главная",
  maindata: "Основная информация",
  fullname: "ФИО",
  languagesknow: "Языки, которые они знают",
  education: "Образование",
  experience: "Опыт",
  graduatedUniversity: "Окончил школу",
  numberOfDoctors: "Количество врачей",
  numberOfBeds: "Количество кроватей",
  totalTerritory: "Общая площадь территории",
  cancel: "Отмена",
  statuses: {
    created: "СОЗДАННЫЙ",
    active: "ОПЛАЧЕННЫЙ",
    progress: "ДОКТОР ПРИНЯЛ",
    canceled: "ОТМЕНЕНО",
    completed: "ЗАВЕРШЕННЫЙ",
  },
  samarqand: "Самарқанд",
  tashkent: "Тошкент",
  whatCanOurSiteGiveYou: "Что может дать вам наш сайт ?",
  procurementProcess: "Процесс закупок",
  notApprovedServices:
    "Услуги, одобренные больницей, в настоящее время вам недоступны.",
  notReservedServices: "Нет услуг, отправленных в больницу для подтверждения",
  existingApprovedServices: "У вас есть одобренные больницей услуги!",
  seeing: "Видеть",
  workExperience: "Опыт работы",
  year: "Год",
  searchingSymptom: "Поиск симптома",
  searchingSickness: "Поиск болезни",
  comment: "Комментарий",
  thanksForComment: "Спасибо за комментарий",
  searchingDoctors: "Поиск врачей",
  searchingClinics: "Поиск больниц",
  searchingServices: "Поиск услуг",
  searchingSymptoms: "Поиск симптомов",
  searchingSicknesses: "Поиск болезней",
  selectNumberOfMonths: "Выберите количество месяцев",
  thanksForOrder: "Спасибо за ваш заказ",
  weReceivedYourOrder: "Мы получили ваш заказ",
  numberOfOrder: "Номер заказа",
  typeOfPayment: "Способ оплаты",
  creditCard: "Кредитная карта",
  download: "Скачать",
  code: "Код",
  createdTime: "Время создания",
  enterUsername: "Введите имя пользователя!",
  enterPassword: "Введите пароль !",
  typeService: "Тип обслуживания",
  moreInformationAboutService: "Подробная информация об услуге",
  reallyDelete: "Вы действительно хотите выключить его?",
  enterCodeSendedToPhone: "Введите смс, пришедшую на ваш телефон",
  enterCodeSendedToThisPhone: "Введите код, отправленный на этот номер",
  enterCardData: "Введите данные вашей кредитной карты!",
  pleaseLogin: "Пожалуйста, войдите сначала!",
  addesFavouriteCart: "Добавлено в избранное!",
  addedFavouriteCart: "Sevimlilar bo'limiga qo'shilgan !",
  addesCompareCart: "Присоединился к разделу Сравнение!",
  dontAllowToLocation: "Адрес не разрешен",
  allowLocation: "Разрешить местоположение",
  enterPhoneOrUsername: "Номер телефона или имя пользователя",
  user: "Пользователь",
  allConveniencesYouCanWant:
    "Наш сайт старается создать все удобства, которые вы можете создать.",
  conveniences: [
    {
      name: "Многополосная поисковая система",
      desc: "Вы можете найти любую услугу через нашу поисковую систему",
      img: "images/conveniences/search.png",
    },
    {
      name: "Медицинская карта",
      desc: "Мы отслеживаем результаты каждой услуги, которую вы приобретаете на нашем сайте.",
      img: "images/conveniences/archive.png",
    },
    {
      name: "Скидки на оплату",
      desc: "При посещении нашего сайта для вас предусмотрены специальные скидки.",
      img: "images/conveniences/discount.png",
    },
    {
      name: "Определить близлежащие больницы",
      desc: "Вы можете найти больницы на любом расстоянии от нашего сайта",
      img: "images/conveniences/location.png",
    },
    {
      name: "Предварительное определение очереди",
      desc: "Вы сэкономите драгоценное время, предварительно организовав очередь!",
      img: "images/conveniences/timetable.png",
    },
    {
      name: "Система онлайн-платежей",
      desc: "Оплатить можно любой картой!",
      img: "images/conveniences/online-payment.png",
    },
    // {
    //   name: "Muddatli to'lov",
    //   desc: "",
    //   img: "images/conveniences/time-payment.png",
    // },
    // {
    //   name: "Chegirmali xizmatlar to'plami",
    //   desc: "",
    //   img: "images/conveniences/discount-packets.png",
    // },
  ],
  currentProfileSize: "Текущий размер профиля",
  commonProfileSize: "Общий размер профиля",
  downloadedFilesSize: "Размер загружаемых файлов",
  noUser: "Этот пользователь недоступен !",
  userExist: "Этот пользователь доступен !",
  free: "Бесплатно",
  errorUsernameOrPassword: "Ошибка логина или пароля!",
  noSale: "Не продается",
  enterValidUsername: "5-20 букв, без пробелов !",
  en: "Английский",
  ru: "Русский",
  uz: "Узбекский",
  level: "Уровень",
  category: "Категория",
  countAnalysis: "Количество анализов",
  countStaff: "Количество работников",
  treatedPatients: "Пролеченные пациенты",
  license: "Лицензия",
  officialDate: "Официальная дата",
  medDiagnosticTechnologies: "Диагностические технологии",
  medTreatmentTechnologies: "Медицинские технологии лечения",
  legalInformation: "Легальная информация",
  clientDiscount: "Скидка клиента",
  totalDiscount: "Общая скидка",
  optinal: "по желанию",
  internships: "Стажировки",
  diplom_series: "Дипломная серия",
  scientific_works: "Научные труды",
  professional_development: "Профессиональное развитие",
  academic_degree: "Ученая степень",
  training_courses: "Учебные курсы",
  previous_workplace: "Предыдущее место работы",
  achievements: "Достижения",
  treated_patients: "Пролеченные пациенты",
  used_medical_equipment: "Бывшее в употреблении медицинское оборудование",
  treated_diseases: "Леченные заболевания",
  to_whom_obey: "Кому подчиняться",
  methods_treatment: "Методы лечения",
  speciality: "Специальность",
  enterRightNumber: "Введите номер правильно",
  unauthorized: "Неавторизованный",
  ordersSelected: "Выбранные заказы",
  medpayOrder: "Заказ на сайте Medpay",
  medpayOrderWarning: [
    "Этот документ подтверждает, что вы действительно приобрели услугу, а код в документе поможет вам в процессе регистрации.",
    "Пожалуйста, не опаздывайте на службу. Если вы опаздываете, вы можете записаться на новый прием, отменив заказ, или поговорить с персоналом клиники. ",
    "Если у вас есть какие-либо вопросы, пожалуйста, не стесняйтесь обращаться к нам в колл-центр MedPay.uz",
  ],
  upload: "Загрузить",
  doPayment: "Оплата",
  noDoctorTimeTable: "График работы врача не сформирован",
};

import Cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import { TOKEN } from "../tools/constants";

export const deleteCookie = (name) => {
  Cookie.remove(name);
};

export const setCookie = (name, value) => {
  Cookie.set(name, value, { path: "/" });
};

// const checkCookieAvailable = (name) => {
//   console.log(name);
//   var dc = document.cookie;
//   console.log(dc);
//   var prefix = name + "=";
//   var begin = dc.indexOf("; " + prefix);
//   if (begin === -1) {
//     begin = dc.indexOf(prefix);
//     if (begin !== 0) return null;
//   } else {
//     begin += 2;
//     var end = document.cookie.indexOf(";", begin);
//     if (end === -1) {
//       end = dc.length;
//     }
//   }
//   return decodeURI(dc.substring(begin + prefix.length, end));
// };

export const tokenExpireTime = (token) => {
  const decoded = jwt_decode(token.split("|")[1]);
  const tokenExpireTime = decoded.exp;
  const tokenAvailableTime = tokenExpireTime * 1000 - Date.now();
  return tokenAvailableTime > 0 ? true : false;
};

export const checkCookie = (name) => {
  try {
    const isTokenExpired = tokenExpireTime(name);
    if (!isTokenExpired) {
      deleteCookie(TOKEN);
      toast.success("Refresh");
      window.location.reload();
    }
  } catch (error) {
    deleteCookie(TOKEN);
    return null;
  } finally {
    if (name !== null && tokenExpireTime(name)) {
      return true;
    } else {
      return null;
    }
  }
};

export const getCookie = (name) => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? v[2] : null;
};

import AccountMedCartPage from "../pages/AccountPages/AccountMedCartPage";
import AccountMedDataP from "../pages/AccountPages/AccountMedData";
import AccountEditPage from "../pages/AccountPages/AccountEditPage";
import AccountDashboardPage from "../pages/AccountPages/AccountDashboardPage";
import OrderHistoryPage from "../pages/AccountPages/OrderHistoryPage";
import AccountPasswordPage from "../pages/AccountPages/AccountPasswordPage";
import StatusOrdersP from "../pages/AccountPages/StatusOrdersP";
import AccountQuesAnsP from "../pages/AccountPages/AccountQuesAnsP";
import AccountStorePage from "../pages/AccountPages/AccountStorePage";
// import AccountCartsP from "../pages/AccountPages/AccountCartsP";

export const accountMenus = [
  { page: AccountDashboardPage, name: "myAccount" },
  { page: AccountEditPage, name: "profile" },
  { page: AccountMedDataP, name: "medData" },
  { page: OrderHistoryPage, name: "ordersHistory" },
  { page: StatusOrdersP, name: "ordersStatus" },
  { page: AccountStorePage, name: "ordersSelected" },
  { page: AccountQuesAnsP, name: "help" },
  { page: AccountMedCartPage, name: "medCart" },
  { page: AccountPasswordPage, name: "password" },
  // { page: AccountCartsP, name: "carts" },
];

import React, { useEffect, useState } from "react";
import ClinicCard from "../components/Cards/ClinicCard";
import ClinicsFilter from "../components/Filters/ClinicsFilter";
import useFetch from "../hooks/useFetch";
import SmallLoading from "../components/Shared/SmallLoading";
import SearchHospital from "../components/Search/SearchHospital";
import { Select } from "antd";
import { connect, useDispatch } from "react-redux";
import { updateState } from "../redux/actions/appActions";
import { ButtonM } from "../components/Buttons/ButtonM";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Modal, ModalBody } from "reactstrap";
import "../style/ClinicsPage.scss";
import CommonLayout from "./../components/Layouts/CommonLayout/index";
import InfiniteScrollCustom from "./../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from "../hooks/useFetchWithScrollPgtn";
import useLocation from "./../hooks/useLocation";

const { Option } = Select;

const ClinicsPage = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [city, setCity] = useState("");
  const [regions] = useFetch("basic-list/regions");
  const [location] = useLocation();
  const [changes, setChanges] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const {
    data: clinics,
    total,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("clinic/list", filterData, [changes]);

  const closeModal = () => {
    setIsOpen(false);
  };
  const openModal = () => {
    setIsOpen(true);
    setFilterData({ ...filterData, size: 100 });
  };
  const handleCity = (e) => {
    setCity(e.target.value);
    if (e.target.value) {
      setFilterData({ ...filterData, region: e.target.value });
    } else {
      delete filterData.region;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleDistance = (dis) => {
    if (dis) {
      setFilterData({
        ...filterData,
        maxDistance: dis * 1000,
        longitude: location[1],
        latitude: location[0],
      });
    } else {
      if (filterData.maxDistance) {
        delete filterData.maxDistance;
        delete filterData.longitude;
        delete filterData.latitude;
      }
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleSort = (value) => {
    if (value) {
      setFilterData({ ...filterData, sort: value });
    } else {
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const lan = props.lan;

  const dispatch = useDispatch();
  useEffect(() => {
    if (props.search.length >= 3) {
      setFilterData({ ...filterData, search: props.search });
    } else {
      if (filterData && filterData.search) {
        let newFilterData = filterData;
        delete newFilterData.search;
        setFilterData({ ...newFilterData });
      }
    }
    setChanges(!changes);
  }, [props.search]);

  useEffect(() => {
    dispatch(updateState({ search: "" }));
  }, []);

  return (
    <CommonLayout isNotSearchComp={true} searchTitle={lan.searchingClinics}>
      <div className="clinicsComp top-bottom">
        <div className="container-lg px-lg-0">
          <h2 className="block-header__title">{lan.clinics}</h2>
          <div
            className="section-header__divider"
            style={{ marginBottom: "20px" }}
          ></div>
          <div className="row mx-0">
            <div className="col-lg-3 px-0">
              <ClinicsFilter
                quantity={<ClinicsSort lan={lan} handleSort={handleSort} />}
                placeName={lan.regions}
                places={regions.map((item) => {
                  return { id: item.id, name: item.name };
                })}
                handlePlace={handleCity}
                handleDistance={handleDistance}
                city={city}
              />
            </div>
            <div className="col-lg-9 pl-lg-3 px-0">
              <div className="sort-card">
                <div className="view-options__legend">
                  <h4 className="d-flex align-items-center">
                    {loading ? (
                      <SmallLoading />
                    ) : (
                      <span
                        style={{ marginRight: "2px", display: "inline-block" }}
                      >
                        {total}
                      </span>
                    )}
                    <span>{` ${lan.amount} ${lan.clinic.toLowerCase()}`}</span>
                  </h4>
                </div>
                <div className="sort-selections d-flex justify-content-between flex-sm-row flex-column">
                  <div className="view-options__select d-none d-lg-block">
                    <label htmlFor="view-option-sort">{lan.sorting}:</label>
                    <ClinicsSort lan={lan} handleSort={handleSort} />
                  </div>
                </div>
                <div className="hospitals-in-map">
                  <img src="/images/background_map.jpg" alt="" />
                  <ButtonM onClick={openModal}>{lan.onMap}</ButtonM>
                </div>
              </div>
              <div>
                <InfiniteScrollCustom
                  data={clinics}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loading={loading}
                >
                  {clinics.map((item) => (
                    <ClinicCard
                      key={item.id}
                      {...item}
                      location={[item.location_y, item.location_x]}
                      userLocation={location}
                    />
                  ))}
                </InfiniteScrollCustom>
              </div>
            </div>
          </div>
        </div>
        <Modal className="mapModal" isOpen={isOpen} toggle={closeModal}>
          <ModalBody>
            <button className="map-modal-close">
              <AiOutlineCloseCircle onClick={closeModal} />
            </button>
            <SearchHospital
              places={clinics.map((clinic) => {
                return {
                  location: [clinic.location_y, clinic.location_x],
                  id: clinic.id,
                  name: clinic.name,
                  score: clinic.score,
                  img:
                    clinic.images.length !== 0
                      ? clinic.images[0].image
                      : "/images/no-hospital.png",
                  userLocation: location,
                };
              })}
              filter={
                <ClinicsFilter
                  // quantity={<ClinicsSort lan={lan} handleSort={handleSort} />}
                  placeName={lan.regions}
                  places={regions.map((item) => {
                    return { id: item.id, name: item.name };
                  })}
                  handlePlace={handleCity}
                  handleDistance={handleDistance}
                  city={city}
                  mobile={true}
                />
              }
            />
          </ModalBody>
        </Modal>
      </div>
    </CommonLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
    search: state.app.search,
  };
};

export default connect(mapStateToProps)(ClinicsPage);

const ClinicsSort = ({ lan, handleSort }) => {
  return (
    <Select
      style={{ width: 200 }}
      placeholder={lan.sortClinics}
      defaultValue="ALL"
      onChange={(e) => handleSort(e)}
    >
      <Option value="ALL">{lan.all}</Option>
      <Option value="RATE">{lan.mostfamous}</Option>
      <Option value="asc_name">{lan.increasename}</Option>
      <Option value="desc_name">{lan.decreasename}</Option>
      <Option value="PRICE_ASC">{lan.increaseprice}</Option>
      <Option value="PRICE_DESC">{lan.decreaseprice}</Option>
      <Option value="DIST_ASC">{lan.increaselocation}</Option>
      <Option value="DIST_DESC">{lan.decreaselocation}</Option>
    </Select>
  );
};

import React from "react";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import HomeClinicCard from "../Cards/HomeClinicCard";
import SampleNextArrow from "../Shared/SampleNextArrow";
import SamplePrevArrow from "../Shared/SamplePrevArrow";
import Loading from "../Shared/Loading";

import "./Clinics.scss";
import useFetch from "../../hooks/useFetch";
import { Link } from "react-router-dom";
import NoData from "../Shared/NoData";

const Clinics = (props) => {
  let settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    autoplaySpeed: 2000,
    pauseOnHover: true,
    autoplay: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const lan = useSelector((state) => state.app.language);
  const [clinics, loading] = useFetch("clinic/list", {
    sort: "desc_score",
  });
  return (
    <div>
      <div className="section-header__body">
        <h2 className="section-header__title">{lan.commonHos}</h2>
        <div className="section-header__spring"></div>
        <ul className="section-header__links">
          <li className="section-header__links-item">
            <Link className="section-header__links-link" to="/clinics">
              {lan.allHosp}
            </Link>
          </li>
        </ul>
        <div className="section-header__divider"></div>
      </div>

      {loading ? (
        <Loading />
      ) : clinics.length !== 0 ? (
        <div className="cities">
          <Slider {...settings}>
            {clinics &&
              clinics.map((clinic) => (
                <div key={clinic.id}>
                  <HomeClinicCard {...clinic} />
                </div>
              ))}
          </Slider>
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default Clinics;

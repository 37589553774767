import React from "react";
import {
  DatePicker,
  Form,
  Input,
  Select,
  Row,
  Col,
  // InputNumber,
  Button,
  Divider,
} from "antd";
// import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useFetch from "./../../hooks/useFetch";
import Loading from "./Loading";
import { sendData } from "../../server/requestFunctions";
import { useHistory } from "react-router-dom";

const UserMedForm = (props) => {
  const lan = useSelector((state) => state.app.language);
  const [userMedData, loading] = useFetch("user/med-questions");
  const [form] = Form.useForm();
  const history = useHistory();

  const onFinish = (values) => {
    let data = Object.entries(values)
      .map(([key, value]) => {
        if (Object.values(value)[0]) {
          return { med_question_id: key, ...value };
        } else {
          return undefined;
        }
      })
      .filter((item) => item);

    // let formData = new FormData();
    // Object.entries(values).forEach(([id, value], index) => {
    //   let [[key, val]] = Object.entries(value);
    //   val && formData.append(`answers[${index}][${key}]`, val);
    //   val && formData.append(`answers[${index}][med_question_id]`, id);
    // });
    sendData("user/update/med-answers", { answers: data }).then(() => {
      if (props.href) {
        window.location.href = props.href;
      }
      toast.success(lan.saveSuccessfully);
    });
  };
  return (
    <Col span={24}>
      <div className="card flex-grow-1">
        <div className="card-body">
          {loading ? (
            <Loading />
          ) : (
            <Form
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              form={form}
              onFinish={onFinish}
              name="medInfo"
            >
              {userMedData.map((category) => {
                return (
                  <>
                    <h4 className="text-center">{category.name}</h4>
                    <Divider />
                    <Row gutter={[8, 8]}>
                      {category.questions.map((ques) => {
                        return (
                          <>
                            {ques.answer_type === "text" && (
                              <Col md={12} span={24}>
                                <Form.Item
                                  label={ques.question}
                                  name={[ques.id, "answer"]}
                                  initialValue={
                                    ques.user_answers.length !== 0 &&
                                    ques.user_answers[0].answer
                                  }
                                >
                                  <Input placeholder={ques.question} />
                                </Form.Item>
                              </Col>
                            )}
                            {ques.answer_type === "date" && (
                              <Col md={12} span={24}>
                                <Form.Item
                                  label={ques.question}
                                  name={[ques.med_question_group_id, ques.id]}
                                >
                                  <DatePicker
                                    format="YYYY-MM-DD"
                                    style={{ width: "100%" }}
                                    placeholder={ques.question}
                                  />
                                </Form.Item>
                              </Col>
                            )}
                            {ques.answer_type === "select" && (
                              <Col md={12} span={24}>
                                <Form.Item
                                  label={ques.question}
                                  name={[ques.id, "med_question_answer_id"]}
                                  initialValue={
                                    ques.user_answers.length !== 0 &&
                                    ques.user_answers[0].med_question_answer_id
                                  }
                                >
                                  <Select placeholder={ques.question}>
                                    {ques.med_question_answers.map((ans) => (
                                      <Select.Option
                                        value={ans.id}
                                        key={ans.answer}
                                      >
                                        {ans.answer}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </>
                );
              })}
              <div className="d-flex justify-content-between">
                <Form.Item>
                  <Button
                    htmlType="submit"
                    type="primary"
                    loading={loading}
                    disabled={loading}
                  >
                    {lan.save}
                  </Button>
                </Form.Item>
                {props.isVisible || (
                  <Form.Item>
                    <Button
                      type="primary"
                      danger
                      onClick={() => {
                        history.push("/account/myAccount");
                      }}
                    >
                      {lan.skip}
                    </Button>
                  </Form.Item>
                )}
              </div>
            </Form>
          )}
        </div>
      </div>
    </Col>
  );
};

export default UserMedForm;

import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Rate } from "antd";
import "./HomeClinicCard.scss";

const HomeClinicCard = (props) => {
  const lan = useSelector((state) => state.app.language);
  const url = props.id;

  return (
    <div className="clinicItem">
      <Link to={`/clinics/${url}`}>
        <div>
          <div>
            <img
              src={
                props.images.length !== 0
                  ? props.images[0].image
                  : "/images/no-hospital.png"
              }
              className="w-100"
              alt=""
            />
          </div>
          <h2>{props.name}</h2>
          {props.score ? (
            <div className="clinic-home-rate">
              <Rate
                disabled
                defaultValue={Math.round(props.score * 2) / 2}
                allowHalf
              />
              <p>({props.score})</p>
            </div>
          ) : (
            <div className="clinic-home-rate">
              <Rate disabled defaultValue={5} />
              <p>(5)</p>
            </div>
          )}
          <p>
            {props.count_doctors || 0} {lan.doctors.toLowerCase()}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default HomeClinicCard;

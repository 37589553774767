import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Input } from "antd";
import { FiSearch, FiShoppingCart } from "react-icons/fi";
import { AiOutlineMenuUnfold } from "react-icons/ai";
import { BiMenuAltLeft } from "react-icons/bi";
import { FcLike } from "react-icons/fc";
import { GiEarthAsiaOceania } from "react-icons/gi";
import { IoMdCloseCircleOutline } from "react-icons/io";
import ToggleMenu from "./ToggleMenu";
import { connect, useSelector } from "react-redux";
import {
  getUserData,
  deleteCart,
  getCartProducts,
  getLikeServices,
  getCompareServices,
  // getStatusOrders,
  updateState,
  changeActiveCartTab,
  getRegions,
} from "../../../redux/actions/appActions";
import { BsPerson } from "react-icons/bs";
import TopHeader from "./TopHeader";
import { RiScales3Fill } from "react-icons/ri";
import { accountMenus } from "../../../constants/accountMenus";
import LoginForm from "../../Shared/LoginForm";
import { IS_VERIFIED, TOKEN } from "../../../tools/constants";
import { getCookie } from "../../../functions/useCookies";
import LogoutButton from "../../Buttons/LogoutButton";
import { useDispatch } from "react-redux";
import "./Header.scss";
import useToggle from "../../../hooks/useToggle";
import { isEmpty } from "../../../functions";

const { Search } = Input;

const Header = (props) => {
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.app.regions);
  const [isCard, setIsCard, refCard] = useToggle(false);
  const [isSignin, setIsLogin, refLogin] = useToggle(false);
  const [menuOpen, setMenuOpen, refMenuClose] = useToggle(false);
  const [insOpen, setInsOpen, refInsClose] = useToggle(false);
  const [cityOpen, setCityOpen, refCityClose] = useToggle(false);
  const [isSearch, setIsSearch, refSearch] = useToggle(false);

  useEffect(() => {
    if (getCookie(TOKEN)) {
      isEmpty(props.userData) && props.getUserData();
      isEmpty(props.cartProducts) && dispatch(getCartProducts());
      isEmpty(regions) && dispatch(getRegions());
      // props.getStatusOrders();
    }
    props.likeServicesNumber && dispatch(getLikeServices());
    props.compareServicesNumber && dispatch(getCompareServices());
  }, [
    getLikeServices,
    getCompareServices,
    props.getCartProducts,
    // props.getStatusOrders,
  ]);

  const onSearch = (value) => {
    props.updateState({ search: value });
  };
  const selectCity = (item) => {
    localStorage.setItem("regionId", item.id);
    localStorage.setItem("regionName", item.name);
    props.updateState({
      selectedCity: item.id,
      selectedRegionName: item.name,
    });
    setCityOpen(false);
  };
  const lan = useSelector((state) => state.app.language);

  return (
    <section id="header">
      {/* TopHeader Start */}
      <div className="background-colors">
        <div className="container-lg px-0">
          <TopHeader
            isNotSearchComp={props.isNotSearchComp}
            searchTitle={props.searchTitle}
          />
        </div>
      </div>
      {/* TopHeader End */}

      <div className="header container-lg">
        <div className="row mx-0 justify-content-between align-items-end align-items-sm-stretch">
          {props.openMenuModal ? (
            <div
              className="d-xl-none d-flex close-button"
              onClick={() => props.updateState({ openMenuModal: false })}
            >
              <IoMdCloseCircleOutline />
            </div>
          ) : (
            <div
              className="d-xl-none d-flex open-button"
              onClick={() => props.updateState({ openMenuModal: true })}
            >
              <AiOutlineMenuUnfold />
            </div>
          )}
          <div className="header-left d-none d-xl-flex align-items-lg-center px-0">
            <div
              onClick={() => updateState({ openMenuModal: false })}
              to="/"
              className="d-flex align-items-center"
            >
              <div className="headerMenus d-flex">
                <div
                  onClick={() => setMenuOpen(!menuOpen)}
                  style={{ marginLeft: "-10px" }}
                  className={
                    menuOpen
                      ? "indicator indicator--open menusItem"
                      : "indicator menusItem"
                  }
                >
                  <button className="indicator__button" style={{ minWidth: 0 }}>
                    <BiMenuAltLeft />
                  </button>
                  <div ref={refMenuClose} className="indicator__content">
                    <ul className="citiesMenus">
                      {/* <li>
                        <Link to="/packages">{lan.packages}</Link>
                      </li> */}
                      <li>
                        <Link to="/quesans">{lan.help}</Link>
                      </li>
                      <li>
                        <Link to="/news">{lan.news}</Link>
                      </li>
                      <li>
                        <Link to="/aboutus">{lan.aboutMenu}</Link>
                      </li>
                      <li>
                        <Link to="/contactus">{lan.contactMenu}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  onClick={() => setCityOpen(!cityOpen)}
                  style={{ marginLeft: "-10px" }}
                  className={
                    cityOpen
                      ? "indicator indicator--open menusItem"
                      : "indicator menusItem"
                  }
                >
                  <button className="indicator__button uz-map">
                    <GiEarthAsiaOceania />
                    {props.selectedRegionName || lan.regions}
                  </button>
                  <div
                    style={{ marginLeft: "10px" }}
                    ref={refCityClose}
                    className="indicator__content"
                  >
                    <ul className="citiesMenus">
                      {regions &&
                        regions.map((city, index) => (
                          <li key={index} onClick={() => selectCity(city)}>
                            <Link to={`/${city.id}/clinics`}>{city.name}</Link>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
                <div className="menusItem">
                  <NavLink activeClassName="active" to="/clinics">
                    {lan.clinics}
                  </NavLink>
                </div>
                <div className="menusItem">
                  <NavLink activeClassName="active" to="/doctors">
                    {lan.doctors}
                  </NavLink>
                </div>
                <div
                  onClick={() => setInsOpen(!insOpen)}
                  style={{ marginLeft: "-10px" }}
                  className={
                    insOpen
                      ? "indicator indicator--open menusItem"
                      : "indicator menusItem"
                  }
                >
                  <button className="indicator__button">{lan.reference}</button>
                  <div ref={refInsClose} className="indicator__content">
                    <ul className="citiesMenus">
                      <li>
                        <Link to="/symptoms">{lan.symptoms}</Link>
                      </li>
                      <li>
                        <Link to="/illness">{lan.illnesses}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-logo d-flex d-xl-none">
            <Link to="/" className="d-flex align-items-center">
              <span>MED</span>
              <span>PAY</span>
            </Link>
          </div>
          <div className="header-right d-none d-xl-flex justify-content-end align-items-center px-0">
            <div className="indicator">
              <Link to="/like" className="indicator__button">
                <span className="indicator__icon">
                  <FcLike className="like-icon" />
                  <span className="indicator__counter">
                    {props.likeServicesNumber}
                  </span>
                </span>
              </Link>
            </div>
            <div className="indicator">
              <Link to="/compare" className="indicator__button">
                <span className="indicator__icon">
                  <RiScales3Fill className="compare-icon" />
                  <span className="indicator__counter">
                    {props.compareServicesNumber}
                  </span>
                </span>
              </Link>
            </div>
            {/* Account start*/}
            <div
              className={isSignin ? " indicator indicator--open" : "indicator"}
              // className={isSignin ? " indicator indicator--open" : "indicator"}
            >
              <button
                className="indicator__button"
                onClick={() => {
                  setIsLogin(!isSignin);
                }}
              >
                <span className="indicator__icon">
                  <BsPerson />
                  <span className="indicator__counter">
                    {props.statusOrdersNumber}
                  </span>
                </span>
                <div className="indicator-text">
                  <span
                    className="indicator__title"
                    style={{ textAlign: "start" }}
                  >
                    {getCookie(TOKEN) &&
                    localStorage.getItem(IS_VERIFIED) === "true"
                      ? `${lan.hello}, ${props.userData.username || lan.user}`
                      : lan.enterButton}
                  </span>
                  <span className="indicator__value">{lan.myAccount}</span>
                </div>
              </button>
              <div ref={refLogin} className="indicator__content">
                {getCookie(TOKEN) &&
                localStorage.getItem(IS_VERIFIED) === "true" ? (
                  <div className="account-menu">
                    <Link
                      className="account-menu__user"
                      to="/account/myAccount"
                      onClick={() => setIsLogin(false)}
                    >
                      <div className="account-menu__user-avatar">
                        <img
                          alt="user"
                          src={props.userData.avatar || "/images/avatar.png"}
                        />
                      </div>
                      <div className=" account-menu__user-info">
                        <div className=" account-menu__user-name">
                          {`${props.userData.name ? props.userData.name : ""} ${
                            props.userData.surname ? props.userData.surname : ""
                          }`}
                        </div>
                        <div className="account-menu__user-email">
                          {props.userData.email}
                        </div>
                      </div>
                    </Link>
                    <div className="account-menu__divider"></div>
                    <ul className="account-menu__links">
                      {accountMenus.map((item, index) => (
                        <li className="account-nav__item" key={index}>
                          <NavLink
                            activeClassName="active"
                            to={"/account/" + item.name}
                            onClick={() => setIsLogin(false)}
                          >
                            {lan[item.name]}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                    <div className="account-menu__divider"></div>
                    <ul className="account-menu__links">
                      <li>
                        <LogoutButton />
                      </li>
                    </ul>
                  </div>
                ) : (
                  <div className="account-menu">
                    <LoginForm setIsLogin={setIsLogin} />
                  </div>
                )}
              </div>
            </div>
            {/* Account end*/}

            {/* Cart Start */}
            <div className={isCard ? "indicator indicator--open" : "indicator"}>
              <button
                className="indicator__button"
                onClick={() => {
                  setIsCard(!isCard);
                }}
              >
                <span className="indicator__icon">
                  <FiShoppingCart />
                  <span className="indicator__counter">
                    {props.cartProducts.length} /{" "}
                    {props.approvedServices.length}
                  </span>
                </span>
                <div className="indicator-text">
                  <span className="indicator__title">{lan.basket}</span>
                  <span className="indicator__value">
                    {props.totalPrice} {lan.sum}
                  </span>
                </div>
              </button>
              <div ref={refCard} className="indicator__content">
                <div className="dropcart">
                  {props.cartProducts.length !== 0 ? (
                    <React.Fragment>
                      <ul className="dropcart__list">
                        {props.cartProducts.map((item) => (
                          <div key={item.id}>
                            <li className="dropcart__item">
                              <div className="dropcart__item-image image image--type--product">
                                <Link
                                  className="image__body"
                                  to={`/clinics/${item.image && item.image.id}`}
                                >
                                  <img
                                    alt=""
                                    className="image__tag"
                                    src={item.image && item.image.image}
                                  />
                                </Link>
                              </div>
                              <div className="dropcart__item-info">
                                <div className="dropcart__item-name">
                                  <Link
                                    to={`/services/${item.service.service_category_id}`}
                                  >
                                    {item.service.name}
                                  </Link>
                                </div>
                                <div className="dropcart__item-meta">
                                  <div className="dropcart__item-quantity">
                                    {1}
                                  </div>
                                  <div className="dropcart__item-price">
                                    {item.service.price} {lan.sum}
                                  </div>
                                </div>
                              </div>
                              <button
                                onClick={() => props.deleteCart(item.id)}
                                type="button"
                                className="dropcart__item-remove"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                >
                                  <path d="M8.8 8.8c-.4.4-1 .4-1.4 0L5 6.4 2.6 8.8c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L3.6 5 1.2 2.6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L5 3.6l2.4-2.4c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L6.4 5l2.4 2.4c.4.4.4 1 0 1.4z"></path>
                                </svg>
                              </button>
                            </li>
                            <li
                              className="dropcart__divider"
                              role="presentation"
                            ></li>
                          </div>
                        ))}
                      </ul>
                      <div className="dropcart__actions">
                        <Link
                          className="btn btn-secondary"
                          onClick={() => {
                            setIsCard(false);
                            props.changeActiveCartTab("1");
                          }}
                          to="/cart"
                        >
                          {lan.basket}
                        </Link>
                        <Link
                          className="btn btn-primary"
                          onClick={() => {
                            setIsCard(false);
                            props.changeActiveCartTab("2");
                          }}
                          to="/checkout"
                        >
                          {lan.order}
                        </Link>
                      </div>
                    </React.Fragment>
                  ) : props.approvedServices.length !== 0 &&
                    props.cartProducts.length === 0 ? (
                    <div className="dropcart__empty">
                      <p>{lan.existingApprovedServices}</p>
                      <Link
                        className="btn btn-primary mt-4"
                        onClick={() => {
                          setIsCard(false);
                          props.changeActiveCartTab("2");
                        }}
                        to="/cart"
                      >
                        {lan.seeing}
                      </Link>
                    </div>
                  ) : (
                    <div className="dropcart__empty">
                      <p>{lan.basketFree}</p>
                      <Link
                        className="btn btn-primary mt-4"
                        onClick={() => {
                          setIsCard(false);
                        }}
                        to="/checkout"
                      >
                        {lan.ordersSelected}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Cart End */}
          </div>

          {/* Mobile Start */}
          <div className="header-right d-flex d-xl-none">
            <div
              className={
                isSearch
                  ? "common-search-box indicator indicator--open"
                  : "common-search-box indicator"
              }
            >
              <button
                className="indicator__button"
                onClick={() => {
                  setIsSearch(!isSearch);
                }}
              >
                <span className="indicator__icon">
                  <FiSearch />
                </span>
              </button>
              <div ref={refSearch} className="indicator__content">
                <Search
                  placeholder={props.searchTitle || lan.searchingServices}
                  onSearch={onSearch}
                  enterButton
                  onChange={(e) =>
                    props.updateState({ search: e.target.value })
                  }
                  value={props.search}
                />
              </div>
            </div>
            {/* <div className="indicator">
              <Link to="/like" className="indicator__button">
                <span className="indicator__icon">
                  <FcLike />
                  <span className="indicator__counter">
                    {props.likeServicesNumber}
                  </span>
                </span>
              </Link>
            </div>
            <div className="indicator">
              <Link to="/compare" className="indicator__button">
                <span className="indicator__icon">
                  <RiScales3Fill />
                  <span className="indicator__counter">
                    {props.compareServicesNumber}
                  </span>
                </span>
              </Link>
            </div> */}
            <div className="indicator">
              <Link
                to={getCookie(TOKEN) ? "/account/myAccount" : "/account"}
                className="indicator__button"
              >
                <span className="indicator__icon">
                  <BsPerson />
                  <span className="indicator__counter">
                    {props.statusOrdersNumber}
                  </span>
                </span>
              </Link>
            </div>
            <div className="indicator">
              <Link to="/cart" className="indicator__button">
                <span className="indicator__icon">
                  <FiShoppingCart />
                  <span className="indicator__counter">
                    {props.cartProducts.length} /{" "}
                    {props.approvedServices.length}
                  </span>
                </span>
              </Link>
            </div>
          </div>
          {/* Mobile End */}
        </div>
      </div>
      <ToggleMenu />
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    selectedRegionName: state.app.selectedRegionName,
    openMenuModal: state.app.openMenuModal,
    cartProducts: state.app.cartProducts,
    approvedServices: state.app.approvedServices,
    compareServicesNumber: state.app.compareServicesNumber,
    likeServicesNumber: state.app.likeServicesNumber,
    statusOrdersNumber: state.app.statusOrdersNumber,
    totalPrice: state.app.totalPrice,
    userData: state.app.userData,
    search: state.app.search,
  };
};

export default connect(mapStateToProps, {
  getUserData,
  deleteCart,
  updateState,
  changeActiveCartTab,
})(Header);

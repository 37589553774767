import React, { useState } from "react";

const FilterItem = (props) => {
  const [open, setOpen] = useState(props.open === false ? false : true);
  return (
    <div className="widget-filters__item">
      <div
        className={open ? "filter_clinics filter--opened" : "filter_clinics"}
      >
        <button
          onClick={() => setOpen(!open)}
          type="button"
          className="filter__title"
        >
          {props.title}
          <span className="filter__arrow">
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7">
              <path d="M.286.273a.92.92 0 0 0-.01 1.292l5.24 5.428 5.241-5.428a.92.92 0 0 0-.01-1.292.923.923 0 0 0-1.31.006L5.516 4.296 1.596.279A.923.923 0 0 0 .286.273z"></path>
            </svg>
          </span>
        </button>
        <div className="filter__body">
          <div className="filter__container">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default FilterItem;

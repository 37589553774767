import React from "react";
import { Link } from "react-router-dom";

const ServiceCard = (props) => {
  return (
    <div className="card px-3">
      <div className="element-card mb-3">
        <Link className="title" to={"/services/" + props.id}>
          {props.name}
        </Link>
      </div>
    </div>
  );
};

export default ServiceCard;

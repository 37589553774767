import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  changeLang,
  updateState,
  getLanguages,
  getStandardServices,
} from "../../../redux/actions/appActions";
import { Link } from "react-router-dom";
import { RiHospitalLine } from "react-icons/ri";
import "./TopHeader.scss";
// import useFetch from "./../../../hooks/useFetch";
import useToggle from "./../../../hooks/useToggle";
import NoData from "./../../Shared/NoData";
import Loading from "./../../Shared/Loading";
import { isEmpty } from "../../../functions";

const TopHeader = (props) => {
  const dispatch = useDispatch();
  const lan = useSelector((state) => state.app.language);
  const languages = useSelector((state) => state.app.languages);
  const standardServices = useSelector((state) => state.app.standardServices);
  const loading = useSelector((state) => state.app.loading);

  const [sug, setSug, sugRef] = useToggle(false);
  const [search, setSearch] = useState("");
  // const [standardServices, loading] = useFetch(
  //   "services/category-list",
  //   { type: "standard", search },
  //   [search]
  // );

  useEffect(() => {
    isEmpty(languages) && dispatch(getLanguages());
    isEmpty(standardServices) && dispatch(getStandardServices());
  }, []);

  function handleSearch(e) {
    setSearch(e.target.value);
    if (e.target.value.length >= 3) {
      dispatch(updateState({ search: e.target.value }));
    } else {
      dispatch(updateState({ search: "" }));
    }
  }

  const searchClick = (e) => {
    setSug(true);
  };

  const searchStandardServices = (search) => {
    if (search) {
      let arr = [];
      for (let j = 0; j < standardServices.length; j++) {
        if (
          new RegExp(search.toLowerCase()).test(
            standardServices[j].name.toLowerCase()
          )
        ) {
          arr.push(standardServices[j]);
        }
      }
      return arr;
    }
    return standardServices;
  };

  return (
    <div className="topheader">
      <div className="header__topbar-start-bg">
        <div className="header__topbar-start">
          <div className="topbar topbar--spaceship-start">
            <div className="topbar__item-text">
              <Link className="topbar__link" to="/aboutus">
                {lan.aboutMenu}
              </Link>
            </div>
            <div className="topbar__item-text">
              <Link className="topbar__link" to="/contactus">
                {lan.contactMenu}
              </Link>
            </div>
            <div className="topbar__item-text">
              <Link className="topbar__link" to="/quesans">
                {lan.help}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-header d-none d-xl-block">
        <div className="header__topbar-start-bg-2"></div>
        <div className="header__logo">
          <Link className="logo" to="/">
            <div className="logo__slogan">{lan.quote}</div>
            <div className="logo__image">
              <span>MED</span>
              <span>PAY</span>
            </div>
          </Link>
        </div>
        <div className="header__topbar-end-bg-2"></div>
        <div className="header__search">
          <div className="search">
            <div className="search__body">
              <div className="search__shadow"></div>
              <label className="sr-only" htmlFor="site-search">
                Ищи...
              </label>
              <input
                type="text"
                className="search__input"
                id="site-search"
                autoCapitalize="off"
                autoComplete="off"
                spellCheck="false"
                name="search"
                value={search}
                placeholder={props.searchTitle || lan.searchingServices}
                onChange={handleSearch}
                onClick={searchClick}
              ></input>
              <button
                type="button"
                className="search__button search__button--start"
              >
                <span className="search__button-icon">
                  <RiHospitalLine />
                </span>
                <span className="search__button-title">Select Vehicle</span>
              </button>
              <button
                className="search__button search__button--end"
                type="submit"
              >
                <span className="search__button-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                  >
                    <path d="M19.2 17.8s-.2.5-.5.8c-.4.4-.9.6-.9.6s-.9.7-2.8-1.6c-1.1-1.4-2.2-2.8-3.1-3.9-1 .8-2.4 1.3-3.9 1.3-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7c0 1.5-.5 2.9-1.3 4 1.1.8 2.5 2 4 3.1 2.3 1.7 1.5 2.7 1.5 2.7zM8 3C5.2 3 3 5.2 3 8s2.2 5 5 5 5-2.2 5-5-2.2-5-5-5z"></path>
                  </svg>
                </span>
              </button>
              <div className="search__box"></div>
              <div className="search__decor">
                <div className="search__decor-start"></div>
                <div className="search__decor-end"></div>
              </div>
              {props.isNotSearchComp || (
                <div
                  ref={sugRef}
                  className={`search__dropdown search__dropdown--suggestions search_suggestions ${
                    sug ? "search__dropdown--open" : ""
                  }`}
                >
                  <div className="suggestions__group">
                    <div className="suggestions__group-title">
                      {lan.services}
                    </div>
                    <div className="suggestions__group-content">
                      {loading ? (
                        <Loading />
                      ) : searchStandardServices(search).length !== 0 ? (
                        searchStandardServices(search)
                          .slice(0, 10)
                          .map((item) => (
                            <Link
                              key={item.id}
                              className="suggestions__item suggestions__product"
                              onClick={() => {
                                setSug(false);
                                dispatch(
                                  updateState({ selectedService: item.id })
                                );
                              }}
                              to={`/services/${item.id}`}
                            >
                              {item.name}
                            </Link>
                          ))
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="header__topbar-end-bg">
        <div className="header__topbar-end h-100">
          <div className="topbar topbar--spaceship-end h-100">
            {/*             <div
              className={
                curr
                  ? "topbar__item-button topbar__menu topbar__menu--open"
                  : "topbar__item-button topbar__menu"
              }
            >
              <button
                onClick={() => setCurr(!curr)}
                className="topbar__button topbar__button--has-arrow topbar__menu-button"
                type="button"
              >
                <span className="topbar__button-label d-none d-sm-inline-block">
                  Валюта:
                </span>
                <span className="topbar__button-title">Сум</span>
                <span className="topbar__button-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5">
                    <path d="M.28.282a.919.919 0 0 1 1.316.015L3.504 2.31 5.413.297a.92.92 0 0 1 1.333 1.268L3.504 5.007.262 1.565A.92.92 0 0 1 .28.282z"></path>
                  </svg>
                </span>
              </button>
              <div className="topbar__menu-body" ref={refCurr}>
                <button className="topbar__menu-item" type="button">
                  <span>Сум</span>
                </button>
                <button className="topbar__menu-item" type="button">
                  <span>Доллар</span>
                </button>
              </div>
            </div>
           */}{" "}
            <div
              className="d-flex justify-content-center"
              style={{ color: "white" }}
            >
              {languages.map((lan) => (
                <button
                  className="topbar__menu-item language-box d-flex align-items-center"
                  type="button"
                  style={{ color: "white" }}
                  key={lan.url}
                  onClick={() => {
                    dispatch(changeLang(lan.url));
                    // setLan(false);
                  }}
                >
                  <img alt={lan.name} width="24" height="15" src={lan.image} />
                  <span>{lan.url}</span>
                </button>
              ))}
              {/* <button
                className="topbar__menu-item language-box d-flex align-items-center"
                type="button"
                style={{ color: "white" }}
                onClick={() => {
                  dispatch(changeLang("uz"));
                  // setLan(false);
                }}
              >
                <img
                  alt="Uzbek"
                  width="24"
                  height="15"
                  src="/images/uz-lan.png"
                />
                <span>Uz</span>
              </button>
              <button
                className="topbar__menu-item language-box d-flex align-items-center"
                type="button"
                style={{ color: "white" }}
                onClick={() => {
                  dispatch(changeLang("ru"));
                  // setLan(false);
                }}
              >
                <img alt="Russian" src="/images/rus-lan.png" />
                <span>Ру</span>
              </button> */}
            </div>
            {/*             <div
              className={
                lan
                  ? "topbar__item-button topbar__menu topbar__menu--open"
                  : "topbar__item-button topbar__menu"
              }
            >
              <button
                className="topbar__button topbar__button--has-arrow topbar__menu-button"
                type="button"
                onClick={() => setLan(!lan)}
              >
                <span className="topbar__button-label  d-none d-sm-inline-block">
                  {props.lan}:
                </span>
                <span className="topbar__button-title">{props.lanName}</span>
                <span className="topbar__button-arrow">
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="5">
                    <path d="M.28.282a.919.919 0 0 1 1.316.015L3.504 2.31 5.413.297a.92.92 0 0 1 1.333 1.268L3.504 5.007.262 1.565A.92.92 0 0 1 .28.282z"></path>
                  </svg>
                </span>
              </button>
              <div className="topbar__menu-body" ref={refLan}>
                <button
                  className="topbar__menu-item d-flex align-items-center"
                  type="button"
                  onClick={() => {
                    dispatch(changeLang("uz"));
                    setLan(false);
                  }}
                >
                  <img
                    alt="Russian"
                    width="24"
                    height="15"
                    src="/images/uz-lan.png"
                  />
                  <span>{props.lanCat[0]}</span>
                </button>
                <button
                  className="topbar__menu-item"
                  type="button"
                  onClick={() => {
                    dispatch(changeLang("ru"));
                    setLan(false);
                  }}
                >
                  <img alt="Russian" src="/images/rus-lan.png" />
                  <span>{props.lanCat[1]}</span>
                </button>
              </div>
            </div>
        */}{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;

import React from "react";
import { connect } from "react-redux";
import AccountLayout from "../../components/Layouts/AccountLayout";
import UserMedForm from "../../components/Shared/UserMedForm";

const AccountMedDataP = (props) => {
  const lan = props.lan;
  return (
    <AccountLayout>
      <div className="card">
        <div className="card-header" style={{ backgroundColor: "white" }}>
          <h4>{lan.medData}</h4>
        </div>
        <div className="card-divider"></div>
        <UserMedForm isVisible={true} />
      </div>
    </AccountLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    lan: state.app.language,
  };
};

export default connect(mapStateToProps)(AccountMedDataP);

import AccountComp from "../../components/Layouts/AccountLayout";
import React, { useRef, useState } from "react";
import {
  Table,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Space,
  Form,
  Select,
  Upload,
  Tabs,
} from "antd";
import Loading from "../../components/Shared/Loading";
import { FaLock, FaLockOpen } from "react-icons/fa";
import { AiOutlinePlusSquare } from "react-icons/ai";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { DeleteOutlined } from "@ant-design/icons";
import { PGNTN_LIMIT } from "../../tools/constants";
import useFetchWithPgtn from "../../hooks/useFetchWithPgtn";
import { sendData } from "../../server/requestFunctions";
import { toast } from "react-toastify";
import { isEmpty } from "../../functions";
import { getData } from "./../../server/requestFunctions";
const { TabPane } = Tabs;
const { Option } = Select;

const AccountMedCartPage = () => {
  const lan = useSelector((state) => state.app.language);
  const [categories] = useFetch("services/category-list", { type: "category" });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [singleMedData, setSingleMedData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState(null);

  const [changes, setChanges] = useState(false);
  const {
    data: medData,
    page: currentPage,
    total: totalElements,
    loading: isFetching,
    handlePaginationChange,
  } = useFetchWithPgtn("user/med-cards", filterData, [changes]);

  const [changesC, setChangesC] = useState(false);
  const {
    data: createdMedData,
    page: currentPageC,
    total: totalElementsC,
    loading: isFetchingC,
    handlePaginationChange: handlePaginationChangeC,
  } = useFetchWithPgtn("user/med-files", {}, [changesC]);

  const [isVisible, setIsVisible] = useState(false);
  const [modal, setModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [files, setFiles] = useState([]);
  const searchInput = useRef();
  const [form] = Form.useForm();
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Qidirish
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Yopish
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    // onFilter: (value, record) =>
    //   record[dataIndex]
    //     ? record[dataIndex]
    //         .toString()
    //         .toLowerCase()
    //         .includes(value.toLowerCase())
    //     : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    // var searchType = "";
    // if (dataIndex === "price") {
    //   searchType = "PRICE";
    // }
    // if (dataIndex === "clinicName") {
    //   searchType = "CLINIC_NAME";
    // }
    // if (dataIndex === "serviceItemName") {
    //   searchType = "SERVICE_NAME";
    // }
    // if (dataIndex === "doctorName") {
    //   searchType = "DOCTOR_NAME";
    // }
    // getDataAuth(
    //   `med-card/search?searchType=${searchType}&keyword=${selectedKeys[0]}&page=${currentPage}&size=${PGNTN_LIMIT}`
    // ).then((res) => {
    //   setData(res.data.content);
    // });
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
    setChanges(!changes);
  };
  const changeAccess = (row) => {
    sendData(`user/med-cards/update-status/${row.id}`).then((res) => {
      setChanges(!changes);
    });
  };
  const columns = [
    {
      title: lan.servic,
      width: 100,
      // dataIndex: "serviceItemName",
      ...getColumnSearchProps("serviceItemName"),
      render: (row) => row.service.name,
    },
    {
      title: lan.date,
      width: 60,
      ...getColumnSearchProps("date"),
      sorter: () => {
        if (filterData && filterData.sort) {
          setFilterData({
            ...filterData,
            sort: filterData.sort === "DATE_ASC" ? "DATE_DESC" : "DATE_ASC",
          });
        } else {
          setFilterData({ ...filterData, sort: "DATE_ASC" });
        }
        setChanges(!changes);
      },
      sortDirections: ["descend", "ascend"],
      render: (row) => row.created_at.split("T")[0],
    },
    {
      title: lan.doctor,
      // dataIndex: "doctor",
      width: 100,
      ...getColumnSearchProps("doctor"),
      render: (row) => row.doctor.user.fio,
    },
    {
      title: lan.clinic,
      // dataIndex: "clinicName",
      width: 100,
      ...getColumnSearchProps("clinicName"),
      render: (row) => row.clinic.name,
    },

    {
      title: lan.price,
      // dataIndex: `price`,
      width: 60,
      ...getColumnSearchProps("price"),
      render: (row) => row.service.price,
      sorter: () => {
        if (filterData && filterData.sort) {
          setFilterData({
            ...filterData,
            sort: filterData.sort === "PRICE_ASC" ? "PRICE_DESC" : "PRICE_ASC",
          });
        } else {
          setFilterData({ ...filterData, sort: "PRICE_ASC" });
        }
        setChanges(!changes);
      },
      sortDirections: ["descend", "ascend"],
    },
    {
      title: lan.access,
      align: "center",
      width: 60,
      // filters: [
      //   { text: <FaLockOpen />, value: true },
      //   { text: <FaLock />, value: false },
      // ],
      sorter: () => {
        if (filterData && filterData.sort) {
          setFilterData({
            ...filterData,
            sort:
              filterData.sort === "ACCESS_OPEN"
                ? "ACCESS_CLOSE"
                : "ACCESS_OPEN",
          });
        } else {
          setFilterData({ ...filterData, sort: "ACCESS_OPEN" });
        }
        setChanges(!changes);
      },
      render: (row) => {
        return (
          <div onClick={() => changeAccess(row)}>
            {row.is_seen ? (
              <FaLockOpen style={{ cursor: "pointer" }} />
            ) : (
              <FaLock style={{ cursor: "pointer" }} />
            )}
          </div>
        );
      },
    },
    {
      title: lan.more,
      dataIndex: ``,
      width: 50,
      render: (data) => (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setLoading(true);
            getData("user/med-cards/" + data.id)
              .then((res) => {
                setSingleMedData(res.data);
              })
              .finally(() => {
                setLoading(false);
              });
            setModal(true);
          }}
        >
          {lan.more}
        </Button>
      ),
    },
  ];
  const columnsC = [
    {
      title: lan.title,
      dataIndex: "name",
    },
    {
      title: lan.delete,
      width: 50,
      align: "center",
      render: (raw) => (
        <DeleteOutlined
          danger
          onClick={() => {
            Modal.confirm({
              title: lan.reallyDelete,
              cancelText: lan.no,
              okText: lan.yes,
              onOk() {
                sendData(`user/med-files/delete/${raw.id}`).then(() => {
                  setChangesC(!changesC);
                });
              },
            });
          }}
        />
      ),
    },
    {
      title: lan.more,
      dataIndex: ``,
      width: 50,
      render: (data) => (
        <Button
          size="small"
          type="primary"
          onClick={() => {
            setLoading(true);
            getData("user/med-files/show/" + data.id)
              .then((res) => {
                setSingleMedData(res.data);
              })
              .finally(() => {
                setLoading(false);
              });
            setModal(true);
          }}
        >
          {lan.more}
        </Button>
      ),
    },
  ];
  const saveService = () => {
    form.validateFields().then((values) => {
      let formData = new FormData();
      Object.keys(values).forEach((item) =>
        formData.append(item, values[item] || "")
      );
      isEmpty(files)
        ? toast.error("File")
        : files.forEach((file) => {
            formData.append("files[]", file);
          });

      sendData("user/med-files/store", formData).then((res) => {
        setIsVisible(false);
        setChangesC(!changesC);
      });
    });
  };
  const addPersonalMedCart = () => {
    setIsVisible(true);
    form.setFieldsValue({
      clinic: null,
      service: { name: null, type: null },
      doctor: null,
      isVisible: null,
      date: null,
    });
  };
  const handleCategory = (category) => {
    setSelectedCategory(category);
    setSubCategories([]);
    setSelectedSubCategory("");
    if (selectedSubCategory) {
      delete filterData.subCategoryId;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    if (category) {
      getData("services/category-list", { category_id: category }).then(
        (res) => {
          setSubCategories(res.data);
        }
      );
      setFilterData({ ...filterData, category: category });
    } else {
      setSubCategories([]);
      delete filterData.category;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const handleSubCategory = (id) => {
    setSelectedSubCategory(id);
    if (id) {
      delete filterData.category;
      setFilterData({ ...filterData, id });
    } else {
      delete filterData.id;
      setFilterData(Object.keys(filterData).length !== 0 ? filterData : null);
    }
    setChanges(!changes);
  };
  const fileRemove = (file) => {
    setFiles(files.filter((item) => item.uid !== file.uid));
  };
  const fileRequest = ({ file, onError, onProgress, onSuccess }) => {
    setFiles((files) => [...files, file]);
    onSuccess();
  };
  return (
    <AccountComp>
      <div className="card p-4">
        <div>
          <Tabs type="card" defaultActiveKey="1" centered>
            <TabPane key="1" tab={lan.createdByDoctor}>
              <Table
                title={() => (
                  <>
                    <Row
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h4>{lan.yourMedData}</h4>
                      </div>
                    </Row>
                    <Row gutter={[8, 8]} className="mt-3">
                      <Col md={12} span={24}>
                        <Select
                          placeholder={lan.sortClinics}
                          defaultValue=""
                          onChange={handleCategory}
                          style={{ width: "100%" }}
                        >
                          <Option value="">{lan.all}</Option>
                          {categories.map((item) => (
                            <Option key={item.id} value={item.id}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col md={12} span={24}>
                        <Select
                          placeholder={lan.sortClinics}
                          defaultValue=""
                          value={selectedSubCategory}
                          onChange={handleSubCategory}
                          disabled={selectedCategory ? false : true}
                          style={{ width: "100%" }}
                        >
                          <Option value="">{lan.all}</Option>
                          {subCategories.map((item) => (
                            <Option
                              key={item.subCategoryId}
                              value={item.subCategoryId}
                            >
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </>
                )}
                pagination={
                  totalElementsC > PGNTN_LIMIT && {
                    current: currentPage,
                    total: totalElements,
                    pageSize: PGNTN_LIMIT,
                    onChange: handlePaginationChange,
                    showTotal: (totalElements) =>
                      `${lan.total}: ${totalElements}`,
                  }
                }
                loading={isFetching}
                bordered
                size="small"
                columns={columns}
                dataSource={medData}
                scroll={{ x: 700 }}
              />
            </TabPane>
            <TabPane key="2" tab={lan.createdBySelf}>
              <Table
                title={() => (
                  <>
                    <Row
                      style={{
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <h4>{lan.yourMedData}</h4>
                      </div>
                      <Button
                        className="addServiceData d-flex"
                        onClick={addPersonalMedCart}
                      >
                        <AiOutlinePlusSquare />
                      </Button>
                    </Row>
                  </>
                )}
                pagination={
                  totalElementsC > PGNTN_LIMIT && {
                    current: currentPageC,
                    total: totalElementsC,
                    pageSize: PGNTN_LIMIT,
                    onChange: handlePaginationChangeC,
                    showTotal: (totalElementsC) => `Jami: ${totalElementsC}`,
                  }
                }
                bordered
                size="small"
                columns={columnsC}
                dataSource={createdMedData}
                rowKey="madCardId"
                loading={isFetchingC}
                scroll={{ x: 400 }}
              />
            </TabPane>
          </Tabs>
        </div>
        <Modal
          className="medCartModal"
          title={lan.moreInformationAboutService}
          visible={modal}
          onOk={() => {
            setModal(false);
            setSingleMedData([]);
          }}
          cancelText={lan.cancel}
          onCancel={() => {
            setModal(false);
            setSingleMedData({});
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              <h3 className="text-center">{singleMedData.name}</h3>
              {isEmpty(singleMedData) ||
                singleMedData.files.map((file) => (
                  <a
                    download
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: "pointer", color: "#1A8194" }}
                    className="d-flex justify-content-between"
                    href={file.file}
                  >
                    <span>{file.file.split("/").slice(-1)[0]}</span>
                    <span>{(file.size / 1024).toFixed(2) + " KB"}</span>
                  </a>
                ))}
            </>
          )}
        </Modal>
        <Modal
          title="Подробная информация об услуге"
          visible={isVisible}
          onOk={saveService}
          okText={lan.save}
          onCancel={() => setIsVisible(false)}
        >
          <Form
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            form={form}
            name="medcart"
          >
            <Form.Item
              name="name"
              label={lan.title}
              rules={[
                {
                  required: true,
                  message: lan.notFilled,
                },
              ]}
            >
              <Input placeholder={lan.title}></Input>
            </Form.Item>
            <Upload
              multiple
              listType="picture"
              onRemove={fileRemove}
              customRequest={fileRequest}
            >
              <Button type="primary">{lan.upload}</Button>
            </Upload>
          </Form>
        </Modal>
      </div>
    </AccountComp>
  );
};

export default AccountMedCartPage;

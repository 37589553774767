import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loading from "../components/Shared/Loading";
import {
  MAX_NUMBER_SELECTING_TIME,
  // NUMBER_WEEKS_SERVICE_SCHEDULE,
} from "../tools/constants";
import TimetableCard from "../components/Cards/TimetableCard";
import {
  Tabs,
  Modal,
  Empty,
  Button,
  Alert,
  Tag,
  Radio,
  Space,
  Rate,
} from "antd";
import { TiArrowBackOutline } from "react-icons/ti";
import { getData, sendData } from "./../server/requestFunctions";
import { getCartProducts } from "../redux/actions/appActions";

const { TabPane } = Tabs;

const useBuyService = (item) => {
  const lan = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [schedule, setSchedule] = useState([]);
  const [selectedTimes, setSelectedTimes] = useState([]);
  const [scheduleLoading, setScheduleLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [liveOrderLoading, setLiveOrderLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [doctorsLoading, setDoctorsLoading] = useState(true);

  const back = () => {
    setSelected(false);
  };
  const selectDoctor = (e) => {
    setSelectedDoctor(e.target.value);
  };

  const check = (selectedTime) => {
    var numberOfTimes = selectedTimes.length;
    const dayFinded = selectedTimes.find(
      (d) => JSON.stringify(d) === JSON.stringify(selectedTime)
    );
    if (dayFinded) {
      setSelectedTimes((selectedTimes) =>
        selectedTimes.filter(
          (d) => JSON.stringify(d) !== JSON.stringify(selectedTime)
        )
      );
    } else {
      if (numberOfTimes <= MAX_NUMBER_SELECTING_TIME) {
        setSelectedTimes([...selectedTimes, selectedTime]);
      } else {
        toast.error(lan.maxNumberOfSelectingTime);
      }
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleOk = () => {
    if (selectedDoctor && !selected) {
      setSelected(true);
      setScheduleLoading(true);
      getData(
        `service-doctors/get-time-table?service_id=${item.id}&doctor_id=${selectedDoctor}`
      )
        .then((res) => {
          setSchedule(res.data);
        })
        .finally(() => {
          setScheduleLoading(false);
        });
    } else if (selectedDoctor && selected) {
      setLoading(true);
      sendData("user-basket/set", {
        service_id: item.id,
        doctor_id: selectedDoctor,
        dates: selectedTimes.map((st) => st.join(" ").slice(0, -3)),
      })
        .then(() => {
          setIsModalVisible(false);
          toast.success(lan.orderSendSuccessfully);
          dispatch(getCartProducts());
          setSelectedTimes([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error(lan.selectServiceDoctor);
    }
  };
  const openTimeTable = () => {
    showModal();
    setDoctorsLoading(true);
    getData(`service-doctors/get-service-doctors/${item.id}`).then((res) => {
      setDoctorsLoading(false);
      setDoctors(res.data);
    });
  };
  const sendLiveOrder = (id) => {
    setLiveOrderLoading(true);
    getData(
      `order/live?timetableObjectId=${id}&serviceItemId=${item.serviceItemId}`
    )
      .then(() => {
        setIsModalVisible(false);
        toast.success(lan.orderSendSuccessfully);
      })
      .finally(() => {
        setLiveOrderLoading(false);
        dispatch(getCartProducts());
      });
  };
  const TimeTableModal = (
    <Modal
      visible={isModalVisible}
      title={[
        <div className="service-modal-title">
          {selected && (
            <Tag onClick={back}>
              <TiArrowBackOutline />
              {lan.back}
            </Tag>
          )}
          <span>
            {lan.timeScheduleOfService}, {item.name}
          </span>
        </div>,
      ]}
      okText={selected ? lan.sending : lan.selectTime}
      cancelText={lan.closing}
      onOk={handleOk}
      onCancel={handleCancel}
      className="service-modal"
      confirmLoading={loading}
    >
      {selected ? (
        <div className="timeTable">
          {scheduleLoading ? (
            <Loading />
          ) : schedule.length !== 0 ? (
            <Tabs tabPosition="left">
              {schedule.map((day) => (
                <TabPane
                  tab={[
                    <>
                      <p>{day.date}</p>
                      <p>{day.weekday}</p>
                    </>,
                  ]}
                  key={day.date}
                >
                  <div className="panel-content">
                    {day.times.length !== 0 ? (
                      day.times.map((time) => (
                        <TimetableCard
                          key={time}
                          time={time}
                          statusOrder={
                            day.yellow_times.includes(time)
                              ? "yellow"
                              : day.red_times.includes(time)
                              ? "red"
                              : "white"
                          }
                          check={() => check([day.date, time])}
                          status={selectedTimes.find(
                            (d) =>
                              JSON.stringify(d) ===
                              JSON.stringify([day.date, time])
                          )}
                        />
                      ))
                    ) : (
                      <div>
                        <Alert
                          message={lan.liveOrder}
                          description={lan.descLiveOrder}
                          type="warning"
                          showIcon
                          closable
                        />
                        <Button
                          className="mt-3"
                          loading={liveOrderLoading}
                          disabled={liveOrderLoading}
                          onClick={() =>
                            sendLiveOrder(day.timetableObjectId, day)
                          }
                        >
                          {lan.buy}
                        </Button>
                      </div>
                    )}
                  </div>
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={lan.noTimeTable}
            />
          )}
        </div>
      ) : doctorsLoading ? (
        <Loading />
      ) : doctors.length !== 0 ? (
        <Radio.Group
          onChange={selectDoctor}
          defaultValue={selectedDoctor}
          buttonStyle="solid"
        >
          <Space direction="vertical">
            {doctors.length !== 0 &&
              doctors.map((doctor) => (
                <Radio.Button key={doctor.id} value={doctor.id}>
                  <p>{doctor.user.fio}</p>
                  <h5 className="mb-0">
                    {doctor.score ? (
                      <Rate
                        disabled
                        defaultValue={Math.round(doctor.score * 2) / 2}
                        allowHalf
                      />
                    ) : (
                      <Rate disabled defaultValue={5} />
                    )}
                  </h5>
                </Radio.Button>
              ))}
          </Space>
        </Radio.Group>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={lan.noDoctorServices}
        />
      )}
    </Modal>
  );

  const ButtonOpeningTimeTable = (
    <div className="product__actions-item product__actions-item--addtocart">
      {item.allowed_billing ? (
        <button
          onClick={openTimeTable}
          type="submit"
          className="btn btn-primary btn-block"
          disabled={item.allowed_billing ? false : true}
        >
          {lan.buy}
        </button>
      ) : (
        <button disabled className="btn btn-primary">
          {lan.noSale}
        </button>
      )}
      {TimeTableModal}
    </div>
  );

  return [ButtonOpeningTimeTable];
};

export default useBuyService;

import React from "react";
import { Link, Text, View, StyleSheet } from "@react-pdf/renderer";
import { lanProp } from "../../../redux/reducers/appReducer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#112131",
    borderBottomStyle: "solid",
    alignItems: "stretch",
  },
  detailColumn: {
    flexDirection: "column",
    flexGrow: 9,
    textTransform: "uppercase",
  },
  linkColumn: {
    flexDirection: "column",
    flexGrow: 2,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  name: {
    fontSize: 20,
    fontFamily: "Lato Bold",
  },
  subtitle: {
    fontSize: 10,
    justifySelf: "flex-end",
    fontFamily: "Lato",
  },
  link: {
    fontFamily: "Lato",
    fontSize: 10,
    color: "black",
    textDecoration: "none",
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
  title: {
    fontSize: 25,
    marginTop: 10,
    padding: 10,
    textAlign: "center",
    backgroundColor: "#e4e4e4",
    textTransform: "uppercase",
    // fontFamily: "Oswald",
  },
});

const Header = (props) => {
  const lan = lanProp();

  return (
    <View>
      <View style={styles.container}>
        <View style={styles.detailColumn}>
          <Text style={styles.name}>{props.name}</Text>
        </View>
        <View style={styles.linkColumn}>
          <Link href="https://medpay.uz" target="_blank" style={styles.link}>
            Medpay.uz
          </Link>
        </View>
      </View>
      <View style={styles.title}>
        <Text style={styles.name}>{lan.medpayOrder}</Text>
      </View>
    </View>
  );
};

export default Header;

import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  changeAccountKey,
  moveToServerFromLocalLikeServices,
  moveToServerFromLocalCompareServices,
  updateState,
} from "../../redux/actions/appActions";
import { saveAuth } from "../../functions/saveAuth";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import {
  API_URL_FRONT,
  IS_VERIFIED,
  REGISTER_SMS,
  RETURN_CODE_DATE,
  SITE_LANG,
  TOKEN,
} from "../../tools/constants";
import axios from "axios";
import { setCookie } from "../../functions/useCookies";

const LoginForm = (props) => {
  const [loading, setLoading] = useState(false);

  const lan = useSelector((state) => state.app.language);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const reload = () => {
    props.href ? (window.location.href = props.href) : window.location.reload();
  };
  const onFinish = (values) => {
    setLoading(true);
    axios
      .post(
        `${API_URL_FRONT}login?lang=${localStorage.getItem(SITE_LANG)}`,
        values
      )
      .then((res) => {
        setLoading(false);
        if (res.data.is_verified) {
          props.setIsLogin && props.setIsLogin(false);
          localStorage.setItem(IS_VERIFIED, "true");
          saveAuth(res, reload);
        }
        dispatch(moveToServerFromLocalLikeServices());
        dispatch(moveToServerFromLocalCompareServices());
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.is_verified === false) {
            localStorage.setItem(IS_VERIFIED, "false");
            history.push("/account");
            dispatch(changeAccountKey("2"));
            setCookie(TOKEN, err.response.data.token);
            localStorage.setItem(
              REGISTER_SMS,
              err.response.data.phone.slice(3)
            );
            localStorage.setItem(RETURN_CODE_DATE, Date.now());
            dispatch(updateState({ isCode: true }));
          }

          form.setFields(
            Object.keys(err.response.data.errors).map((item) => {
              return { name: [item], errors: err.response.data.errors[item] };
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="card flex-grow-1">
      <div className="card-body">
        <h3 className="card-title">{lan.login}</h3>
        <Form
          name="login"
          form={form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
        >
          <Form.Item
            label={lan.username}
            name="username"
            rules={[
              {
                required: true,
                message: lan.enterUsername,
              },
              {
                pattern:
                  /^(?=.{5,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
                message: lan.enterValidUsername,
              },
            ]}
          >
            <Input placeholder={lan.username} disabled={loading} />
          </Form.Item>

          <Form.Item
            label={lan.password}
            name="password"
            rules={[
              {
                required: true,
                message: lan.enterPassword,
              },
            ]}
          >
            <Input.Password
              iconRender={(visible) =>
                visible ? (
                  <EyeTwoTone onClick={() => props.setIsLogin(true)} />
                ) : (
                  <EyeInvisibleOutlined
                    onClick={() => props.setIsLogin(true)}
                  />
                )
              }
              placeholder={lan.password}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-between align-items-center">
              <Button type="primary" htmlType="submit" disabled={loading}>
                {lan.login}
              </Button>
              <div className="d-flex flex-column justify-content-end">
                <Link to="/account-resetpassword">{lan.forgetPassword}</Link>
                <Link
                  to="/account"
                  onClick={() => {
                    dispatch(changeAccountKey("2"));
                    props.setIsLogin && props.setIsLogin(false);
                  }}
                >
                  {lan.register}
                </Link>
              </div>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default LoginForm;

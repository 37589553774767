import React from "react";
import { BeatLoader } from "react-spinners";

const Loading = () => {
  return (
    <div className="d-flex justify-content-center">
      <BeatLoader size={50} color="#1A8194" />{" "}
    </div>
  );
};

export default Loading;

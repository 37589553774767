import React from "react";
import { Col, Row } from "antd";
import PackageCard from "../../components/Cards/PackageCard";
import SpecificClinicLayout from "../../components/Layouts/SpecificClinicLayout";
import { useSelector } from "react-redux";
import InfiniteScrollCustom from "../../components/Shared/InfiniteScrollCustom";
import useFetchWithScrollPgtn from './../../hooks/useFetchWithScrollPgtn';

const SpecificClinicPackagesPage = (props) => {
  const {
    data: packages,
    hasMore,
    loading,
    fetchMoreData,
  } = useFetchWithScrollPgtn("clinic/packets/" + props.match.params.id);
  const lan = useSelector((state) => state.app.language);

  return (
    <SpecificClinicLayout breadcrumbs={[lan.packages]}>
      <InfiniteScrollCustom
        data={packages}
        next={fetchMoreData}
        hasMore={hasMore}
        loading={loading}
      >
        <Row gutter={[16, 16]}>
          {packages.map((item) => (
            <Col key={item.id} lg={6} mc={8} sm={12} span={24}>
              <PackageCard {...item} />
            </Col>
          ))}
        </Row>
      </InfiniteScrollCustom>
    </SpecificClinicLayout>
  );
};

export default SpecificClinicPackagesPage;

import { useRef, useState } from "react";
import useOutsideClick from "../functions/useOutSideClick";

const useToggle = () => {
  const [name, setName] = useState(false);
  const refName = useRef();

  useOutsideClick(refName, () => {
    if (name) setName(false);
  });

  return [name, setName, refName];
};

export default useToggle;

import React from "react";
import { BiCheck } from "react-icons/bi";
import "./TimeTableCard.scss";

const TimetableCard = (props) => {
  return (
    <div
      className="timetablecard"
      onClick={props.check}
      status
      style={{
        backgroundColor: props.statusOrder,
        pointerEvents: props.statusOrder === "white" ? "auto" : "none",
        opacity: props.statusOrder === "white" ? "1" : "0.4",
      }}
    >
      {props.time}
      {props.status && (
        <div>
          <BiCheck />
        </div>
      )}
    </div>
  );
};

export default TimetableCard;

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NoData from "./../components/Shared/NoData";
// import { getDataAuth } from "./../server/config/common";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";
import "../style/CheckoutHistory.scss";
import { isEmpty } from "../functions";
import useFetch from "../hooks/useFetch";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OrderPDFGenerator from "./../components/Shared/OrderPDFGenerator/index";
import { getUserData } from "../redux/actions/appActions";

const CheckoutHistoryPage = (props) => {
  const transId = props.match.params.id;
  const lan = useSelector((state) => state.app.language);
  const user = useSelector((state) => state.app.userData);
  const dispatch = useDispatch();

  const [data] = useFetch("billing/getByTransactionId", {
    transaction_id: transId,
  });

  useEffect(() => {
    dispatch(getUserData());
  }, []);

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  return (
    <div className="checkoutHistory">
      <div className="container">
        {isEmpty(data) ? (
          <div className="block-empty__body">
            <NoData />
            <div className="block-empty__action">
              <Link className="btn btn-primary btn-sm" to="/">
                {lan.toHome}
              </Link>
            </div>
          </div>
        ) : (
          <div className="order-success__body">
            <div className="order-success__header">
              <svg
                className="order-success__icon"
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
              >
                <path d="M50 100C22.4 100 0 77.6 0 50S22.4 0 50 0s50 22.4 50 50-22.4 50-50 50zm0-98C23.5 2 2 23.5 2 50s21.5 48 48 48 48-21.5 48-48S76.5 2 50 2zm-5.8 69L22.3 49.1l1.4-1.4 21.2 21.2 34.4-34.4 1.4 1.4L45.6 71c-.4.4-1 .4-1.4 0z"></path>
              </svg>
              <h1 className="order-success__title">{lan.thanksForOrder} !</h1>
              <div className="order-success__subtitle">
                {lan.weReceivedYourOrder}
              </div>
              <div className="order-success__actions">
                <Link className="btn btn-sm btn-secondary" to="/">
                  {lan.toHome}
                </Link>
              </div>
            </div>
            <div className="card order-success__meta">
              <ul className="order-success__meta-list">
                <li className="order-success__meta-item">
                  <span className="order-success__meta-title">
                    {lan.numberOfOrder}
                  </span>
                  <span className="order-success__meta-value">№ {transId}</span>
                </li>
                <li className="order-success__meta-item">
                  <span className="order-success__meta-title">{lan.date}</span>
                  <span className="order-success__meta-value">
                    {mm + "/" + dd + "/" + yyyy}
                  </span>
                </li>
                <li className="order-success__meta-item">
                  <span className="order-success__meta-title">
                    {lan.totalPrice}
                  </span>
                  <span className="order-success__meta-value">
                    {data.orders.reduce((a, b) => a + b.price, 0)}
                  </span>
                </li>
                <li className="order-success__meta-item">
                  <span className="order-success__meta-title">
                    {lan.typeOfPayment}
                  </span>
                  <span className="order-success__meta-value">
                    {lan.creditCard}
                  </span>
                </li>
              </ul>
            </div>
            <div className="card" style={{ overflowX: "auto" }}>
              <div className="order-list">
                <table style={{ minWidth: "600px" }}>
                  <thead className="order-list__header">
                    <tr>
                      <th className="order-list__column-label" colSpan="2">
                        {lan.servic}
                      </th>
                      <th className="order-list__column-quantity">
                        {lan.clinic}
                      </th>
                      <th className="order-list__column-quantity">
                        {lan.doctor}
                      </th>
                      <th className="order-list__column-total">{lan.price}</th>
                      <th className="order-list__column-total">
                        {lan.download}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="order-list__products">
                    {isEmpty(data) ||
                      data.orders.map((item) => (
                        <tr key={item.id}>
                          <td className="order-list__column-image">
                            <div className="image image--type--product">
                              <Link
                                className="image__body"
                                to={`/clinics/${item.clinic_id}`}
                              >
                                <img
                                  alt=""
                                  className="image__tag"
                                  src={
                                    item.clinic.images[0].image ||
                                    "/images/no-hospital.png"
                                  }
                                />
                              </Link>
                            </div>
                          </td>
                          <td className="order-list__column-product">
                            <Link
                              className="cart-table__product-name"
                              to={`/services/${item.service.service_category_id}`}
                            >
                              {item.service.name}
                            </Link>
                          </td>
                          <td className="order-list__column-quantity">
                            <Link
                              className="cart-table__product-name"
                              to={`/clinics/${item.clinic_id}`}
                            >
                              {item.clinic.name}
                            </Link>
                          </td>
                          <td className="order-list__column-quantity">
                            <Link
                              className="cart-table__product-name"
                              to={`/doctors/${item.doctor.user_id}`}
                            >
                              {item.doctor.user.fio}
                            </Link>
                          </td>
                          <td className="order-list__column-total">
                            {item.price}
                          </td>
                          <td className="order-list__column-total">
                            <PDFDownloadLink
                              document={
                                <OrderPDFGenerator
                                  name={user.name + " " + user.surname}
                                  service={item.service.name}
                                  time={item.service_time}
                                  price={item.price}
                                  clinic={item.clinic.name}
                                  doctor={item.doctor.user.fio}
                                  promo_code={item.promo_code}
                                />
                              }
                              fileName={`${item.service.name}.pdf`}
                            >
                              {({ blob, url, loading, error }) =>
                                loading ? (
                                  lan.loading
                                ) : (
                                  <Button
                                    type="primary"
                                    icon={<DownloadOutlined />}
                                  />
                                )
                              }
                            </PDFDownloadLink>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <tfoot className="order-list__footer">
                    <tr>
                      <th className="order-list__column-label" colSpan="5">
                        {lan.totalPrice}
                      </th>
                      <td className="order-list__column-total">
                        {data.orders.reduce((a, b) => a + b.price, 0)}
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutHistoryPage;

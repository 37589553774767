import React from "react";
import { Link } from "react-router-dom";
import "./ClinicDoctorCard.scss";

const ClinicDoctorCard = (props) => {
  return (
    <div className="block-teammates__item teammate">
      <div className="teammate__avatar">
        <Link to={`/doctors/${props.user_id}`}>
          <img
            alt="doctor"
            className="image__tag"
            src={props.user.avatar || "/images/doctor-avatar.jpg"}
          />
        </Link>
      </div>
      <div className="teammate__info">
        <div className="teammate__name">{props.user.fio}</div>
        <div className="teammate__position">{props.category}</div>
      </div>
    </div>
  );
};

export default ClinicDoctorCard;

import React from "react";
import { Card, Divider, Button } from "antd";
import { useSelector } from "react-redux";
import "./StorageProfileCard.scss";

const StorageProfileCard = (props) => {
  const lan = useSelector((state) => state.app.language);

  return (
    <Card
      title={props.title}
      className={`storageProfileCard ${
        props.activeStatus === props.title && "activeStorageProfileCard"
      }`}
    >
      <p className="size">
        {props.maxWriteBytes / Math.pow(2, 20)} <sup>MB</sup>
      </p>
      <p>{props.description}</p>
      <Divider />
      <div className="d-flex justify-content-between mt-3">
        <h3>{props.price ? `${props.price} ${lan.sum}` : lan.free}</h3>
        <Button
          type="primary"
          disabled={props.activeStatus === props.title}
          onClick={() => props.changeOption(props.storageProfileId)}
        >
          {lan.buy}
        </Button>
      </div>
    </Card>
  );
};

export default StorageProfileCard;

import React from "react";
import { Link } from "react-router-dom";

const SymptomCard = ({ item }) => {
  return (
    <div className="symptomCard card px-3">
      <div className="element-card">
        <Link className="title" to={"/symptoms/" + item.id}>
          {item.name}
        </Link>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
    </div>
  );
};

export default SymptomCard;

import React from "react";

export const SpecSection = ({ title, children }) => (
  <div className="spec__section">
    <h4 className="spec__section-title">{title}</h4>
    {children}
  </div>
);

export const SpecRow = ({ name, value }) => (
  <div className="spec__row">
    <div className="spec__name"> {name}</div>
    <div className="spec__value">{value}</div>
  </div>
);

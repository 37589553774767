import { RU } from "../../locales/Ru";
import { UZ } from "../../locales/Uz";
import { SITE_LANG } from "../../tools/constants";
import { UPDATE_APP_STATE } from "../types";

export const lanProp = () => {
  if (localStorage.getItem(SITE_LANG) === "uz") {
    return UZ;
  } else {
    localStorage.setItem(SITE_LANG, "ru");
    return RU;
  }
};

export const lan = lanProp();

const initialState = {
  openMenuModal: false,
  places: [],
  products: [],
  totalPrice: 0,
  cartProducts: [],
  approvedServices: [],
  selectedServicesForOrdered: [],
  storeServices: [],
  purchasedServices: [],
  likeServices: [],
  likeServicesNumber: 0,
  compareServices: [],
  compareServicesNumber: 0,
  statusOrders: [],
  statusOrdersNumber: 0,
  roadPrice: 1000,
  language: lanProp(),
  accountKey: "1",
  activeCartTab: "1",
  selectedCity: "",
  selectedRegionName: "",
  activeDataStep: 0,
  basketCallback: false,
  search: "",
  searchTitle: lan.services,
  searchArray: [],
  userData: {},
  clinic: {},
  selectedService: {},
  isCode: false,
  again: false,
  is_verified: false,
  languages: [],
  footerInfo: {},
  socialContactList: [],
  regions: [],
  standardServices: [],
  loading: true,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_APP_STATE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

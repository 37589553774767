import React from "react";
import { Link } from "react-router-dom";
import "./IllCard.scss";

const IllCard = ({ item, to }) => {
  return (
    <div className="illcard card px-3">
      <div className="element-card elementCosEqPr disLink">
        <Link className="title" to={to}>
          {item.name}
        </Link>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: item.description }}
        />
      </div>
    </div>
  );
};

export default IllCard;

import React, { useState } from "react";
import { Button, Radio, Space, Slider } from "antd";
import { useSelector } from "react-redux";
import FilterItem from "./../Filters/FilterItem";
import useLocation from "../../hooks/useLocation";

export default ({ handleDistance }) => {
  const lan = useSelector((state) => state.app.language);
  const [location, allowLocation] = useLocation();
  const [distanceValue, setDistanceValue] = useState(0);

  return (
    <FilterItem title={lan.distance}>
      {location.length !== 0 ? (
        <>
          <Radio.Group
            value={distanceValue}
            onChange={(e) => {
              handleDistance(e.target.value);
              setDistanceValue(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={0}>{lan.all}</Radio>
              <Radio value={3}>3км</Radio>
              <Radio value={5}>5км</Radio>
              <Radio value={7}>7км</Radio>
              <Radio value={9}>9км</Radio>
            </Space>
          </Radio.Group>
          <Slider
            min={0}
            max={50}
            value={distanceValue}
            defaultValue={0}
            onChange={(value) => {
              handleDistance(value);
              setDistanceValue(value);
            }}
          />
          <div className="mt-4">{distanceValue} км</div>
        </>
      ) : (
        <Button onClick={allowLocation}>{lan.allowLocation}</Button>
      )}
    </FilterItem>
  );
};

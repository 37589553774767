import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Slider } from "antd";
import FilterItem from "../Filters/FilterItem";
import FilterLayout from "../Filters/FilterLayout";
import CommonRadioFilter from "./CommonRadioFilter";
import FilterByDistance from "../Shared/FilterByDistance";

const ServicesFilter = (props) => {
  const lan = useSelector((state) => state.app.language);
  const [priceValue, setPriceValue] = useState(0);

  return (
    <div>
      <FilterLayout title={lan.filter} quantity={props.quantity}>
        <FilterItem title={lan.regions}>
          <CommonRadioFilter
            value={props.city}
            handleValue={props.handlePlace}
            data={props.places}
          />
        </FilterItem>
        {props.serviceTypes.length !== 0 && (
          <FilterItem title={lan.typeService}>
            <CommonRadioFilter
              // value={props.serviceType}
              handleValue={props.handleServiceType}
              data={props.serviceTypes}
            />
          </FilterItem>
        )}
        <FilterItem title={lan.price}>
          <Slider
            min={0}
            max={100}
            value={priceValue}
            defaultValue={0}
            onChange={(value) => {
              props.handlePrice(value);
              setPriceValue(value);
            }}
          />
          <div className="mt-4">
            {priceValue * 1000} {lan.sum}
          </div>
        </FilterItem>
        <FilterByDistance handleDistance={props.handleDistance} />
      </FilterLayout>
    </div>
  );
};

export default ServicesFilter;

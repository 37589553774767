import React, { useEffect, useState } from "react";
import { Form, Input, Button, Col, Statistic } from "antd";
import { toast } from "react-toastify";
import { sendData } from "../../server/requestFunctions";
import {
  MEDPAY_USER_USERNAME,
  REGISTER_SMS,
  RETURN_CODE_DATE,
  CODE_RETURN_TIME,
  TOKEN,
  SITE_LANG,
  API_URL_FRONT,
  IS_VERIFIED,
} from "../../tools/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  changeActiveDataStep,
  moveToServerFromLocalLikeServices,
  moveToServerFromLocalCompareServices,
  updateState,
} from "../../redux/actions/appActions";
import { RollbackOutlined } from "@ant-design/icons";
import { setCookie } from "../../functions/useCookies";
import axios from "axios";
import { removeRegisterLocalStorageData } from "./../../functions/removeRegisterLocalStorageData";
import { phoneFormat } from "../../functions";

const { Countdown } = Statistic;

const RegisterForm = (props) => {
  const [login, setLogin] = useState(false);
  const [form] = Form.useForm();
  const [callback, setCallback] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCode, setLoadingCode] = useState(false);

  const again = useSelector((state) => state.app.again);
  const isCode = useSelector((state) => state.app.isCode);
  const lan = useSelector((state) => state.app.language);

  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem(REGISTER_SMS)) {
      dispatch(updateState({ isCode: true }));
      countFinish();
    }
  }, []);
  const register = (values) => {
    setLogin(!login);
    setLoading(true);
    axios
      .post(
        `${API_URL_FRONT}register?lang=${localStorage.getItem(SITE_LANG)}`,
        {
          ...values,
          ...phoneFormat(values.phone),
        }
      )
      .then((res) => {
        form.resetFields();
        setCookie(TOKEN, res.data.token);
        dispatch(updateState({ isCode: true }));
        localStorage.setItem(REGISTER_SMS, values.phone);
        localStorage.setItem(IS_VERIFIED, "false");
        localStorage.setItem(MEDPAY_USER_USERNAME, values.username);
        localStorage.setItem(RETURN_CODE_DATE, Date.now());
        setCallback(!callback);
        dispatch(moveToServerFromLocalLikeServices());
        dispatch(moveToServerFromLocalCompareServices());
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          form.setFields(
            Object.keys(err.response.data.errors).map((item) => {
              return { name: [item], errors: err.response.data.errors[item] };
            })
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const confirmingCode = (values) => {
    setLoadingCode(true);
    sendData(`confirm-phone`, { key: values.code })
      .then(() => {
        localStorage.removeItem(REGISTER_SMS);
        localStorage.setItem(IS_VERIFIED, "true");
        setCallback(!callback);
        toast.success(lan.registerSuccessfully);
        dispatch(changeActiveDataStep(1));
      })
      .finally(() => {
        setLoadingCode(false);
      });
  };
  const returnCode = () => {
    localStorage.setItem(RETURN_CODE_DATE, Date.now());
    dispatch(updateState({ again: false }));
    sendData("send-sms", phoneFormat(localStorage.getItem(REGISTER_SMS))).then(
      () => {
        toast.success(lan.sendingCodeToPhone);
      }
    );
  };
  const countFinish = () => {
    let dif = Date.now() - parseInt(localStorage.getItem(RETURN_CODE_DATE));

    dif > CODE_RETURN_TIME * 60 * 1000 &&
      dispatch(updateState({ again: true }));
  };
  const backRegister = () => {
    dispatch(updateState({ isCode: false }));
    removeRegisterLocalStorageData();
  };

  return (
    <Col md={12} span={24}>
      <div className="card flex-grow-1">
        <div className="card-body">
          {isCode ? (
            <>
              <Form
                form={form}
                name="code"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={confirmingCode}
              >
                <Button
                  className="position-absolute"
                  style={{ right: "1.25rem", top: "1.25rem", zIndex: 10 }}
                  type="primary"
                  onClick={backRegister}
                  icon={<RollbackOutlined />}
                />
                <Form.Item
                  label={`${lan.enterCode} (${localStorage.getItem(
                    REGISTER_SMS
                  )})`}
                  name="code"
                  rules={[
                    {
                      required: true,
                      message: lan.notFilled,
                    },
                  ]}
                >
                  <Input disabled={loadingCode} placeholder={lan.phoneNumber} />
                </Form.Item>
                <div className="d-flex justify-content-between">
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={loadingCode}
                      loading={loadingCode}
                    >
                      {lan.send}
                    </Button>
                  </Form.Item>
                  {again ? (
                    <Button onClick={returnCode}>{lan.resend}</Button>
                  ) : (
                    <Countdown
                      format="mm:ss"
                      value={
                        parseInt(localStorage.getItem(RETURN_CODE_DATE)) +
                        CODE_RETURN_TIME * 60 * 1000
                      }
                      onFinish={countFinish}
                    />
                  )}
                </div>
              </Form>
            </>
          ) : (
            <>
              <h3 className="card-title">{lan.register}</h3>
              <Form
                form={form}
                name="register"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                onFinish={register}
              >
                <Form.Item
                  label={lan.phoneNumber}
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: lan.notFilled,
                    },
                    {
                      pattern: /^[89][012345789][0-9]{7}$/,
                      message: lan.enterRightNumber + " (991234567)",
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder="991234567" />
                </Form.Item>
                <Form.Item
                  label={lan.username}
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: lan.notFilled,
                    },
                    {
                      pattern:
                        /^(?=.{5,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
                      message: lan.enterValidUsername,
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder={lan.username} />
                </Form.Item>
                <Form.Item
                  label={lan.email + " (" + lan.optinal + ")"}
                  name="email"
                  rules={[
                    {
                      pattern: /^\S+@\S+$/,
                      message: lan.emailValidation,
                    },
                  ]}
                >
                  <Input disabled={loading} placeholder={lan.email} />
                </Form.Item>
                <Form.Item
                  label={lan.password}
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: lan.notFilled,
                    },
                  ]}
                >
                  <Input.Password
                    disabled={loading}
                    placeholder={lan.password}
                  />
                </Form.Item>
                <Form.Item
                  name="password_confirmation"
                  label={lan.repeatPassword}
                  dependencies={["password"]}
                  hasFeedback
                  allowClear
                  rules={[
                    {
                      required: true,
                      message: lan.enterConfirmPassword,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(lan.passwordMatchError)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    disabled={loading}
                    placeholder={lan.repeatPassword}
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    disabled={loading}
                  >
                    {lan.register}
                  </Button>
                </Form.Item>
              </Form>
            </>
          )}
        </div>
      </div>
    </Col>
  );
};

export default RegisterForm;

import AccountComp from "../../components/Layouts/AccountLayout";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../../components/Shared/Loading";
import NoData from "../../components/Shared/NoData";
import useFetchWithPgtn from "./../../hooks/useFetchWithPgtn";

const AccountStorePage = (props) => {
  const lan = useSelector((state) => state.app.language);
  const {
    data: orders,
    pgtn,
    loading,
  } = useFetchWithPgtn("order/get-created-list");
  return (
    <AccountComp>
      <div className="card">
        {loading ? (
          <Loading />
        ) : (
          <>
            <table className="cart-table__table">
              <thead className="cart-table__head">
                <tr className="cart-table__row">
                  <th className="cart-table__column cart-table__column--image">
                    {lan.img}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.servic}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.clinic}
                  </th>
                  <th className="cart-table__column cart-table__column--product">
                    {lan.doctor}
                  </th>
                  <th className="cart-table__column cart-table__column--time">
                    {lan.time}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.price}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.ordersStatus}
                  </th>
                  <th className="cart-table__column cart-table__column--price">
                    {lan.doPayment}
                  </th>
                </tr>
              </thead>
              <tbody className="cart-table__body">
                {orders.map((item) => (
                  <tr className="cart-table__row">
                    <td className="cart-table__column cart-table__column--image">
                      <div className="image image--type--product">
                        <Link className="image__body" to="/clinics/one">
                          <img
                            alt=""
                            className="image__tag"
                            src={
                              item.clinic.images[0].image ||
                              "/images/no-hospital.png"
                            }
                          />
                        </Link>
                      </div>
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                      <Link
                        className="cart-table__product-name"
                        to={`/services/${item.service.service_category_id}`}
                      >
                        {item.service.name}
                      </Link>
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                      <Link
                        className="cart-table__product-name"
                        to={`/clinics/${item.clinic_id}`}
                      >
                        {item.clinic.name}
                      </Link>
                    </td>
                    <td className="cart-table__column cart-table__column--product">
                      <Link
                        className="cart-table__product-name"
                        to={`/doctors/${item.doctor.user_id}`}
                      >
                        {item.doctor.user.fio}
                      </Link>
                    </td>
                    <td
                      className="cart-table__column cart-table__column--time"
                      data-title={lan.time}
                    >
                      <div>
                        <div
                          className="timetablecard"
                          style={{
                            font: "bold 12px/22px Arial",
                            width: "62px",
                          }}
                        >
                          {item.service_time.split(" ").map((el) => (
                            <p key={el} className="p-0">
                              {el}
                            </p>
                          ))}
                        </div>
                        {/* <div
                          key={index}
                          className="timetablecard"
                          style={{
                            font: "bold 12px/22px Arial",
                            width: "62px",
                          }}
                        >
                          <p className="p-0">{day.objectDate}</p>
                        </div> */}
                      </div>
                    </td>
                    <td
                      className="cart-table__column cart-table__column--price"
                      data-title="Price"
                    >
                      {item.price}
                    </td>
                    <td
                      className="cart-table__column cart-table__column--price"
                      data-title="Price"
                    >
                      {lan.statuses[item.status]}
                    </td>
                    <td
                      className="cart-table__column cart-table__column--price"
                      data-title="Price"
                    >
                      <Link to="/checkout" className="btn btn-primary">
                        {lan.doPayment}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {orders.length === 0 && <NoData />}
            {pgtn}
          </>
        )}
      </div>
    </AccountComp>
  );
};

export default AccountStorePage;

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "./TechCard.scss";

const TechCard = (props) => {
  return (
    <div className="techCard">
      <div className="row">
        <div className="col-md-3 col-12">
          <div className="tech-img">
            <LazyLoadImage src={props.image} alt={props.name} />
          </div>
        </div>
        <div className="col-md-9 col-12">
          <div className="tech-info">
            <h4>{props.name}</h4>
            <div dangerouslySetInnerHTML={{ __html: props.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechCard;
